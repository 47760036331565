<script setup lang="ts">
import type { FileStoreDrawingFile } from "@/stores/fileStore";
import { downloadVSFXToUser } from "@/components/files/DrawingList/downloadFile";
import { ref } from "vue";
import userState from "@/stores/UserState";
import { useToast } from "@/components/composable/useToast";
const props = defineProps<{
  drawing: FileStoreDrawingFile;
}>();

const showDlVsfx = userState.isInternalUser();
const showDownloadVsfxSpinner = ref(false);
const { showDanger } = useToast();

async function downloadVsfxFile() {
  showDownloadVsfxSpinner.value = true;
  try {
    await downloadVSFXToUser(props.drawing.id, props.drawing.name);
  } catch (e) {
    showDanger("Erreur lors du téléchargement du fichier");
    throw e;
  } finally {
    showDownloadVsfxSpinner.value = false;
  }
}
</script>
<template>
  <button
    v-if="showDlVsfx"
    class="btn btn-primary"
    :class="{ disabled: !drawing.isLocal }"
    @click.prevent.stop="downloadVsfxFile()"
  >
    <i v-if="!showDownloadVsfxSpinner" class="bi bi-file-earmark-excel" />
    <span v-else class="spinner-border spinner-border-sm" role="status"></span>
  </button>
</template>
@/stores/UserState
