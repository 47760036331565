<script setup lang="ts">
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
import type { FileStoreDrawingFile } from "@/stores/fileStore";
import { Logger } from "@/logger";
import { useDeviceState } from "@/stores/DeviceState";
import { ref } from "vue";
import { useToast } from "@/components/composable/useToast";
import { downloadFileToUser } from "@/components/files/DrawingList/downloadFile";
const props = defineProps<{
  drawing: FileStoreDrawingFile;
}>();
const showDownloadSpinner = ref(false);
const deviceState = useDeviceState();
const { showDanger } = useToast();
const { refHook: refHookDownload } = useLongPressTooltip();
async function downloadFile() {
  Logger.info(`DownloadDwgBtn.vue : downloading drawing ${props.drawing.id}`);
  showDownloadSpinner.value = true;
  try {
    await downloadFileToUser(props.drawing.id, props.drawing.name);
  } catch (e) {
    Logger.error(
      `DownloadDwgBtn.vue : could not download DWG for ${
        props.drawing.id
      }, ${JSON.stringify(e)}`
    );
    showDanger("Erreur lors du téléchargement du fichier");
    throw e;
  } finally {
    showDownloadSpinner.value = false;
  }
}
</script>
<template>
  <button
    ref="refHookDownload"
    data-bs-content="Télécharger le plan en format Dwg sur l'appareil"
    data-bs-title="Exporter"
    class="btn btn-primary"
    :class="{ disabled: !drawing.isLocal || !deviceState.isOnline }"
    @click.prevent.stop="downloadFile()"
  >
    <i
      v-if="!showDownloadSpinner"
      class="bi bi-file-earmark-arrow-down-fill"
    ></i>
    <span v-else class="spinner-border spinner-border-sm" role="status"></span>
  </button>
</template>
