<script setup lang="ts">
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
import { useDrawingStore, type FileStoreDrawingFile } from "@/stores/fileStore";
import { useDeviceState } from "@/stores/DeviceState";
import { useToast } from "@/components/composable/useToast";
import { Logger } from "@/logger";
const props = defineProps<{
  drawing: FileStoreDrawingFile;
}>();
const { showPrimary, showWarning } = useToast();
const deviceState = useDeviceState();
const { refHook: refHookArchive } = useLongPressTooltip();

async function archiveDrawing() {
  Logger.info(`ArchiveBtn.vue : archiving drawing ${props.drawing.id}`);
  if (
    window.confirm(
      "Êtes-vous sûr de vouloir archiver ce plan ? Vous pourrez le rétablir dans la page des plans archivés."
    )
  ) {
    try {
      await useDrawingStore().archiveDrawing(props.drawing.id);
      showPrimary("Le plan a été archivé");
    } catch (e: Error) {
      if (e.message === "DRAWING NOT SYNC") {
        showWarning(
          "Le dessin ne peut pas être archivé car il n'est pas synchronisé avec le serveur"
        );
      } else {
        throw e;
      }
    }
  }
}
</script>
<template>
  <button
    ref="refHookArchive"
    class="btn btn-primary"
    :class="{ disabled: !drawing.isSynced || !deviceState.isOnline }"
    @click.prevent.stop="archiveDrawing"
    data-bs-content="Le plan disparaitra de la liste mais ne sera pas supprimé du serveur. Vous pourrez les rétablir par la suite."
    data-bs-title="Archiver"
  >
    <i class="bi bi-archive-fill"></i>
  </button>
</template>
