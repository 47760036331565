<script setup lang="ts">
import CanvasButton from "@/components/canvas/CanvasButton.vue";
import { useToast } from "@/components/composable/useToast";
import DraggerMenuState from "@/stores/DraggerMenuState";
const { showSecondary } = useToast();

function toggle() {
  DraggerMenuState.toggleCloseContour();
  if (DraggerMenuState.closeContour) {
    showSecondary("Fermeture du contour activée");
  } else {
    showSecondary("Fermeture du contour désactivée");
  }
}
</script>
<template>
  <CanvasButton
    @click.prevent.stop="toggle"
    tooltipTitle="Fermeture des contours"
    tooltipText="Active la fermeture du contour lorsque les extrémités sont proches."
    :active="DraggerMenuState.closeContour"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="16"
      height="16"
    >
      <!-- curve-->
      <path
        d="M3.24345 22.1902C1.05161 16.5013 4.95356 12.5614 8.74219 7.48923C18.97-6.20344 36.3406 8.5954 29.3036 22.2319"
        fill="none"
        stroke="currentColor"
        stroke-width="3"
      />
      <!-- circles-->
      <path
        d="M2.45486 23.3056C1.71849 22.9256 1.40933 22.0599 1.76433 21.372C2.11932 20.684 3.00405 20.4344 3.74042 20.8144C4.4768 21.1944 4.78596 22.0601 4.43096 22.7481C4.07596 23.436 3.19123 23.6856 2.45486 23.3056Z"
        fill="none"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        d="M28.6589 23.4813C27.9225 23.1013 27.6134 22.2356 27.9684 21.5476C28.3234 20.8597 29.2081 20.6101 29.9445 20.9901C30.6808 21.3701 30.99 22.2358 30.635 22.9237C30.28 23.6117 29.3953 23.8613 28.6589 23.4813Z"
        fill="none"
        stroke="currentColor"
        stroke-width="4"
      />
      <!-- dashes-->
      <path
        d="M6.38489 22.06L9.33477 22.06"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M11.3045 22.06L14.2544 22.06"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M16.7731 22.06L20.0146 22.06"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M22.4962 22.06L25.8675 22.06"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
  </CanvasButton>
</template>
