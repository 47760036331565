<script setup lang="ts">
import DrawingList from "@/components/files/DrawingList/DrawingList.vue";
import EmptyFileList from "@/components/files/EmptyFileList.vue";
import { useDrawingStore } from "@/stores/fileStore";
import UploadList from "@/components/files/UploadList/UploadList.vue";
import FileActionButtons from "@/components/files/FileActionButtons.vue";
import { onMounted, onUnmounted } from "vue";
import { useDeviceState } from "@/stores/DeviceState";
import { checkVersion } from "@/version";

const drawingStore = useDrawingStore();
const deviceState = useDeviceState();

onMounted(() => {
  deviceState.startMonitoringOnlineState();
  drawingStore.startAutoSync();
  checkVersion();
});

onUnmounted(() => {
  drawingStore.deleteWaitingJobs();
  deviceState.stopMonitoringOnlineState();
  drawingStore.stopAutoSync();
});
</script>

<template>
  <div class="p-2">
    <div v-if="drawingStore.drawings && drawingStore.drawings.length > 0">
      <FileActionButtons />
      <UploadList />
      <DrawingList :drawings="drawingStore.drawings" />
    </div>
    <div v-else>
      <UploadList class="mt-3" style="margin-bottom: -20px" />
      <EmptyFileList />
    </div>
  </div>
</template>
