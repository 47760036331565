import type { pointArray } from "@/open-cloud/types/oda.types";
import type { EntityProps } from "../../builders/EntityBuilder";
import Toolbox from "@/open-cloud/builders/ODAToolbox";

export const ORIGIN_RADIUS = 10 * window.devicePixelRatio; // px

export const ORIGIN_DESIGN_PROPS: EntityProps = {
  color: "125,125,125",
  transparency: {
    edge: 0,
    face: 0.5,
  },
};

export function appendCursorEntityToModel(
  model: VisualizeJS.TvModel,
  center: pointArray,
  radius: number
): VisualizeJS.OdTvEntityId {
  const entityId = model.appendEntity("DR_Entity");
  const entity = entityId.openObject();
  const geomId = entity.appendCircleWithNormal(center, radius, [0, 0, 1]);
  const circle = geomId.openAsCircle();
  circle.setFilled(true);
  const poly1 = entity.appendPolyline([
    center[0],
    center[1] + radius / 2,
    0,
    center[0],
    center[1] - radius / 2,
    0,
  ]);
  const poly2 = entity.appendPolyline([
    center[0] + radius / 2,
    center[1],
    0,
    center[0] - radius / 2,
    center[1],
    0,
  ]);
  Toolbox.deleteAll([poly2, poly1, circle, geomId, entity]);
  return entityId;
}
