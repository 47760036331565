import type { Coords } from "@/open-cloud/draggers/draggers.type";
import type { pointArray } from "@/open-cloud/types/oda.types";
import {
  type BaseIterator,
  isCoord,
  isEntityId,
  isIdOfEntity,
  isIdOfInsert,
  isInsert,
  isOdtvPoint,
  type MatrixAsArray,
} from "@/open-cloud/types/oda.types";
import { OdBaseDragger } from "@inweb/viewer-visualize";
import type { DRViewer } from "@/open-cloud/DRViewer";
import { v4 } from "uuid";

// we want to be able to use in standalone the `relativeCoords` method in the OdBaseDragger.
// So we instantiate one with dummy param
const odBaseDragger = new OdBaseDragger({ visualizeJs: null });

export default class Toolbox {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  /****************************************MATH UTILS**************************** */

  static coord2Point(coord: Coords): pointArray {
    return [coord.x, coord.y, 0];
  }

  static point2Coord(point: pointArray): Coords {
    return {
      x: point[0],
      y: point[1],
      z: point[2],
    };
  }

  static array2Coord(array: [number, number, number]): Coords {
    return {
      x: array[0],
      y: array[1],
      z: array[2],
    };
  }

  /**
   * Compute the center of two points on the canvas.
   */
  static computeCenter2D(p1: pointArray, p2: pointArray): pointArray;
  static computeCenter2D(p1: Coords, p2: Coords): Coords;
  static computeCenter2D(
    coords1: Coords | pointArray,
    coords2: Coords | pointArray
  ): Coords | pointArray {
    if (isOdtvPoint(coords1) && isOdtvPoint(coords2))
      return [(coords1[0] + coords2[0]) / 2, (coords1[1] + coords2[1]) / 2, 0];

    if (isCoord(coords1) && isCoord(coords2))
      return {
        x: (coords1.x + coords2.x) / 2,
        y: (coords1.y + coords2.y) / 2,
        z: 0,
      };
    throw new TypeError(
      `cannot compute center if inputs are not Coord or OdtvPoints`
    );
  }

  /**
   * Compute the Euclidian distance between two points on the canvas.
   */
  static computeDistance2D(coords1: Coords, coords2: Coords): number;
  static computeDistance2D(coords1: pointArray, coords2: pointArray): number;
  static computeDistance2D(
    coords1: pointArray | Coords,
    coords2: pointArray | Coords
  ): number {
    if (isOdtvPoint(coords1) && isOdtvPoint(coords2))
      return Math.hypot(coords1[0] - coords2[0], coords1[1] - coords2[1]);
    if (isCoord(coords1) && isCoord(coords2))
      return Math.hypot(coords1.x - coords2.x, coords1.y - coords2.y);
    throw new TypeError(
      `cannot compute distance if inputs are not Coord or OdtvPoints`
    );
  }

  static eventToWorldCoord(event: PointerEvent): Coords {
    return odBaseDragger.relativeCoords(event) as Coords;
  }

  static iterateBase<T, I extends BaseIterator<T>>(
    iterator: I,
    cb: (entityId: T, index: number) => void,
    mapToCb: (interator: I) => T
  ) {
    for (let i = 0; !iterator.done(); iterator.step(), i++) {
      cb(mapToCb(iterator), i);
    }
  }

  /**
   * Utility to apply different thing to an entity, depending if it's an insert or an entity.
   * If an entityId is provided,, it will be opened properly depending on its getType
   * @param entity
   * @param hooks
   */
  static agnosticEntity<R>(
    entity:
      | VisualizeJS.OdTvEntity
      | VisualizeJS.OdTvInsert
      | VisualizeJS.OdTvEntityId,
    hooks: {
      onInsert?: (insert: VisualizeJS.OdTvInsert) => R;
      onEntity?: (insert: VisualizeJS.OdTvEntity) => R;
    }
  ) {
    if (!hooks.onInsert && !hooks.onEntity) throw new Error(`nnn`);
    if (isEntityId(entity)) {
      if (isIdOfInsert(entity)) {
        if (hooks.onInsert) {
          const insert = entity.openObjectAsInsert();
          const result = hooks.onInsert(insert);
          insert.delete();
          return result;
        }
        throw new Error(`No onInsert hook provided`);
      } else {
        if (hooks.onEntity) {
          const ent = entity.openObject();
          const result = hooks.onEntity(ent);
          ent.delete();
          return result;
        }
        throw new Error(`No onEntity hook provided`);
      }
    } else {
      if (isInsert(entity)) {
        if (hooks.onInsert) {
          return hooks.onInsert(entity);
        }
        throw new Error(`No onInsert hook provided`);
      } else {
        if (hooks.onEntity) {
          return hooks.onEntity(entity);
        }
        throw new Error(`No onEntity hook provided`);
      }
    }
  }

  setVisible(
    entity: VisualizeJS.OdTvEntity | VisualizeJS.OdTvInsert,
    visible: boolean
  ): void;
  setVisible(entityId: VisualizeJS.OdTvEntityId, visible: boolean): void;
  setVisible(
    entity:
      | VisualizeJS.OdTvEntity
      | VisualizeJS.OdTvInsert
      | VisualizeJS.OdTvEntityId,
    visible: boolean
  ): void {
    Toolbox.agnosticEntity(entity, {
      onInsert: (insert) => insert.setVisibility(visible),
      onEntity: (entity) => {
        const visDef = new this.visLib.OdTvVisibilityDef(visible);
        entity.setVisibility(visDef, this.visLib.GeometryTypes.kAll);
        visDef.delete();
      },
    });
  }

  createEntityInModel(
    model: VisualizeJS.TvModel,
    {
      sourceInsert,
      baseName = "default",
    }: { sourceInsert?: VisualizeJS.OdTvEntityId; baseName?: string }
  ): VisualizeJS.OdTvEntityId {
    if (!sourceInsert || isIdOfEntity(sourceInsert)) {
      return model.appendEntity(`${baseName}_${v4()}`);
    } else if (isIdOfInsert(sourceInsert)) {
      const insert = sourceInsert.openObjectAsInsert();
      const blockId = insert.getBlock();
      insert.delete();
      return model.appendInsert(blockId, baseName);
    } else {
      throw new Error(`cannot create from unknown type`);
    }
  }

  static getRotationFromMatrix(matrix: VisualizeJS.Matrix3d) {
    const r11 = matrix.get(1 - 1, 1 - 1);
    const r21 = matrix.get(2 - 1, 1 - 1);
    return Math.atan2(r21, r11);
  }

  static matrix2Array(m: VisualizeJS.Matrix3d): MatrixAsArray {
    // @ts-ignore
    const out: MatrixAsArray = [];
    for (let i = 0; i < 4; i++) {
      const row = [];
      for (let j = 0; j < 4; j++) {
        row.push(m.get(i, j));
      }
      // @ts-ignore
      out.push(row);
    }
    return out;
  }

  screenToWorld(point: pointArray): pointArray;
  screenToWorld(point: Coords): Coords;
  screenToWorld(point: Coords | pointArray): Coords | pointArray {
    if (isOdtvPoint(point)) {
      return this.visViewer.screenToWorld(point[0], point[1]);
    } else if (isCoord(point)) {
      const [x, y] = this.visViewer.screenToWorld(point.x, point.y);
      return { x, y };
    } else {
      throw new TypeError(`Toolbox.screenToWorld expects a Coord or Point`);
    }
  }

  computeCenter(
    p1: [number, number, number],
    p2: [number, number, number]
  ): [number, number, number] {
    const p: [number, number, number] = [
      (p1[0] + p2[0]) / 2,
      (p1[1] + p2[1]) / 2,
      (p1[2] + p2[2]) / 2,
    ];
    return p;
  }

  /**
   * convert a distance in pixel, on our screen to a distance in the view coordinates
   * @param distanceInPixels
   */
  screenDistanceToWorld(distanceInPixels: number): number {
    const point1 = this.visViewer.screenToWorld(0, 0);
    const point2 = this.visViewer.screenToWorld(1, 0);
    const pixelRatio = point2[0] - point1[0];
    return distanceInPixels * pixelRatio;
  }

  static rotatePoint(c: pointArray, p: pointArray, alpha: number): pointArray {
    const relativeX = p[0] - c[0];
    const relativeY = p[1] - c[1];
    const radius = Math.sqrt(relativeX ** 2 + relativeY ** 2);
    const currentAngle = Math.atan2(relativeY, relativeX);
    const newAngle = currentAngle + alpha;
    const newX = c[0] + radius * Math.cos(newAngle);
    const newY = c[1] + radius * Math.sin(newAngle);
    return [newX, newY, c[2]];
  }

  static deleteAll(array: any[]) {
    for (const elem of array) {
      elem.delete();
    }
  }

  // Basically, screenToWorld is an affine transform of 2D points
  // It combines a linear transformation represented by a matrix
  // and a translation.
  // This method inverts it to get Canvas position from WCS point
  getCanvasCoord(point: VisualizeJS.Point3): VisualizeJS.Point3 {
    const p = this.visLib.Point3d.createFromArray(point);
    const t = p
      .transformBy(this.visViewer.activeView.worldToDeviceMatrix)
      .toArray();
    p.delete();
    return t;
  }

  static sum(
    p1: VisualizeJS.Point3,
    p2: VisualizeJS.Point3
  ): VisualizeJS.Point3 {
    return [p1[0] + p2[0], p1[1] + p2[1], p1[2] + p2[2]];
  }
}
