import type { FontSize } from "@/utils/bootstrap.utils";
import type { Toast } from "bootstrap";
import type { InjectionKey } from "vue";

type ShowFunction = (
  text: string,
  fontsize?: FontSize,
  options?: Partial<Toast.Options>
) => void;

export type ToastProviderValue = {
  showPrimary: ShowFunction;
  showSuccess: ShowFunction;
  showInfo: ShowFunction;
  showDanger: ShowFunction;
  showWarning: ShowFunction;
  showLight: ShowFunction;
  showSecondary: ShowFunction;
  showTertiary: ShowFunction;
  hideAll: () => void;
};
export const ToastSymbol = Symbol(
  "Toast provider identifier"
) as InjectionKey<ToastProviderValue>;
