const DEFAULT_THEME: Theme = {
  name: "dark",
  bgColor: [0, 0, 0, 0],
  overrideColor: [255, 255, 255, 0],
  toolColor: [200, 200, 200, 200],
};

export const LIGHT_THEME: Theme = {
  name: "light",
  bgColor: [255, 255, 255, 0],
  overrideColor: [0, 0, 0, 0],
  toolColor: [100, 100, 100, 100],
};

export const THEME_LIST: Theme[] = [DEFAULT_THEME, LIGHT_THEME];

export type Color = [number, number, number, number];

export type Theme = {
  name: "light" | "dark";
  bgColor: Color;
  overrideColor: Color;
  toolColor: Color;
};
