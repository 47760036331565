import type { DRDrawing } from "@/repositories/domain.types";
import type { ApiDrawingDto } from "@/repositories/api.types";
import { RevisionDecoder } from "@/open-cloud/revision.decoder";
import type { FileStoreDrawingFile } from "@/stores/fileStore";

export class DrawingDecoder {
  static fromApiDtoToDomain(dto: ApiDrawingDto): DRDrawing {
    return {
      initialOpenCloudFileId: dto.initialOpenCloudFileId,
      lastRevision: RevisionDecoder.fromApiDtoToDomain(dto.lastRevision),
      name: dto.fileName,
      userId: dto.userId,
      id: dto.id,
      createdAt: new Date(dto.createdAt),
      archivedAt: new Date(dto.archivedAt),
    };
  }

  static fromDomainToFileStore(
    input: DRDrawing,
    options?: {
      isSelected?: boolean;
    }
  ): FileStoreDrawingFile {
    const getLastEditedDate = () => {
      if (input.lastRevision.editedAt) {
        return new Date(input.lastRevision.editedAt);
      } else if (input.lastRevision.savedAt) {
        return new Date(input.lastRevision.savedAt);
      }
      return undefined;
    };

    const hasDraft = Boolean(input.lastRevision.draft);
    const isLocal = Boolean(input.lastRevision.vsf);
    const isSynced = !(hasDraft && isLocal);
    return {
      initialOpenCloudFileId: input.initialOpenCloudFileId,
      name: input.name,
      userId: input.userId,
      id: input.id,
      createdAt: new Date(input.createdAt),
      archivedAt: new Date(input.archivedAt),
      lastRevisionEditedAt: getLastEditedDate(),
      hasDraft,
      isLocal,
      isSynced,
      isSelected: options?.isSelected || false,
    };
  }

  // static fromDomainToFileStore(input: DRDrawing): FileStoreDrawingFile {
  static fromDomainToFileStoreArchived(
    input: DRDrawing
  ): FileStoreDrawingFile & { archivedAt: Date } {
    const base = DrawingDecoder.fromDomainToFileStore(input);
    base.archivedAt = new Date(input.archivedAt);
    return base;
  }
}
