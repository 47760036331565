import { onMounted, onBeforeUnmount, ref } from "vue";
import type { Ref } from "vue";
import Popover from "bootstrap/js/dist/popover";

import { onLongPress, unrefElement } from "@vueuse/core";

export function useLongPressTooltip(options?: {
  delayInMs?: number;
  pressExactTarget: boolean;
}): {
  hide: () => void;
  refHook: Ref<HTMLElement | null>;
} {
  const populatedOptions = {
    delayInMs: 800,
    pressExactTarget: false,
    ...options,
  };
  const buttonRefHook = ref<HTMLElement | null>(null);
  let tooltip: Popover;

  const onLongPressCallbackHook = (event: PointerEvent) => {
    if (populatedOptions.pressExactTarget) {
      if (event.target !== unrefElement(buttonRefHook.value)) {
        return;
      }
    }
    tooltip.show();
  };
  const hideTooltip = () => {
    if (tooltip) {
      tooltip.hide();
    }
  };

  onMounted(() => {
    // ref.value can be either the HtmlElement or a Component depending if it was `ref=""` on a node or a Component
    const htmlElement = unrefElement(buttonRefHook);
    if (htmlElement) {
      tooltip = new Popover(htmlElement, {
        trigger: "manual",
        html: true,
        customClass: "dr-button-popover",
      });
      onLongPress(buttonRefHook, onLongPressCallbackHook, {
        delay: populatedOptions.delayInMs,
      });
      htmlElement.addEventListener("pointerup", hideTooltip);
      htmlElement.addEventListener("pointerout", hideTooltip);
    }
  });

  onBeforeUnmount(() => {
    const htmlElement = unrefElement(buttonRefHook);
    tooltip?.dispose();

    htmlElement?.removeEventListener("pointerup", hideTooltip);
    htmlElement?.removeEventListener("pointerout", hideTooltip);
  });

  return { hide: hideTooltip, refHook: buttonRefHook };
}
