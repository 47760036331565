<script setup lang="ts">
import { useDrawingStore } from "@/stores/fileStore";
import { useDeviceState } from "@/stores/DeviceState";
import { useToast } from "@/components/composable/useToast";
import { Logger } from "@/logger";
import { computed } from "vue";

const { showPrimary, showWarning } = useToast();

const deviceState = useDeviceState();

const drawingStore = useDrawingStore();

async function archiveSelection() {
  Logger.info(`ArchiveSelected.vue : archiving selected drawing`);
  if (window.confirm("Êtes-vous sûr de vouloir archiver la sélection ?")) {
    let hasArchived = false;
    for (const drawing of drawingStore.drawings) {
      if (drawing.isSelected && drawing.isSynced) {
        try {
          drawingStore.archiveDrawing(drawing.id);
          hasArchived = true;
        } catch (e) {
          if (e.message === "DRAWING NOT SYNC") {
            showWarning(
              `Le plan ${drawing.name} n'est pas synchronisé et ne peut être archivé. Attendez la synchronisation.`
            );
          } else {
            throw e;
          }
        }
        drawing.isSelected = false;
      }
    }
    if (hasArchived) showPrimary("Les plans sélectionnés ont été archivés.");
  }
}

const storeHasSelected = computed(() => {
  return drawingStore.drawings.some((drawing) => drawing.isSelected);
});
</script>

<template>
  <button
    v-if="storeHasSelected"
    class="btn btn-dark"
    :class="{ disabled: !deviceState.isOnline }"
    @click="archiveSelection"
  >
    Archiver la sélection
  </button>
</template>
