import type { DRViewer } from "@/open-cloud/DRViewer";
import { Logger } from "@/logger";

// FIXME : should type that better
export type Color = "kByLayer" | "kByBlock" | string; //R,G,B

export class ColorDefBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib() {
    return this.viewer.visLib();
  }

  get visViewer() {
    return this.viewer.visViewer();
  }

  logProps(colorDef: VisualizeJS.OdTvColorDef) {
    Logger.debug(colorDef);
    const colorType = colorDef.getTypeEnum();
    Logger.debug("colorType", colorType);
    if (colorType === this.visLib.ColorType.kInherited) {
      Logger.debug("InheritedColor", colorDef.getInheritedColor());
    } else if (colorType === this.visLib.ColorType.kIndexed) {
      const index = colorDef.getIndexedColor();
      const activeDevice = this.visViewer.getActiveDevice();
      Logger.debug(
        "color index",
        index,
        activeDevice.getLogicalPalette()[index]
      );
      activeDevice.delete();
    } else if (colorType === this.visLib.ColorType.kColor) {
      Logger.debug("color : ", colorDef.getColor());
    }
  }

  getRGBfromString(
    color: string
  ): [number, number, number] | [number] | undefined {
    const matches = color.match(/\d{1,3}/g);
    if (matches && matches.length === 3) {
      const [R, G, B] = matches.map((str) => Number(str));
      return [R, G, B];
    } else {
      return undefined;
    }
  }

  setColor(colorDef: VisualizeJS.OdTvColorDef, color: Color) {
    if (color === "kByLayer" || color === "kByBlock") {
      colorDef.setInheritedColor(this.visLib.InheritedAttribute[color]);
    } else {
      const matches = this.getRGBfromString(color);
      if (matches && matches.length === 3) {
        const indexes = this.viewer.deviceBuilder.findColorInPalette(
          [...matches, 0],
          this.viewer.deviceBuilder.palette
        );
        if (indexes.length) {
          colorDef.setIndexedColor(indexes[0]);
          //Logger.debug("ColorDefBuilder.setColor", matches, indexes, this.viewer.deviceBuilder.palette, colorDef.getIndexedColor());
        } else {
          //Logger.debug("ColorDefBuilder.setColor", matches);
          colorDef.setColor(matches[0], matches[1], matches[2]);
        }
      } else {
        Logger.error(`No RGB value matches ${color}`);
      }
    }
  }
}
