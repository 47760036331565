import type { pointArray } from "@/open-cloud/types/oda.types";
import { EntityBuilder } from "../../builders/EntityBuilder";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import SequentialEntityDragger from "./SequentialEntityDragger";
import { v4 } from "uuid";
import { TextBuilder, type TextData } from "@/open-cloud/builders/TextBuilder";

const LEADER_NAME_PREFIX = "DRleader";
const TEXTSIZE_TO_HEAD_RATIO = 0.6;
const TEXTSIZE_TO_FIRST_POINT_GAP_RATIO = 1;
const TEXTSIZE_TO_SECOND_POINT_GAP_RATIO = 5;

export default class LeaderDragger extends SequentialEntityDragger {
  textId: VisualizeJS.OdTvEntityId | null = null;
  textIsSet = false;

  createNew(): VisualizeJS.OdTvEntityId | null {
    const model = this.viewer.modelBuilder.findModel("ACTIVE");
    if (!model) return null;
    const newEntity = model.appendEntity(`${LEADER_NAME_PREFIX}_${v4()}`);
    this._setNoteConfigProperties(newEntity);

    if (!this.textIsSet && this.textId && !this.textId.isNull()) {
      const text = this.textId.openObject();
      text.copyGeometryTo(newEntity);
      this.textIsSet = true;
      model.delete();
      return newEntity;
    } else if (this.textIsSet) {
      if (this.entity && !this.entity.isNull()) {
        const geom = this.entity.openObject();
        geom.copyGeometryTo(newEntity);
        geom.delete();
      }
      const shadow = this.shadowId?.openObject();
      shadow?.copyGeometryTo(newEntity);
      shadow?.delete();
      model.delete();
      return newEntity;
    }
    model.delete();
    return null;
  }

  _updateFrame(): void {
    this.refreshShadowEntity();
    if (!this.textIsSet) {
      this.refreshText();
      this._setNoteConfigProperties(this.textId);
    } else {
      this.refreshArrow();
      this._setNoteConfigProperties(this.shadowId);
    }
  }

  refreshText() {
    if (this.endCornerWCS.length === 3) {
      this.deleteText();
      if (this.viewer.activeText) {
        const model = this.viewer.modelBuilder.findModel("MUP");
        if (!model) return;
        this.textId = model.appendEntity("DR_ENTITY");
        const data: TextData = {
          refpoint: this.endCornerWCS,
          message: this.viewer.activeText,
        };
        TextBuilder.addText(this.textId, data);
        model.delete();
      }
    }
  }

  deleteText() {
    const model = this.viewer.modelBuilder.findModel("MUP");
    if (!model) return;
    if (this.textId) {
      model.removeEntity(this.textId);
    }
    model.delete();
  }

  refreshArrow() {
    this.refreshShadowEntity();
    if (
      this.shadowId &&
      this.textId &&
      !this.textId.isNull() &&
      this.endCornerWCS.length === 3
    ) {
      const textSize = EntityBuilder.getTextSizeFromProps(
        this.viewer.activeNoteConfig.props
      );
      const text = this.textId.openObject();
      const ext = text.getExtents();
      const [start, middle, end] = LeaderDragger.computeArrowFromExtents(
        ext,
        this.endCornerWCS,
        TEXTSIZE_TO_FIRST_POINT_GAP_RATIO * textSize,
        TEXTSIZE_TO_SECOND_POINT_GAP_RATIO * textSize
      );
      ext.delete();
      text.delete();
      const shadow = this.shadowId.openObject();
      const poly = shadow.appendPolyline(start.concat(middle, end));
      poly.delete();
      shadow.delete();
      this.drawArrowHead(this.shadowId, middle, end, textSize);
    }
  }

  drawArrowHead(
    entId: VisualizeJS.OdTvEntityId,
    tail: pointArray,
    head: pointArray,
    size: number
  ) {
    const angle = Math.atan2(head[1] - tail[1], head[0] - tail[0]);

    const delta = (10 * Math.PI) / 180; // Define the width of the leader

    const radialPoint: pointArray = [
      head[0] - TEXTSIZE_TO_HEAD_RATIO * size,
      head[1],
      head[2],
    ];
    const firstPoint = Toolbox.rotatePoint(head, radialPoint, angle + delta);
    const secondPoint = Toolbox.rotatePoint(head, radialPoint, angle - delta);
    const ent = entId.openObject();

    const geomId = ent.appendPolygon([...firstPoint, ...secondPoint, ...head]);
    const gon = geomId.openAsPolygon();
    gon.setFilled(true);
    gon.delete();
    geomId.delete();
    ent.delete();
  }

  static computeArrowFromExtents(
    extents: VisualizeJS.Extents3d,
    arrowEndWCS: pointArray,
    gap: number, // gap between text boundingbox and first arrow point
    gap2: number // gap between text boundingbox and second arrow point
  ): [pointArray, pointArray, pointArray] {
    const [xMin, yMin] = extents.min();
    const [xMax, yMax] = extents.max();

    const xMiddleAxis = (xMax + xMin) / 2;
    let point1: pointArray, point2: pointArray;
    if (arrowEndWCS[0] >= xMiddleAxis) {
      point1 = [xMax + gap, (yMin + yMax) / 2, arrowEndWCS[2]];
      point2 = [xMax + gap2, (yMin + yMax) / 2, arrowEndWCS[2]];
    } else {
      point1 = [xMin - gap, (yMin + yMax) / 2, arrowEndWCS[2]];
      point2 = [xMin - gap2, (yMin + yMax) / 2, arrowEndWCS[2]];
    }
    return [point1, point2, arrowEndWCS];
  }

  clear() {
    super.clear();
    this.deleteText();
    this.textIsSet = false;
  }
}
