import { delay } from "lodash";
type cursor = "default" | "pointer";
import type { OdBaseDragger } from "@inweb/viewer-visualize";
import DraggerState from "../DraggersState";
export function changeCursor(cursor: cursor) {
  document.body.style.cursor = cursor;
}

export function delayedPanZoomEnded<T extends OdBaseDragger>(target: T) {
  delay(() => {
    target.onmessage({ type: "zoomat" });
    DraggerState.isGestureActive = false;
  }, 200);
}
