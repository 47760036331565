import { Facade } from "@/open-cloud/Facade";
import { Logger } from "@/logger";

/**
 * KeyHandler is the class that listens to keyboard shortcuts
 * and turn them into Actions
 * Simply import an instance to attach listener to Window
 * Don't forget to dispose it when done
 */
class KeyHandler {
  listener: (e: KeyboardEvent) => void;
  constructor() {
    this.listener = (e: KeyboardEvent) => this.onKeyDown(e);
  }

  init() {
    document.addEventListener("keydown", this.listener, {
      capture: true,
    });
    Logger.info(`KeyHandler.ts : listening to keyup`);
  }

  dispose() {
    window.removeEventListener("keydown", this.listener);
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key == "Delete") {
      e.preventDefault();
      this.handleDelete();
    } else if (e.key == "Escape") {
      e.preventDefault();
      this.handleEscape();
    } else if (e.ctrlKey) {
      switch (e.key) {
        case "d":
          e.preventDefault();
          this.handleDuplicate();
          break;
        case "z":
          e.preventDefault();
          this.handleUndo();
          break;
        case "y":
          e.preventDefault();
          this.handleRedo();
          break;
      }
    }
  }

  handleEscape() {
    Logger.info(`KeyHandler.ts : escape`);
    Facade.escape();
  }

  handleUndo() {
    Logger.info(`KeyHandler.ts : undo`);
    Facade.undo();
  }

  handleRedo() {
    Logger.info(`KeyHandler.ts : redo`);
    Facade.redo();
  }

  handleDelete() {
    Logger.info(`KeyHandler.ts : delete selection`);
    Facade.deleteSelection();
  }

  handleDuplicate() {
    Logger.info(`KeyHandler.ts : duplicate selection`);
    Facade.copySelection();
  }
}

export const keyHandler = new KeyHandler();
