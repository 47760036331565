<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {});
</script>

<template>
  <router-link
    to="archives"
    class="btn btn-outline-dark btn-outline-secondary"
    type="button"
  >
    Plans Archivés
  </router-link>
</template>
