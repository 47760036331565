import type { DRViewer } from "@/open-cloud/DRViewer";
import { loadFont, loadDefaultFont } from "@/open-cloud/FontLoader";
import { Logger } from "@/logger";

export type Textstyle = {
  name: string;
  size?: number;
  filename?: string;
  bigfontfilename?: string;
  typeface?: string;
  charset?: number;
  bold?: boolean;
  italic?: boolean;
  family?: number;
  backward?: boolean;
  upsidedown?: boolean;
  vertical?: boolean;
  obliquingangle?: number;
  widthfactor?: number;
  alignmentmode?: number;
  underlined?: boolean;
  overlined?: boolean;
  striked?: boolean;
};

const DEFAULT_PROPERTIES = {
  name: "DR_DEFAULT_TEXSTYLE",
  size: 1,
  filename: "arial.ttf",
  bigfontfilename: "arial.ttf",
  typeface: "Arial",
  charset: 0,
  bold: false,
  italic: false,
  family: 0,
  backward: false,
  upsidedown: false,
  vertical: false,
  obliquingangle: 0,
  widthfactor: 1,
  alignmentmode: 1,
  underlined: false,
  overlined: false,
  striked: false,
};

export class TextStyleBuilder {
  viewer: DRViewer;
  filenames: string[] = [];

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get LIB() {
    return this.viewer.visLib();
  }

  get visViewer() {
    return this.viewer.visViewer();
  }

  static isNull(textstyleId: VisualizeJS.OdTvTextStyleId): boolean {
    const textstyle = textstyleId.openObject();
    const isNull = textstyle.isNull();
    textstyle.delete();
    return isNull;
  }

  // Logs the properties of the textstyleDef

  logTextStyleDefProperties(textStyleDef: VisualizeJS.OdTvTextStyleDef) {
    Logger.debug("logTextStyleDefProperties");
    Logger.debug("type", textStyleDef.getType());
    const textStyleId = textStyleDef.getTextStyle();
    this.logTextStyleProperties(textStyleId);
    textStyleId.delete();
  }

  // Logs the properties of the textstyleId
  logTextStyleProperties(textStyleId: VisualizeJS.OdTvTextStyleId) {
    const textStyle = textStyleId.openObject();
    try {
      if (!textStyle.isNull()) {
        Logger.debug("name", textStyle.getName());
        Logger.debug("textsize", textStyle.textSize());
        Logger.debug("typeface", textStyle.getFontTypeface());
        Logger.debug("bold", textStyle.getFontBold());
        Logger.debug("italic", textStyle.getFontItalic());
        Logger.debug("charset", textStyle.getFontCharset());
        Logger.debug("PitchAndFamily", textStyle.getFontPitchAndFamily());
        Logger.debug("filename", textStyle.getFileName());
        Logger.debug("BigFontFilename", textStyle.getBigFontFileName());
        textStyle.delete();
      } else {
        Logger.warn(
          "TextStyleBuilder.logTextStyleProperties() : textstyle is null"
        );
      }
    } catch (e) {
      Logger.error(
        `TextStyleBuilder.logTextStyleProperties() : ${JSON.stringify(e)}`
      );
    }
  }

  // Finds a textstyle having the layername as name
  // Returns the textstyleId or null if nothing was found

  findTextstyle(textstyle: Textstyle): VisualizeJS.OdTvTextStyleId | null {
    const iter = this.visViewer.getTextStylesIterator();
    for (; !iter.done(); iter.step()) {
      const textStyleId = iter.getTextStyle();
      const style = textStyleId.openObject();
      const name = style.getName();
      style.delete();
      // Activate this if you want to see texstyle in drawing
      // Logger.debug("textstylename", name);
      if (name === textstyle.name) {
        iter.delete();
        return textStyleId;
      } else textStyleId.delete();
    }
    iter.delete();
    return null;
  }

  /**
   * Browse the textstyles of current drawing and log their properties
   */
  browseTextstyles() {
    const iter = this.visViewer.getTextStylesIterator();
    for (; !iter.done(); iter.step()) {
      const textStyleId = iter.getTextStyle();
      this.logTextStyleProperties(textStyleId);
      textStyleId.delete();
    }
    iter.delete();
  }

  /**
   * Browse Textstyle font filename and add embedded font file to viewer
   */
  async embedFontFiles() {
    const iter = this.visViewer.getTextStylesIterator();
    const defaultFont = await loadDefaultFont();
    this.visViewer.addEmbeddedFile(DEFAULT_PROPERTIES.filename, defaultFont);
    for (; !iter.done(); iter.step()) {
      const textStyleId = iter.getTextStyle();
      const style = textStyleId.openObject();
      style.setFileName(DEFAULT_PROPERTIES.filename);
      style.delete();
      textStyleId.delete();
      //await this.embedFontFile(textStyleId);
      //this.logTextStyleProperties(textStyleId);
    }
    iter.delete();
  }

  async embedFontFile(textStyleId: VisualizeJS.OdTvTextStyleId) {
    const textStyle = textStyleId.openObject();
    const filename = textStyle.getFileName();
    // if file is not embedded to viewer yet, add it
    if (!this.filenames.find((addedFilename) => addedFilename === filename)) {
      const file = await loadFont(filename);
      this.visViewer.addEmbeddedFile(filename, file);
      this.filenames.push(filename);
    }
    textStyle.delete();
  }

  /**
   *
   * @returns the first textstyle in the iterator
   */
  getDefaultTextstyle(): VisualizeJS.OdTvTextStyleId {
    const iter = this.visViewer.getTextStylesIterator();
    const defaultStyle: VisualizeJS.OdTvTextStyleId = iter.getTextStyle();
    iter.delete();
    return defaultStyle;
  }

  createTextStyle(textstyle: Textstyle, shouldUpdate = true) {
    let textStyleId = this.findTextstyle(textstyle);
    if (!textStyleId) {
      textStyleId = this.visViewer.createTextStyle(textstyle.name);
      if (!textStyleId) return;
      this.setProperties(textStyleId, textstyle);
      //this.embedFontFile(textStyleId); No need because at the moment the only font is default one and is loaded at open
    } else if (shouldUpdate) {
      this.setProperties(textStyleId, textstyle);
    }
    textStyleId?.delete();
  }

  setProperties(
    textStyleId: VisualizeJS.OdTvTextStyleId,
    properties: Textstyle
  ) {
    const textStyle = textStyleId.openObject();
    const size = properties.size || DEFAULT_PROPERTIES.size;
    const filename = properties.filename || DEFAULT_PROPERTIES.filename;
    const typeface = properties.typeface || DEFAULT_PROPERTIES.typeface;
    const charset = properties.charset || DEFAULT_PROPERTIES.charset;
    const bold = properties.bold || DEFAULT_PROPERTIES.bold;
    const italic = properties.italic || DEFAULT_PROPERTIES.italic;
    const family = properties.family || DEFAULT_PROPERTIES.family;

    textStyle.setTextSize(size);
    textStyle.setFileName(filename);
    textStyle.setFont(typeface, bold, italic, charset, family);
    textStyle.delete();
    // Can add other properties there
  }

  getTextStyles(): Textstyle[] {
    const iter = this.visViewer.getTextStylesIterator();
    const textstyles: Textstyle[] = [];
    for (; !iter.done(); iter.step()) {
      const textStyleId = iter.getTextStyle();
      textstyles.push(this.getTextstyle(textStyleId));
      textStyleId.delete();
    }
    iter.delete();
    return textstyles;
  }

  getTextstyle(textStyleId: VisualizeJS.OdTvTextStyleId): Textstyle {
    const textStyle = textStyleId.openObject();
    const result = {
      name: textStyle.getName(),
      size: textStyle.textSize(),
      filename: textStyle.getFileName(),
      bigfontfilename: textStyle.getBigFontFileName(),
      typeface: textStyle.getFontTypeface(),
      bold: textStyle.getFontBold(),
      italic: textStyle.getFontItalic(),
      charset: textStyle.getFontCharset(),
      family: textStyle.getFontPitchAndFamily(),
    };
    textStyle.delete();
    return result;
  }
}
