// Work in progress : need a getProperties method to get properties and store them
// Workaround : clone entities and change their props, then use replace command
import type { EntityProps } from "../builders/EntityBuilder";
import Command from "./Command";
type SetEntityPropertiesParams = {
  handles: string;
  props: EntityProps;
}[];

export default class SetEntityProperties extends Command<SetEntityPropertiesParams> {
  _reexecute(params: SetEntityPropertiesParams) {}

  _unexecute(params: SetEntityPropertiesParams) {}
}
