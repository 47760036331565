import type { DRViewer } from "../DRViewer";
import AddEntities from "./AddEntities";
import DeleteEntities from "./DeleteEntities";
import ReplaceEntities from "./ReplaceEntities";
import TransformEntities from "./TransformEntities";
import History from "./History";
import SetEntityProperties from "./SetEntityProperties";

export default class CommandFactory {
  history: History;
  addEntities: AddEntities;
  deleteEntities: DeleteEntities;
  replaceEntities: ReplaceEntities;
  transformEntities: TransformEntities;
  setEntityProperties: SetEntityProperties;
  constructor(viewer: DRViewer) {
    this.addEntities = new AddEntities(viewer);
    this.history = new History(viewer);
    this.deleteEntities = new DeleteEntities(viewer);
    this.replaceEntities = new ReplaceEntities(viewer);
    this.transformEntities = new TransformEntities(viewer);
    this.setEntityProperties = new SetEntityProperties(viewer);
  }
}
