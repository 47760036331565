<script setup lang="ts">
import Settings from "@/stores/Settings";
import { computed } from "vue";
import { Logger } from "@/logger";
import type { Theme } from "./Themes";
const isDark = computed(() => {
  // Should improve this to match several themes' names
  return Settings.actviveTheme.name === "dark";
});

function setActiveTheme(theme: Theme["name"]) {
  Logger.info(`ThemePicker.vue : toggling canvas theme to ${theme}`);
  Settings.setActiveTheme(theme);
}
</script>
<template>
  <div class="btn-group">
    <button
      class="btn"
      :class="isDark ? 'btn-primary' : 'btn-outline-primary'"
      @click="() => setActiveTheme('dark')"
    >
      Sombre
    </button>
    <button
      class="btn"
      :class="!isDark ? 'btn-primary' : 'btn-outline-primary'"
      @click="() => setActiveTheme('light')"
    >
      Clair
    </button>
  </div>
</template>
