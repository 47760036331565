<script setup lang="ts">
import { ref } from "vue";
import router from "@/router";

const activeTab = ref(router.currentRoute.value.name);

function toggleTab(tabname: string) {
  activeTab.value = tabname;
}
</script>

<template>
  <div class="container mt-5 tab-container">
    <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
        <router-link
          :to="{ name: 'login' }"
          @click="toggleTab('login')"
          class="nav-link"
          :class="{ active: activeTab === 'login' }"
        >
          Se connecter
        </router-link>
      </li>
      <!--li class="nav-item">
        <router-link
          :to="{ name: 'signup' }"
          @click="toggleTab('signup')"
          class="nav-link"
          :class="{ active: activeTab === 'signup' }"
        >
          Créer un compte
        </router-link>
      </li-->
    </ul>
    <RouterView></RouterView>
  </div>
</template>

<style scoped>
.tab-container {
  max-width: 500px !important;
}
</style>
