type MonitorOptions = {
  intervalInMs?: number;
  triggerOnTabActive?: boolean;
  pauseIntervalOnTabInactive?: boolean;
};

export class Monitor {
  private intervalId: number | null = null;
  private readonly options: MonitorOptions = {
    intervalInMs: 5000,
    triggerOnTabActive: true,
    pauseIntervalOnTabInactive: true,
  };

  // private cb: () => void;
  constructor(private readonly cb: () => void, options?: MonitorOptions) {
    this.options = { ...this.options, ...options };
    this.handleVisibilitychange = this.handleVisibilitychange.bind(this);
  }

  private startInterval() {
    this.stopInterval();
    // @ts-expect-error: the type aim at nodes but this will run on the browser
    this.intervalId = setInterval(() => this.cb(), this.options.intervalInMs);
  }

  private stopInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private handleVisibilitychange() {
    if (document.visibilityState === "hidden") {
      if (this.options.pauseIntervalOnTabInactive) {
        this.stopInterval();
      }
    } else {
      if (this.options.triggerOnTabActive) {
        this.cb();
      }
      if (this.options.pauseIntervalOnTabInactive) {
        this.startInterval();
      }
    }
  }

  start() {
    // we do an initial call, to not wait to the first (possibly long) interval
    this.cb();
    // stats the interval timer
    this.startInterval();

    // if tab becomes inactive, we stop the interval timer and resume when active again
    if (
      this.options.triggerOnTabActive ||
      this.options.pauseIntervalOnTabInactive
    ) {
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilitychange
      );
    }
  }

  stop() {
    this.stopInterval();
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilitychange
    );
  }
}
