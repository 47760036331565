<script setup lang="ts">
import type { NoteConfig } from "@/stores/UserState";
import { computed } from "vue";

const props = defineProps<{
  config: NoteConfig;
  activeNoteConfig: NoteConfig | null;
}>();

const displayedLabel = computed<string>(() => {
  return props.config.label || props.config.name;
});

const isActive = computed<boolean>(() => {
  return Object.is(props.config, props.activeNoteConfig);
});
</script>

<template>
  <button
    class="btn design-button flex-grow-0"
    :class="{
      'btn-dark': isActive,
      'btn-secondary': !isActive,
    }"
  >
    {{ displayedLabel }}
  </button>
</template>

<style>
.design-button {
  white-space: nowrap;
  pointer-events: all;
}
</style>
@/stores/UserState
