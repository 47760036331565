<script setup lang="ts">
import CanvasButton from "../../CanvasButton.vue";
import DraggerMenuState from "@/stores/DraggerMenuState";
import NoteMenuState from "@/stores/NoteMenuState";
import { computed } from "vue";

// toggles the add label on and off
function toggle() {
  DraggerMenuState.toggleLabel();
}

const label = computed(() => {
  return (
    NoteMenuState.activeNote.label ||
    NoteMenuState.activeNote.name.substring(0, 2)
  );
});
</script>
<template>
  <CanvasButton
    @click.prevent.stop="toggle"
    tooltipTitle="Label"
    tooltipText="Active l'ajout du label du désordre."
    :active="DraggerMenuState.label"
    :class="{ 'text-decoration-line-through': !DraggerMenuState.label }"
  >
    {{ label }}
  </CanvasButton>
</template>
<style>
.strikethrough {
  text-decoration: line-through;
}
</style>
