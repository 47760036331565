<script setup lang="ts">
import { Logger } from "@/logger";
import Settings from "@/stores/Settings";
import type { PhotoMarkerShape } from "@/open-cloud/photoMarker";
import { computed } from "vue";

function setMarkerShape(shape: PhotoMarkerShape) {
  Logger.info(`MarkerShapeToggler.vue : toggle marker shape to ${shape}`);
  Settings.setPhotoMarkerShape(shape);
}

const isThin = computed<boolean>(() => {
  return Settings.arrowShape === "thin_arrow";
});
</script>

<template>
  <div class="btn-group">
    <button
      class="btn"
      :class="isThin ? 'btn-primary' : 'btn-outline-primary'"
      @click="() => setMarkerShape('thin_arrow')"
    >
      Fin
    </button>
    <button
      class="btn"
      :class="!isThin ? 'btn-primary' : 'btn-outline-primary'"
      @click="() => setMarkerShape('large_arrow')"
    >
      Large
    </button>
  </div>
</template>
