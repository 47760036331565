import { Logger } from "@/logger";

// [TrueType File, URL]
const FONTS = [
  ["Arial", "/fonts/arial-font/arial.ttf"],
  //["Times", "/fonts/times-font/TimesNewRomanMTStd.ttf"],
  //["Verdana", "/fonts/verdana-font/verdana.ttf"],
];

const DEFAULT_FONT = FONTS[0];

async function downloadFile(url: string): Promise<ArrayBuffer> {
  const response = await fetch(url, { method: "GET" });
  return response.arrayBuffer();
}

/**
 * Use preload to download files (can be combined with caching to improve drawing open time)
 */
async function preloadFonts(): Promise<void> {
  Logger.info(
    `FontLoader.preloadFonts() : preloading fonts ${FONTS.toString()}`
  );
  Promise.all(FONTS.map(async (font) => await downloadFile(font[1])));
}

/**
 * Looks for a font in FONTS that matches filename, returns the font or DEFAULT_FONT if no font was found
 */

async function loadFont(filename: string): Promise<Uint8Array> {
  for (const font of FONTS) {
    if (font[0] === filename) {
      const res = await downloadFile(font[1]);
      return new Uint8Array(res);
    }
  }
  const res = await downloadFile(DEFAULT_FONT[1]);
  return new Uint8Array(res);
}

async function loadDefaultFont(): Promise<Uint8Array> {
  const res = await downloadFile(DEFAULT_FONT[1]);
  return new Uint8Array(res);
}

export { preloadFonts, loadFont, loadDefaultFont };
