import { inject } from "vue";
import {
  type ToastProviderValue,
  ToastSymbol,
} from "@/components/providers/toastProviderSymbols";

/**
 * Sugar wrapper on top of the injector. Allows user to look for available composable typing `use..` and follow IDE autocomplete.
 * Also provides default value so the function can be called directly,
 * otherwise the type returned is `ToastProviderValue | undefined` and need to be behind an `if`
 */
export function useToast(): ToastProviderValue {
  return inject(ToastSymbol, {
    showPrimary: () => {},
    showSuccess: () => {},
    showInfo: () => {},
    showDanger: () => {},
    showWarning: () => {},
    showSecondary: () => {},
    showTertiary: () => {},
    showLight: () => {},
    hideAll: () => {},
  });
}
