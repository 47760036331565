<script setup lang="ts">
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";

defineProps<{
  active?: boolean;
  icon?: string;
  tooltipText?: string;
  tooltipTitle?: string;
}>();

const { refHook } = useLongPressTooltip();
</script>

<template>
  <button
    ref="refHook"
    :class="['btn ', active ? 'btn-dark' : 'btn-secondary']"
    :data-bs-content="tooltipText"
    :data-bs-title="tooltipTitle"
  >
    <i v-if="icon" :class="`bi bi-${icon}`"></i>
    <slot />
  </button>
</template>
