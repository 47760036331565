import Command from "./Command";
const MAX_HISTORY_LENGTH = 50;
type HystoryParams = {
  command: Command<any>;
};

export enum HistoryEvents {
  UndoEnded = "UndoEnded",
  RedoEnded = "RedoEnded",
}

export default class History extends Command<HystoryParams> {
  // override Command.execute since History does want to monitor itself
  execute(params: HystoryParams) {
    super.execute(params, false);
    if (this._undostack.length > MAX_HISTORY_LENGTH) {
      this.shiftUndo();
    }
  }
  _reexecute(params: HystoryParams) {
    params.command.redo();
  }
  _unexecute(params: HystoryParams) {
    params.command.undo();
  }
  undo(): void {
    super.undo();
    if (this._redostack.length > MAX_HISTORY_LENGTH) this.shiftRedo();
    this._viewer.emitEvent(new Event(HistoryEvents.UndoEnded));
  }
  redo(): void {
    super.redo();
    if (this._undostack.length > MAX_HISTORY_LENGTH) this.shiftUndo();
    this._viewer.emitEvent(new Event(HistoryEvents.RedoEnded));
  }
  shiftUndo(): void {
    const oldest = this._undostack.shift();
    oldest?.command.shiftUndo();
  }
  shiftRedo(): void {
    const oldest = this._redostack.shift();
    oldest?.command.shiftRedo();
  }
}
