import type { DRRevision } from "@/repositories/domain.types";
import type { ApiRevisionDto } from "@/repositories/api.types";

export class RevisionDecoder {
  static fromApiDtoToDomain(dto: ApiRevisionDto): DRRevision {
    return {
      drawingId: dto.drawingId,
      format: dto.format,
      fromDevice: "",
      openCloudFileId: dto.openCloudFileId,
      id: dto.id,
      savedAt: new Date(dto.savedAt),
      editedAt: dto.editedAt ? new Date(dto.editedAt) : undefined,
    };
  }
}
