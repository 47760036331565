<script setup>
import { useDrawingStore } from "@/stores/fileStore";
import { Logger } from "@/logger";
import router from "@/router";
import UserState from "@/stores/UserState";
import { logout } from "@/utils/logout";

const drawingStore = useDrawingStore();
const logoutHandler = async () => {
  Logger.info(`OffCanvasAccountInfo.vue : logging user out`);
  if (!drawingStore.areAllSynced) {
    if (
      !window.confirm(
        "Vous avez au moins un fichier non-synchronisé avec le serveur. Se déconnecter entrainera la perte des changements. Êtes-vous sur de vouloir de vouloir vous déconnecter ?"
      )
    ) {
      Logger.info(`OffCanvasAccountInfo.vue : user cancels logging out`);
      return;
    } else {
      Logger.info(`OffCanvasAccountInfo.vue : user accepts to lose drawings`);
    }
  }

  if (drawingStore.uploadTrackers.length != 0) {
    if (
      window.confirm(
        "Vos dessins sont en cours de téléchargement, vous allez les perdre en continuant. Voulez-vous continuer ?"
      )
    ) {
      Logger.info(`OffCanvasAccountInfo.vue : user accepts to lose uploads`);
      await drawingStore.deleteWaitingJobs();
    } else {
      Logger.info(`OffCanvasAccountInfo.vue : user cancels logging out`);
      return;
    }
  }
  logout();
  router.replace({ name: "welcome" });
};
</script>
<template>
  <a
    class="btn btn-lg btn-primary"
    data-bs-toggle="offcanvas"
    href="#offcanvasAccountInfo"
    role="button"
    aria-controls="offcanvasHelp"
  >
    <i class="bi bi-person-circle"></i>
  </a>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasAccountInfo"
    aria-labelledby="offcanvasAccountInfoLabel"
  >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="offcanvasHelpLabel">Compte</h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">Nom: {{ UserState.data.full_name }}</li>
        <li class="list-group-item">Email: {{ UserState.data.email }}</li>
        <li class="list-group-item">
          <a class="btn btn-primary" href="#" @click="logoutHandler"
            >Se déconnecter</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
@/stores/UserState
