import type { DRViewer } from "@/open-cloud/DRViewer";
import { Logger } from "@/logger";
//import type BlockViewer from "@/open-cloud/BlockViewer";

export type ViewProperty = {
  borderVisibility?: boolean;
  borderColor?: [number, number, number, number];
  borderWidth?: number;
  viewport?: [VisualizeJS.VectorOdTvPoint2d, VisualizeJS.VectorOdTvPoint2d];
  models?: VisualizeJS.TvModel[];
};

export class ViewBuilder {
  viewer: DRViewer /*| BlockViewer*/;

  constructor(viewer: DRViewer /*| BlockViewer*/) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  static setProperty(view: VisualizeJS.View, props: ViewProperty) {
    if (props.borderVisibility)
      view.setBorderVisibility(props.borderVisibility);
    if (props.borderWidth) view.borderWidth = props.borderWidth;
    if (props.viewport) view.setViewport(props.viewport[0], props.viewport[1]);
    if (props.models) this.addModels(view, props.models);
    if (props.borderColor) view.borderColor = props.borderColor;
  }

  static addModels(view: VisualizeJS.View, models: VisualizeJS.TvModel[]) {
    models.map((model) => view.addModel(model));
  }

  static logModels(view: VisualizeJS.View) {
    for (let i = 0; i < view.numModels(); i++) {
      const model = view.modelAt(i);
      Logger.debug(model.getName());
    }
  }

  initLineWeightMode() {
    const viewer = this.visViewer;
    const device = viewer.getActiveDevice();
    const activeView = device.getActiveView();
    activeView.setLineWeightMode(
      this.visLib.OdTvGsViewLineWeightMode.kDeviceFixed
    );
    Logger.info(
      `ViewBuilder.initLineWeightMode : ${
        activeView.getLineWeightMode().value
      } ${activeView.getLineWeightScale()}`
    );
  }
}
