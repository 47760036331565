<script setup lang="ts">
import { ref } from "vue";
import APIClient from "@/APIClient";
import { getErrorMessage, PASSWORD_RG_PATTERN } from "@/utils/misc.utils";
import LoadingPrimaryButton from "./LoadingPrimaryButton.vue";
import { useRouter } from "vue-router";
import { useToast } from "@/components/composable/useToast";
import PasswordRequirements from "@/components/welcome/PasswordRequirements.vue";
import { Logger } from "@/logger";

const password = ref("");
const confirmation = ref("");

const isLoading = ref(false);
const showError = ref("");

const props = defineProps<{
  token: string;
}>();

if (!props.token) {
  showError.value =
    "Le lien de réinitialisation est invalide. Avez-vous ouvert le lien directement depuis l'email ?";
}
const router = useRouter();
const { showSuccess } = useToast();
async function submit() {
  if (password.value === confirmation.value) {
    isLoading.value = true;
    try {
      await APIClient.reset(props.token, password.value);
      isLoading.value = false;
      showError.value = "";
      showSuccess("Votre mot de passe a été réinitialisé.");
      Logger.info(`Reset.vue : password was reset`);
      router.push({ name: "login" });
    } catch (error) {
      isLoading.value = false;
      Logger.error(
        `Reset.vue : Couldn't reset password: ${JSON.stringify(error)}`
      );
      showError.value = getErrorMessage(error, {
        auth: "Lien invalide. Avez-vous ouvert le lien directement depuis l'email ?",
      });
    }
  } else {
    showError.value = getErrorMessage(undefined, {
      default: "Les deux mots de passe ne correspondent pas",
    });
  }
}
</script>

<template>
  <form @submit.prevent="submit" id="login-form">
    <h5 class="mt-3 mb-2">Choisissez votre nouveau mot de passe</h5>
    <div class="form-floating my-3">
      <input
        v-model="password"
        type="password"
        class="form-control"
        id="password"
        placeholder="Mot de passe"
        :pattern="PASSWORD_RG_PATTERN"
        required
      />
      <label for="password">Mot de passe</label>
    </div>
    <div class="form-floating mb-3">
      <input
        class="form-control my-3"
        type="password"
        id="confirmation"
        v-model="confirmation"
        title="Les mots de passe ne correspondent pas."
        placeholder="Confirmer le mot de passe"
        required
      />
      <label for="password">Confirmer le mot de passe</label>
    </div>
    <PasswordRequirements />
    <div class="text-center">
      <LoadingPrimaryButton :isLoading="isLoading">
        Sauvegarder
      </LoadingPrimaryButton>
    </div>
  </form>
  <div v-if="showError !== ''" class="alert alert-danger mt-3" role="alert">
    {{ showError }}
  </div>
</template>
