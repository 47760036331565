import DRBaseDragger from "./DRBaseDragger";
import { DraggerEvents } from "./draggers.type";
import {
  appendCursorEntityToModel,
  ORIGIN_DESIGN_PROPS,
  ORIGIN_RADIUS,
} from "./composables/cursors";
import { Logger } from "@/logger";

export class OriginDragger extends DRBaseDragger {
  start(x: number, y: number) {
    super.start(x, y);
    if (this.isDragging) {
      this._updateFrame();
    }
  }

  drag(x: number, y: number) {
    if (this.isDragging) {
      this.start(x, y);
    }
  }

  end() {
    // When restoring dragger after Pan zoom, end can be triggered with no pan.
    if (this.startCornerWCS.length === 3) {
      this.viewer.emitEvent(
        new CustomEvent(DraggerEvents.OriginSet, {
          detail: {
            coordinates: Array.from(this.startCornerWCS),
          },
        })
      );
    }
    Logger.info(
      `originDragger.end : ${this.viewer.activeDragger()?.name} added an entity`
    );
    this.clear();
  }
  _updateFrame() {
    if (this.startCornerWCS.length === 3) {
      this.clearShadow();
      const radius = this.viewer.toolbox.screenDistanceToWorld(ORIGIN_RADIUS);
      this.shadowId = appendCursorEntityToModel(
        this.viewer.modelBuilder.findModel("MUP"),
        this.startCornerWCS,
        radius
      );
      this.viewer.entityBuilder.setProperties(
        this.shadowId,
        ORIGIN_DESIGN_PROPS
      );
    }
  }
}
