import { db } from "@/repositories/database";
import type { RepositoryBaseInterface } from "@/repositories/repository.interface";
import { BaseRepository } from "@/repositories/base.repository";
import type { DRProperty } from "./domain.types";

export const IGNORED_TEXTSTLE_NAMES = ["Standard"];

class PropertyRepository
  extends BaseRepository<DRProperty, string>
  implements RepositoryBaseInterface<DRProperty>
{
  constructor() {
    super(db.properties, "properties");
  }
}

export const propertyRepository = new PropertyRepository();
