import OneWayEntityDragger from "./OneWayEntityDragger";

// FIXME : we should move this somewhere else, app fails without it
import { useDeviceState } from "@/stores/DeviceState";
import { pinia } from "@/stores/pinia";
const deviceState = useDeviceState(pinia);
//////

export default class BlockDragger extends OneWayEntityDragger {
  _updateFrame(): void {
    this.refreshShadowEntity();
    if (
      this.shadowId &&
      this.viewer.activeBlock &&
      this.endCornerWCS.length === 3
    ) {
      const model: VisualizeJS.TvModel =
        this.viewer.modelBuilder.findModel("MUP");
      this.refreshShadowEntity();
      this.shadowId = model.appendInsert(this.viewer.activeBlock, "DR_Insert");
      this._setNoteConfigProperties(this.shadowId);
      const entityPtr = this.shadowId.openObjectAsInsert();
      entityPtr.setPosition(this.endCornerWCS);
      entityPtr.delete();
      model.delete();
    }
  }
}
