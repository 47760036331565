import formatDistance from "date-fns/formatDistance";
import { fr } from "date-fns/locale";
import { APIError } from "@/APIClient";

export function isIterable(obj: any) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

export function formatDistanceToNow(date: string | Date): string {
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
    locale: fr,
  });
}

export const EMAIL_RG_PATTERN =
  "^\\w+([\\.\\-]?\\w+)*@\\w+([\\.\\-]?\\w+)*(\\.\\w{2,3})+$";

export const OCS_SPECIAL_CHAR_FILES = "+$!%*?&";

// Please propagate changes to this constant or to the rg pattern to the backend too
export const OCS_SPECIAL_CHAR_PWD = "$!%*?&";
export const PASSWORD_RG_PATTERN = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${OCS_SPECIAL_CHAR_PWD}])[A-Za-z\\d${OCS_SPECIAL_CHAR_PWD}]{8,}$`;

export const removeAccents = (str: string): string =>
  str.normalize("NFD").replace(/\p{Diacritic}/gu, "");

export const removeSpecialCharsForOcsFiles = (str: string): string =>
  str
    .replace(/œ/g, "oe")
    .replace(/æ/g, "ae")
    .replace(new RegExp(`[^\\w\\s-_${OCS_SPECIAL_CHAR_FILES}]`, "g"), "");

export const normalizeForOCS = (str: string): string =>
  removeSpecialCharsForOcsFiles(removeAccents(str));

export function getErrorMessage(
  error: unknown,
  messages: { auth?: string; default?: string; offline?: string } = {}
) {
  if (error instanceof APIError) {
    if (error.isAuthError()) {
      return messages.auth || "Problème d'authentification.";
    }
    const msg = error.userMessage();
    if (msg) {
      return msg;
    }
  } else if (error instanceof Error && APIError.isOffline(error)) {
    return (
      messages.offline || "Vérifiez que votre connexion internet est active."
    );
  }
  return messages.default || "Il y a eu un problème lors de votre connexion.";
}

// Returns a function that wraps a function argument and monitor time taken by a function to execute.
// If this time is higher or equal to a delay, then it triggers a cb function
// Delay is in ms

export function monitorPerformance(
  func: (...args: any[]) => any,
  delay: number,
  cb: () => void
): (...args: any[]) => any {
  return function (...args: any[]) {
    const startTime = performance.now();
    const result = func(...args);
    const endTime = performance.now();
    const duration = endTime - startTime;

    if (duration >= delay) {
      cb();
    }
    return result;
  };
}
