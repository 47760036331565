import { Logger } from "@/logger";

const WINDOW_SIZE = 5;

// Function to smooth a 3D polyline (with constant z-coordinate) using a moving average filter
export function smooth3DPolyline(
  polyline: number[],
  windowSize: number = WINDOW_SIZE
): number[] {
  const smoothedPolyline: number[] = [];
  if (polyline.length % 3 !== 0) {
    Logger.warn(`smooth.utils.ts : polyline has not the right number count`);
    return polyline;
  }

  const count = polyline.length / 3;

  // Ensure window size is odd for symmetry
  if (windowSize % 2 === 0) {
    windowSize++;
  }

  const halfWindowSize = Math.floor(windowSize / 2);
  const constantZ = polyline[2];
  for (let i = 0; i < count; i++) {
    // Get the constant z-coordinate
    const xValues = [];
    const yValues = [];

    // Extract x and y values within the window
    for (
      let j = Math.max(i - halfWindowSize, 0);
      j <= Math.min(i + halfWindowSize, count - 1);
      j++
    ) {
      xValues.push(polyline[j * 3]);
      yValues.push(polyline[j * 3 + 1]);
    }

    // Calculate the average x and y values
    const averageX =
      xValues.reduce((acc, val) => acc + val, 0) / xValues.length;
    const averageY =
      yValues.reduce((acc, val) => acc + val, 0) / yValues.length;

    // Push the averaged x, y, and constant z values to the smoothed polyline
    smoothedPolyline.push(averageX, averageY, constantZ);
  }

  return smoothedPolyline;
}
