<script setup lang="ts">
import DraggerMenuState from "@/stores/DraggerMenuState";
import { ref } from "vue";
import type { ExposedHtmlInput } from "./text.type";

const props = withDefaults(
  defineProps<{
    preventFocusOnClear: boolean;
  }>(),
  {
    preventFocusOnClear: false,
  }
);

const TextInputHTML = ref<HTMLInputElement | null>(null);
const preventBlur = ref<boolean>(false);

function setActiveText() {
  DraggerMenuState.setActiveText(DraggerMenuState.currentTextInput);
}

function clear() {
  DraggerMenuState.currentTextInput = "";
  // focus element on clear to save a click

  setActiveText();
  if (!props.preventFocusOnClear) {
    TextInputHTML.value?.focus();
  }
}

function disableBlur() {
  preventBlur.value = true;
}

function focus() {
  TextInputHTML.value?.focus();
}

function blur() {
  TextInputHTML.value?.blur();
}

function handleBlur(e: FocusEvent) {
  if (preventBlur.value) {
    // Prevent the blur event by refocusing the input
    e.preventDefault();
    e.stopImmediatePropagation();
    TextInputHTML.value?.focus();
    preventBlur.value = false; // Reset the flag
  }
}

defineExpose<ExposedHtmlInput>({
  focus,
  blur,
});
</script>
<template>
  <input
    ref="TextInputHTML"
    v-model="DraggerMenuState.currentTextInput"
    key="TextInput"
    type="text"
    @blur="(e: FocusEvent) => handleBlur(e)"
    @focus="setActiveText()"
    @input="setActiveText()"
    class="form-control"
    placeholder="Texte"
  />
  <button
    class="btn btn-secondary"
    @pointerdown="disableBlur"
    @click.prevent.stop="clear"
  >
    <i class="bi bi-x-lg" />
  </button>
</template>
