<script setup lang="ts">
import Dragger from "../Tools/Dragger.vue";
import DraggerMenuState from "@/stores/DraggerMenuState";
import {
  DraggerName,
  type DraggerConfig,
} from "@/open-cloud/draggers/draggers.type";
import { Logger } from "@/logger";
import { Facade } from "@/open-cloud/Facade";
import CanvasButton from "../CanvasButton.vue";

function activateDragger(config: DraggerConfig) {
  Logger.info(`ZoomMenu.vue : activating dragger ${config.name}`);
  DraggerMenuState.setActiveDragger(config);
}
function zoomExtents() {
  Facade.zoomExtents();
}
</script>
<template>
  <Dragger
    :config="{ name: DraggerName.DrZoomToWindow }"
    @click="activateDragger({ name: DraggerName.DrZoomToWindow })"
    :activeDragger="DraggerMenuState.activeDragger"
    tooltipText="Permet de dessiner une zone et de zoomer sur cette zone."
    tooltipTitle="Zoom"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      class="bi"
      viewBox="0,0,16,16"
      width="16"
      height="16"
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
    >
      <!-- Corners -->
      <circle r="1" cx="2" cy="2" />
      <circle r="1" cx="14" cy="2" />
      <circle r="1" cx="14" cy="14" />
      <circle r="1" cx="2" cy="14" />
      <circle r="1" cx="2" cy="14" />
      <!-- Rectangle -->
      <rect width="12" height="12" x="2" y="2" />
      <!-- Goggle -->
      <circle r="4" cx="8" cy="8" />
      <line x1="11" y1="11" x2="16" y2="16" stroke-width="3" />
    </svg>
  </Dragger>
  <CanvasButton
    @click="zoomExtents"
    icon="zoom-out"
    :activeDragger="DraggerMenuState.activeDragger"
    tooltipText="Permet de placer le zoom et le centrer pour voir l'ensemble du modèle objet."
    tooltipTitle="Zoom Modèle"
  />
</template>
