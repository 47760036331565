//import { OrbitAction } from "open-cloud-client/src/Viewer/Draggers/Actions/OrbitAction";
import { PanAction } from "@inweb/viewer-visualize/src/Viewer/Draggers/Actions/PanAction";
import { ZoomAction } from "@inweb/viewer-visualize/src/Viewer/Draggers/Actions/ZoomAction";
import { OdBaseDragger } from "@inweb/viewer-visualize/src/Viewer/Draggers/Common/OdBaseDragger";
import type { DRViewer } from "@/open-cloud/DRViewer";
import { delayedPanZoomEnded } from "./navigation.utils";
import DraggerState from "../DraggersState";
import type { Point2d } from "@inweb/viewer-visualize/src/Viewer/Draggers/Common/Geometry";
import { Logger } from "@/logger";
import { DraggerEvents } from "../draggers.type";
export enum GestureAction {
  None,
  Pan,
  ZoomPan,
}

// ADDED
const MIN_NTH_ZOOM = 5;
const ZOOM_COEF = window.devicePixelRatio / 2000;
const MAX_SPEED_DELTA = 0.1;
//

export default class DRGestureManager extends OdBaseDragger {
  private _previousEvents = new Map<number, PointerEvent>();
  private _currentEvents = new Map<number, PointerEvent>();
  private _lastGestureAction = GestureAction.None;
  private _panAction: PanAction;
  private _zoomAction: ZoomAction;

  private nthZoom = 0; // ADDED
  // 2 modes :
  // true means that stylus is used to draw and then 1 finger pan, 2 finger panzoom
  // false means that only 2 fingers panzoom
  isSingleTouchEnabled = false;

  private eventNotInCurrentEvents(event: PointerEvent): boolean {
    return !this._currentEvents.get(event.pointerId);
  }

  public constructor(subject: DRViewer, isSingleTouchEnabled: boolean) {
    super(subject);

    this.isSingleTouchEnabled = isSingleTouchEnabled; // ADDED

    this._panAction = new PanAction(
      this.m_module,
      this.subject,
      this.beginInteractivity,
      this.endInteractivity,
      this.getViewParams,
      this.setViewParams
    );
    this._zoomAction = new ZoomAction(this.m_module, this.subject);
  }

  private getMiddlePoint(
    events: Map<number, PointerEvent>
  ): Point2d | undefined {
    if (events.size !== 2) {
      return undefined;
    }

    const keys = this.getKeys(events);
    const point0 = this.relativeCoords(events.get(keys[0]));
    const point1 = this.relativeCoords(events.get(keys[1]));

    return {
      x: Math.floor((point0.x + point1.x) / 2),
      y: Math.floor((point0.y + point1.y) / 2),
    };
  }

  private getFirstPoint(
    events: Map<number, PointerEvent>
  ): Point2d | undefined {
    if (events.size < 1) {
      return undefined;
    }

    const keys = this.getKeys(events);
    return this.relativeCoords(events.get(keys[0]));
  }

  private getDistance(events: Map<number, PointerEvent>): number {
    if (events.size !== 2) {
      return -1;
    }

    const keys = this.getKeys(events);
    const point0 = this.relativeCoords(events.get(keys[0]));
    const point1 = this.relativeCoords(events.get(keys[1]));

    return Math.hypot(point0.x - point1.x, point0.y - point1.y);
  }

  /**
   * Check if event is in current event map. if yes,
   * set it as current and the former one with same id to previous
   * @param event new event
   */
  private updateEvent(event: PointerEvent) {
    const previousEvent = this._currentEvents.get(event.pointerId);
    if (previousEvent) {
      this._previousEvents.set(previousEvent.pointerId, previousEvent);
    }

    this._currentEvents.set(event.pointerId, event);
  }

  private removeEvent(event: PointerEvent) {
    this._currentEvents.delete(event.pointerId);
    this._previousEvents.delete(event.pointerId);
  }

  private getKeys(map: Map<number, PointerEvent>): number[] {
    return Array.from(map.keys());
  }

  /**
   * Analyze the number of fingers, the mode that is set and execute the right action
   */
  private analyzeGesture() {
    // MODIFIED
    if (this._currentEvents.size == 1 && this.isSingleTouchEnabled) {
      DraggerState.isGestureActive = true;
      if (DraggerState.isGestureActive) {
        const point = this.getFirstPoint(this._currentEvents);
        if (point) this.executePanAction(point);
      }
    } else if (this._currentEvents.size == 2) {
      DraggerState.forceGestureActive(); // If there are 2 finger on screen Gesture manager takes control
      this.executePanZoomAction();
    }
  }

  private executePanZoomAction() {
    const center = this.getMiddlePoint(this._currentEvents);
    if (!center) return;
    if (this._lastGestureAction != GestureAction.ZoomPan) {
      Logger.info(
        `DRGestureManager.ts : user is panzooming, isSingleTouchEnabled : ${this.isSingleTouchEnabled}`
      );
      Logger.info(
        `DRGestureManager.ts : user is panzooming, isSingleTouchEnabled : ${this.isSingleTouchEnabled}`
      );
      this.executeEndAction(this._lastGestureAction);
      this._lastGestureAction = GestureAction.ZoomPan;
      this.onmessage({ type: DraggerEvents.AutoPanZoomStart });
      this._panAction.beginAction(center.x, center.y);
    }
    this._panAction.action(center.x, center.y);

    const currentDistance = this.getDistance(this._currentEvents);
    const previousDistance = this.getDistance(this._previousEvents);
    const delta = currentDistance - previousDistance;
    if (this.nthZoom > MIN_NTH_ZOOM) {
      const zoomSpeed = delta * ZOOM_COEF;
      const smoothenSpeed =
        Math.min(MAX_SPEED_DELTA, Math.abs(zoomSpeed)) * Math.sign(zoomSpeed);
      const zoomFactor = 1 + smoothenSpeed;
      const middlePoint = this.getMiddlePoint(this._currentEvents);
      if (!middlePoint) return;
      this._zoomAction.action(middlePoint.x, middlePoint.y, zoomFactor);
    } else if (delta) {
      // allows to smoothen zoom when user stop pinching
      this.nthZoom++;
    }

    this.subject.update();
  }
  //

  private executePanAction(currentPoint: Point2d) {
    if (this._lastGestureAction !== GestureAction.Pan) {
      Logger.info(`DRGestureManager.ts : user is panning`);
      Logger.info(`DRGestureManager.ts : user is panning`);
      this.executeEndAction(this._lastGestureAction);
      this._lastGestureAction = GestureAction.Pan;
      this.onmessage({ type: DraggerEvents.AutoPanZoomStart });
      this._panAction.beginAction(currentPoint.x, currentPoint.y);
    }

    this._panAction.action(currentPoint.x, currentPoint.y);
    this.subject.update();
  }

  private executeEndAction(gestureAction: GestureAction) {
    if (gestureAction === GestureAction.ZoomPan) {
      this._panAction.endAction();
      this.nthZoom = 0;
    } // ADDED
    if (gestureAction === GestureAction.Pan) this._panAction.endAction();
  }

  /**
   * Ignore event if wheel (ie. zoom) is not allowed or it is not a touch event
   * @param event
   * @returns true if event should be ignored
   */
  private needIgnoreEvent(event: PointerEvent): boolean {
    return (
      !this.subject.options.enableZoomWheel ||
      //!this.subject.options.enableGestures ||
      !this.eventIsTouchEvent(event)
    );
  }

  private eventIsTouchEvent(event: PointerEvent): boolean {
    return event.pointerType === "touch" || event.pointerType === "";
  }

  override pointerdown(event: PointerEvent) {
    if (this.needIgnoreEvent(event) || this._currentEvents.size === 2) {
      return;
    }
    this.updateEvent(event);
    this.analyzeGesture();
  }

  override pointermove(event: PointerEvent) {
    if (this.needIgnoreEvent(event) || this.eventNotInCurrentEvents(event)) {
      return;
    }

    this.updateEvent(event);
    this.analyzeGesture();
  }

  override pointerup(event: PointerEvent) {
    if (this.needIgnoreEvent(event) || this.eventNotInCurrentEvents(event)) {
      return;
    }

    this.removeEvent(event);
    if (this.isSingleTouchEnabled && this._currentEvents.size < 1) {
      Logger.info(`DRGestureManage.pointerup : done panzooing`);
      this.executeEndAction(this._lastGestureAction);
      this._lastGestureAction = GestureAction.None;
      delayedPanZoomEnded(this);
    } else if (this.isSingleTouchEnabled && this._currentEvents.size < 2) {
      Logger.info(
        `DRGestureManage.pointerup : user removes one pointer from screen`
      );
      Logger.info(
        `DRGestureManage.pointerup : user removes one pointer from screen`
      );
      this.nthZoom = 0;
    } else {
      Logger.info(
        `DRGestureManage.pointerup : user stops panzooming in 2 fingers mode`
      );
      Logger.info(
        `DRGestureManage.pointerup : user stops panzooming in 2 fingers mode`
      );
      this.executeEndAction(this._lastGestureAction);
      this._lastGestureAction = GestureAction.None;
      delayedPanZoomEnded(this);
    }
  }

  override pointercancel(event: PointerEvent) {
    if (this.needIgnoreEvent(event) || this.eventNotInCurrentEvents(event)) {
      return;
    }

    this.pointerup(event);
  }

  pointerleave(event: PointerEvent) {
    if (this.needIgnoreEvent(event) || this.eventNotInCurrentEvents(event)) {
      return;
    }

    this.pointerup(event);
  }
}
