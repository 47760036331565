<script setup lang="ts">
import DraggerMenuState from "@/stores/DraggerMenuState";
import CanvasButton from "@/components/canvas/CanvasButton.vue";

function toggle() {
  DraggerMenuState.toggleSmoothen();
}
</script>
<template>
  <CanvasButton
    :active="DraggerMenuState.smoothen"
    @click.prevent.stop="toggle"
    tooltipTitle="Lissage"
    tooltipText="Active le lissage des courbes"
  >
    <i class="bi bi-bezier"></i>
  </CanvasButton>
</template>
