import type { DRViewer } from "@/open-cloud/DRViewer";
import DRBaseDragger from "../DRBaseDragger";
import { debounce } from "lodash";
import SelectDragger, {
  MINIMUM_WINDOW_SIZE,
  SELECTION_WINDOW_ENTITY_PROPS,
} from "../selectDragger";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import { DraggerEvents } from "../draggers.type";

export default class DrZoomToWindow extends DRBaseDragger {
  constructor(viewer: DRViewer) {
    super(viewer);
  }

  start(x: number, y: number) {
    super.start(x, y);
  }

  drag(x: number, y: number) {
    super.drag(x, y);
    // on touch device, drag can be triggered very fast. Then, select point which triggers unselect never happens
    // we put a minimum window size in order to improve ux
    if (
      this.startCornerCanvas.length === 3 &&
      this.endCornerCanvas.length === 3
    ) {
      const window_size = Toolbox.computeDistance2D(
        [x, y, 0],
        this.startCornerCanvas
      );
      if (this.isDragging && window_size >= MINIMUM_WINDOW_SIZE) {
        this._updateFrame();
      }
    }
  }

  _updateFrame() {
    if (
      this.endCornerCanvas.length === 3 &&
      this.startCornerCanvas.length === 3
    ) {
      this.refreshShadowEntity();
      if (
        !this.shadowId ||
        this.startCornerWCS.length === 0 ||
        this.endCornerWCS.length === 0
      )
        return;
      SelectDragger.drawSelectionWindow(
        this.shadowId,
        this.startCornerWCS,
        this.endCornerWCS
      );
      this._setProperties(this.shadowId, SELECTION_WINDOW_ENTITY_PROPS);
    }
  }

  end(x: number, y: number) {
    if (
      this.startCornerCanvas.length === 3 &&
      this.endCornerCanvas.length === 3
    ) {
      const viewer: VisualizeJS.Viewer = this.viewer.visViewer();
      viewer.zoomWindow(
        this.startCornerCanvas[0],
        this.startCornerCanvas[1],
        this.endCornerCanvas[0],
        this.endCornerCanvas[1]
      );
    }
    // zoom extent can take time but does not block instruction flow.
    // it does not return a promise either.
    // only isRunningAnimation() tells if viewer is done zooming.
    // we then use a debounce approach
    const debounceEmit = debounce(() => {
      if (this.viewer) {
        const viewer: VisualizeJS.Viewer = this.viewer.visViewer();
        if (viewer.isRunningAnimation()) {
          debounceEmit();
        } else {
          this.viewer.emit({ type: DraggerEvents.AutoPanZoomEnded });
        }
      }
    }, 500);
    debounceEmit();
    super.end(x, y);
  }
}
