<script setup lang="ts">
import { provide, ref } from "vue";
import Toast from "bootstrap/js/dist/toast";
import { ToastSymbol } from "@/components/providers/toastProviderSymbols";
import type { ColorTheme, FontSize } from "@/utils/bootstrap.utils";

const toastRef = ref();
const textContent = ref("");

const setThemeToToast = (theme: ColorTheme) => {
  toastRef.value.classList.remove(
    "text-bg-primary",
    "text-bg-success",
    "text-bg-secondary",
    "text-bg-tertiary",
    "text-bg-light",
    "text-bg-info",
    "text-bg-danger",
    "text-bg-warning"
  );
  toastRef.value.classList.add(`text-bg-${theme}`);
};

const setFontSizeToToast = (size: FontSize) => {
  toastRef.value.classList.remove(
    "fs-1",
    "fs-2",
    "fs-3",
    "fs-4",
    "fs-5",
    "fs-6"
  );
  toastRef.value.classList.add(`fs-${size}`);
};

const makeToastFn = (theme: ColorTheme) => {
  return (text: string, fontsize: FontSize = 6, options?: Toast.Options) => {
    setThemeToToast(theme);
    setFontSizeToToast(fontsize);
    if (toastRef.value) {
      // `dispose` because `hide` is not immediate. If you hide it and then do a .show the `hide` may comes after the `show` and cancel it
      Toast.getOrCreateInstance(toastRef.value).dispose();
      textContent.value = text;
      const toast = new Toast(toastRef.value, options);
      toast.show();
    }
  };
};

const hideAll = () => {
  Toast.getOrCreateInstance(toastRef.value).hide();
};

provide(ToastSymbol, {
  showPrimary: makeToastFn("primary"),
  showSuccess: makeToastFn("success"),
  showInfo: makeToastFn("info"),
  showDanger: makeToastFn("danger"),
  showWarning: makeToastFn("warning"),
  showSecondary: makeToastFn("secondary"),
  showTertiary: makeToastFn("tertiary"),
  showLight: makeToastFn("light"),
  hideAll,
});
</script>
<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <div
      id="notification-toast"
      ref="toastRef"
      class="toast w-auto"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body">{{ textContent }}</div>
        <button
          type="button"
          class="btn-close me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
  <slot></slot>
</template>
