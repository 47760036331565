<script setup lang="ts">
import type {
  GeometryStatus,
  PropertyStatus,
} from "@/repositories/domain.types";
import { computed } from "vue";
import StatusPill from "@/components/files/UploadList/StatusPill.vue";
import type { ColorTheme } from "@/utils/bootstrap.utils";

const props = defineProps<{
  status: GeometryStatus | PropertyStatus;
}>();

const colorStatus = computed(() => {
  const colorTable: Record<GeometryStatus | PropertyStatus, ColorTheme> = {
    inprogress: "warning",
    waiting: "warning",
    done: "success",
    failed: "danger",
    none: "danger",
  };
  return colorTable[props.status] || "primary";
});

const humanStatus = computed(() => {
  const translationTable = {
    inprogress: "Analyse en cours",
    waiting: "Analyse en cours",
    done: "Terminé",
    failed: "Erreur chargement",
    none: "Manquante",
  };
  return translationTable[props.status];
});
</script>

<template>
  <StatusPill :status="colorStatus">{{ humanStatus }} </StatusPill>
</template>
