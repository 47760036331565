import type { DRViewer } from "@/open-cloud/DRViewer";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import { ORIGIN_DESIGN_PROPS, ORIGIN_RADIUS } from "./cursors";
import { appendCursorEntityToModel } from "./cursors";
import type { pointArray } from "@/open-cloud/types/oda.types";
const MAXIMUM_DISTANCE_TO_CLOSE = 30 * window.devicePixelRatio;
const MINIMUM_DRAWPOINTS_COUNT = 30 * 3; // 30 points

export function isExtremitiesClose(
  drawPoints: number[],
  viewer: DRViewer
): boolean {
  const length = drawPoints.length;
  const maxDistanceToCloseWCS = viewer.toolbox.screenDistanceToWorld(
    MAXIMUM_DISTANCE_TO_CLOSE
  );
  if (length >= 6) {
    const start: pointArray = [drawPoints[0], drawPoints[1], drawPoints[2]];
    const end: pointArray = [
      drawPoints[length - 3],
      drawPoints[length - 2],
      drawPoints[length - 1],
    ];
    const distance = Toolbox.computeDistance2D(start, end);
    if (distance <= maxDistanceToCloseWCS) {
      return true;
    }
  }
  return false;
}
/**
 * Check if the 2 extremities of the curve are close and add cursor to the start if so
 * @param cursorId this reference to the ongoing cursor to
 * @param drawPoints points of the curve
 * @param viewer viewer that contains the markup model to create the cursor
 * @returns the reference to the cursor
 */
export function checkIfCloseAndAddCursorStart(
  cursorId: VisualizeJS.OdTvEntityId | null,
  drawPoints: number[],
  viewer: DRViewer,
  minPointCount = MINIMUM_DRAWPOINTS_COUNT
): VisualizeJS.OdTvEntityId | null {
  if (drawPoints.length < minPointCount) return null;
  if (isExtremitiesClose(drawPoints, viewer) && cursorId == null) {
    const start: [number, number, number] = drawPoints.slice(undefined, 3) as [
      number,
      number,
      number
    ];
    const model: VisualizeJS.TvModel = viewer.visViewer().getMarkupModel();
    const radius = viewer.toolbox.screenDistanceToWorld(ORIGIN_RADIUS);
    cursorId = appendCursorEntityToModel(model, start, radius);
    viewer.entityBuilder.setProperties(cursorId, ORIGIN_DESIGN_PROPS);
    model.delete();
  } else if (!isExtremitiesClose(drawPoints, viewer)) {
    cursorId = clearCursor(cursorId, viewer);
  }
  return cursorId;
}

export function clearCursor(
  cursorId: VisualizeJS.OdTvEntityId | null,
  viewer: DRViewer
) {
  const model: VisualizeJS.TvModel = viewer.visViewer().getMarkupModel();
  if (cursorId != null) {
    model.removeEntity(cursorId);
    cursorId.delete();
  }
  cursorId = null;
  model.delete();
  return cursorId;
}
