import { db } from "@/repositories/database";
import type { DRDrawing, OdaBinaryFormat } from "@/repositories/domain.types";
import type { RepositoryBaseInterface } from "@/repositories/repository.interface";
import { map } from "lodash";
import { BaseRepository } from "@/repositories/base.repository";
import { Logger } from "@/logger";

class DrawingRepository
  extends BaseRepository<DRDrawing, number>
  implements RepositoryBaseInterface<DRDrawing>
{
  constructor() {
    super(db.drawings, "drawing");
  }

  fetchAllByNewest() {
    Logger.info(`drawing.repository.ts : fetch drawings by newest`);
    const start = performance.now();
    const drawings = db.drawings.reverse().sortBy("createdAt");
    const end = performance.now();
    Logger.info(
      `drawing.repository.ts : fetch drawings done in ${end - start} ms`
    );
    return drawings;
  }

  replace(drawing: DRDrawing) {
    return db.drawings.put(drawing);
  }

  update(fileId: number, update: Partial<Omit<DRDrawing, "id">>) {
    return db.drawings.update(fileId, update);
  }

  async pruneManyFromList(files: DRDrawing[]): Promise<number> {
    const fileIds = map(files, "id");
    return this.pruneManyFromIds(fileIds, "id");
  }

  createMany(items: DRDrawing[]) {
    return db.transaction(
      "rw",
      db.drawings,
      async (): Promise<{ created: number[] }> => {
        const { failed, created } = await this.createMany2(items);
        if (failed.length) {
          // already existed, so we're updating them
          await Promise.all(
            failed.map(async (failedId) => {
              const failedItem = items.find((item) => item.id === failedId);
              if (!failedItem) {
                throw new Error(
                  "Item which insertion was rejected cannot be found in initial items array"
                );
              }

              const existing = await db.drawings.get(failedId);
              if (!existing) {
                throw new Error(
                  "Item which insertion was rejected cannot be found in db"
                );
              }
            })
          );
        }
        return { created };
      }
    );
  }

  async saveLastRevisionVsf(
    drawingId: number,
    vsf: ArrayBuffer
  ): Promise<void> {
    const count = await db.drawings.update(drawingId, {
      "lastRevision.vsf": vsf,
      isLocal: true,
    });
    if (count === 1) return;
    else throw new Error(`Failed to save last revision vsf for ${drawingId}`);
  }

  async getLastSaveOrDraft(
    drawingId: number
  ): Promise<{ binary: ArrayBuffer; format: OdaBinaryFormat } | undefined> {
    const drawing = await this.getByIdOrThrow(drawingId);
    if (drawing.lastRevision.draft) {
      return {
        binary: drawing.lastRevision.draft,
        format: "VSFX",
      };
    } else if (drawing.lastRevision.vsf) {
      return {
        binary: drawing.lastRevision.vsf,
        format: drawing.lastRevision.format,
      };
    }
    return undefined;
  }

  async saveDraft(drawingId: number, draft: ArrayBuffer) {
    return db.drawings.update(drawingId, {
      "lastRevision.draft": draft,
      "lastRevision.editedAt": new Date(),
    });
  }

  dropDraft(drawingId: number) {
    return db.drawings.update(drawingId, {
      "lastRevision.draft": undefined,
    });
  }

  deleteById(id: number) {
    return db.drawings.delete(id);
  }

  /**
   *
   * @param drawingid drawing id
   * @returns Original file id (the dwg that the drawing was converted from) on OCS
   */
  async getOriginalFileId(drawingid: number): Promise<string | undefined> {
    const drawing = await this.getById(drawingid);
    return drawing?.initialOpenCloudFileId;
  }
}

export const drawingRepository = new DrawingRepository();
