<script setup lang="ts">
import { onMounted } from "vue";
import { useToast } from "../composable/useToast";
import { Facade } from "@/open-cloud/Facade";
import { ViewerEvents } from "@/open-cloud/DRViewer";
import UserState from "@/stores/UserState";
import { Logger } from "@/logger";
const { showWarning } = useToast();

//const MAX_RENDER_TIME = 100;
onMounted(() => {
  Facade.on(ViewerEvents.OpenDrawing, () => {
    /*Facade.monitorRender(MAX_RENDER_TIME)*/
  });
  Facade.on(ViewerEvents.RenderLatency, () => warn());
});

function warn() {
  Logger.info(
    `Toaster.vue : warning user about render latency ${UserState.hasSeenRenderAlert}`
  );
  if (!UserState.hasSeenRenderAlert) {
    showWarning(
      "Pour améliorer la navigation, pensez à masquer les calques avec de nombreux éléments. Réaffichez-les une fois que vous avez zoomé."
    );
    UserState.hasSeenRenderAlert = true;
  }
}
</script>
<template>
  <div class="d-none" />
</template>
@/stores/UserState
