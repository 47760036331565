import type { DRViewer } from "@/open-cloud/DRViewer";
import { DraggerEvents } from "@/open-cloud/draggers/draggers.type";
import { debounce } from "lodash";
import Grid from "./Grid";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import { HistoryEvents } from "../../commands/History";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";
import { EntityBuilder } from "../../builders/EntityBuilder";
import DraggerState from "../DraggersState";
import { Logger } from "@/logger";
import type { pointArray } from "../../types/oda.types";
import { DrawSelectionGridEvent, type HandleName } from "./transform.type";
import { ModelBuilder } from "@/open-cloud/builders/ModelBuilder";

/**
 * We call _Grid_ the grid around a selected entity along with its _Handles_ (colored circles user can grad to rotate/scale/translate).
 * While the user is rotating or resizing an entity, we display what would be the result, we called that temporary entity the _Ghost_ entity.
 */
export class Transformer {
  grids: Grid[] = [];
  // the handle the user is currently dragging
  activeHandle?: HandleName;
  baseMatrix: VisualizeJS.Matrix3d | null = null;
  // temporary entity that shows the user how will look the target entity when the transformation ends.
  // Is deleted when dragging ends
  ghostEntity: VisualizeJS.OdTvEntityId | null = null;

  pointerDownEvent: PointerEvent | null = null;

  private canvas: HTMLElement;

  handlersToRemove: {
    target: HTMLElement;
    type: string;
    handler: (...args: any[]) => unknown;
  }[] = [];

  constructor(
    canvas: HTMLElement,
    private readonly viewer: DRViewer,
    private readonly toolbox: Toolbox
  ) {
    this.canvas = canvas;
    viewer.addEventListener(DraggerEvents.AutoPanZoomEnded, () => {
      Logger.info(`transformer.ts : auto pan event`);
      this.handleAutoPanEnded();
    });

    viewer.addEventListener(DraggerEvents.AutoPanZoomStart, () => {
      Logger.info(`transformer.ts : auto pan event`);
      this.updateFloatingMenu([]);
    });

    viewer.addEventListener("zoomat", () => {
      Logger.info(`transformer.ts : zoom at event`);
      this.cleanGrids();
      this.debouncedRedrawGrid();
    });

    viewer.addEventListener(DraggerEvents.UnselectAll, () => {
      this.cleanGrids();
    });

    viewer.addEventListener(DraggerEvents.SetSelectionProperty, () => {
      this.cleanGrids();
      this.drawGridIOnSelection();
    });

    viewer.addEventListener(DraggerEvents.SelectionDeleted, () => {
      this.cleanGrids();
    });

    viewer.addEventListener(DraggerEvents.SelectionEnded, () => {
      Logger.info(`transformer.ts : selection end event`);
      this.drawGridIOnSelection();
    });

    viewer.addEventListener(HistoryEvents.UndoEnded, () => {
      this.cleanGrids();
    });

    viewer.addEventListener(HistoryEvents.RedoEnded, () => {
      this.cleanGrids();
    });

    this.startListeningForTransform();
  }
  get visLib(): typeof VisualizeJS {
    // @ts-ignore
    return this.viewer.visLib();
  }
  get visViewer(): VisualizeJS.Viewer {
    // @ts-ignore
    return this.viewer.visViewer();
  }

  get mupModel(): VisualizeJS.TvModel {
    const viewer = this.visViewer;
    return viewer.getMarkupModel();
  }

  dispose() {
    this.handlersToRemove.forEach(({ target, type, handler }) => {
      target.removeEventListener(type, handler);
    });
    this.cleanGrids();
    this.activeHandle = undefined;
    this.ghostEntity?.delete();
    this.ghostEntity = null;
    this.pointerDownEvent = null;
    this.baseMatrix = null;
  }

  startListeningForTransform() {
    Logger.info("transformer.ts : start listening for transform");
    this.canvas.addEventListener(
      "pointerdown",
      (event: PointerEvent) => {
        // if we have a resize grid diplayed, we need to check if the click is on one of the dragging buttons/circles
        this.handleTransformStart(event);
      },
      {
        passive: false,
      }
    );

    this.canvas.addEventListener(
      "pointermove",
      (event: PointerEvent) => {
        if (
          this.pointerDownEvent &&
          event.pointerId === this.pointerDownEvent.pointerId
        ) {
          try {
            this.handleTransformOngoing(event);
          } catch (e) {
            Logger.error(
              `transformer.handleTransformOngoing : e : ${JSON.stringify(
                e
              )}, grids: ${JSON.stringify(
                this.grids.map((grid) => grid.handles)
              )}, activeHandle: ${JSON.stringify(this.activeHandle)}`
            );
          }
          try {
            this.handleTransformOngoing(event);
          } catch (e) {
            if (e instanceof TypeError) {
              Logger.error(
                `transormer.handleTransformOngoing : ${
                  e.message
                }, grids: ${JSON.stringify(
                  this.grids.map((grid) => grid.handles)
                )}, activeHandle: ${JSON.stringify(this.activeHandle)}`
              );
            }
            throw e;
          }
        }
      },
      {
        passive: false,
      }
    );

    this.canvas.addEventListener("pointerup", (event: PointerEvent) => {
      if (
        this.pointerDownEvent &&
        event.pointerId === this.pointerDownEvent.pointerId
      ) {
        this.handleTransformationEnds();
        this.pointerDownEvent = null;
      }
    });
  }

  handleTransformStart(event: PointerEvent) {
    // if nothing is selected, we can safely ignore
    if (!this.grids.length) return;

    // if it's a touch event, we want only one finger, not supporting funky gestures for now
    // if we have a resize grid displayed, we need to check if the click is on one of the dragging buttons/circles
    const coordRel = Toolbox.eventToWorldCoord(event);
    const point = Toolbox.point2Coord(
      this.visViewer.screenToWorld(coordRel.x, coordRel.y)
    );

    // is the tap/click on a handle ?
    const matchHandle = this.matchHandle(point.x, point.y);
    if (!matchHandle) return;
    // one of the active handle was selected, then deactivate other tools
    DraggerState.isTransformActive = true;
    this.pointerDownEvent = event;
    this.activeHandle = matchHandle.handleName;
    this.baseMatrix = EntityBuilder.getModelingMatrix(
      this.grids[0].targetEntityIds[0]
    );
    Logger.info(
      `transformer.handleTransformStart : activehandle : ${this.activeHandle}`
    );
  }

  handleTransformOngoing(event: PointerEvent) {
    if (!this.activeHandle)
      throw new Error(
        "transformer.handleTransformationOngoing : no active handle"
      );
    const eventRelCoord = Toolbox.eventToWorldCoord(event);
    const eventWorldPoint = this.visViewer.screenToWorld(
      eventRelCoord.x,
      eventRelCoord.y
    );
    // calculate transform matrix

    let transformMatrix: VisualizeJS.Matrix3d;

    if (this.activeHandle === "rotation") {
      transformMatrix = this.handleRotation(eventWorldPoint);
    } else if (this.activeHandle === "center") {
      transformMatrix = this.handleTranslation(eventWorldPoint);
    } else if (this.activeHandle === "scale") {
      transformMatrix = this.handleConstraintResize(
        eventWorldPoint,
        "scale",
        "topLeft"
      );
    } else {
      const scaleParameters = Grid.matchScaleHandler(this.activeHandle);
      if (!scaleParameters) return;
      try {
        transformMatrix = this.handleFreeResize(
          eventWorldPoint,
          this.activeHandle,
          scaleParameters.refHandle,
          scaleParameters.axles
        );
      } catch (e) {
        if (e instanceof TypeError) {
          Logger.error(
            `transformer.handleFreeResize : error ${
              e.name + " : " + e.message + " , " + e.stack
            }`
          );
          Logger.error(
            `transformer.handleFreeResize : handle ${
              this.activeHandle
            }, point ${eventWorldPoint}, scales ${JSON.stringify(
              scaleParameters
            )}`
          );
        }
        throw e;
      }
    }

    const targetEntityId = this.grids[0].targetEntityIds[0];

    // This is a pure translation matrix centered in the entity.
    // We keep it on the side for the redo/undo stack
    // this.grids[0].data = this.grids[0].data || {};
    this.grids[0].data.transformMatrix?.delete();
    this.grids[0].data.transformMatrix = transformMatrix;
    this.ghostEntity = this.safeInitGhostVisual(targetEntityId);
    if (this.baseMatrix) {
      EntityBuilder.appendTransformWithBase(
        this.ghostEntity,
        transformMatrix,
        this.baseMatrix
      );
    }
    this.viewer.update();
  }

  safeInitGhostVisual(
    targetEntityId: VisualizeJS.OdTvEntityId
  ): VisualizeJS.OdTvEntityId {
    // if it's the first time we call, we create the Ghost entity in the markup model (not saved)
    if (!this.ghostEntity) {
      this.ghostEntity = ModelBuilder.cloneEntity(
        targetEntityId,
        this.mupModel
      );
    }
    // we hide the original entity, as we're going now to display the ghost entity
    this.toolbox.setVisible(targetEntityId, false);
    // @todo this is shortcut to not to have to redraw the Grid during the dragging, can be implemented in the future
    this.setGridVisible(false);
    return this.ghostEntity;
  }

  /**
   * Problem: `Entity.rotate` sets the rotation, starting from 0.
   * Meaning if we call it twice with rotate(x) and then rotate(y), the entity will be rotated y and not x+y.
   * To solve this, we use matrixes which allow more flexibility. From then we set on using matrices for all cases to keep it consistent.
   */
  handleRotation(eventWorldPoint: pointArray): VisualizeJS.Matrix3d {
    if (!this.grids[0].points)
      throw new TypeError("handleRotation : points is null");
    const centerPoint = this.grids[0].points.center;
    const rotationHandlePoint = this.grids[0].points.rotation;
    // calc the angle the user is creating while dragging
    let angle =
      Math.atan2(
        rotationHandlePoint[1] - centerPoint[1],
        rotationHandlePoint[0] - centerPoint[0]
      ) -
      Math.atan2(
        eventWorldPoint[1] - centerPoint[1],
        eventWorldPoint[0] - centerPoint[0]
      );
    angle = angle > 0 ? angle : angle + 2 * Math.PI;
    angle = -angle;

    return OdaGeometryUtils.getRotationMatrix(centerPoint, angle);
  }

  handleTranslation(eventWorldPoint: pointArray) {
    if (!this.grids[0].points?.center)
      throw new TypeError("handleTranslation : points is undefined");
    const centerPoint = this.grids[0].points.center;

    const vector: VisualizeJS.Vector3 = [
      eventWorldPoint[0] - centerPoint[0],
      eventWorldPoint[1] - centerPoint[1],
      0,
    ];

    return OdaGeometryUtils.getTranslationMatrix(vector);
  }

  /**
   *
   * @param eventWorldPoint
   * @param handle
   * @param originalRefHandle the handle that stays "anchored" at its location during the scaling operation
   * @param axles to scale along only one axis
   */
  handleFreeResize(
    eventWorldPoint: pointArray,
    handle: HandleName,
    originalRefHandle: HandleName = "center",
    axles: { x?: boolean; y?: boolean } = { x: true, y: true }
  ) {
    if (!this.grids[0].points)
      throw new TypeError("handleFreeResize : points is undefined");

    const handlePoint = this.grids[0].points[handle];
    const originalPoint = this.grids[0].points[originalRefHandle];

    if (!this.grids[0].data.xOriginalDistance) {
      this.grids[0].data.xOriginalDistance = handlePoint[0] - originalPoint[0];
    }
    if (!this.grids[0].data.yOriginalDistance) {
      this.grids[0].data.yOriginalDistance = handlePoint[1] - originalPoint[1];
    }

    const xDraggingDistance = eventWorldPoint[0] - originalPoint[0];
    const xRatio = xDraggingDistance / this.grids[0].data.xOriginalDistance;

    const yDraggingDistance = eventWorldPoint[1] - originalPoint[1];
    const yRatio = yDraggingDistance / this.grids[0].data.yOriginalDistance;

    const ratioVector: VisualizeJS.Vector3 = [1, 1, 1];
    if (axles.x) ratioVector[0] = xRatio;
    if (axles.y) ratioVector[1] = yRatio;

    // Scaling makes the entity move, as a result of all its coordinate being scaled.
    // So we need to compensate with a translation to make it scale while being fixed on its center
    const shiftVector: VisualizeJS.Vector3 = [0, 0, 0];
    if (axles.x) shiftVector[0] = originalPoint[0] * (1 - xRatio);
    if (axles.y) shiftVector[1] = originalPoint[1] * (1 - yRatio);

    return OdaGeometryUtils.getScaleMatrix(ratioVector).preMultBy(
      OdaGeometryUtils.getTranslationMatrix(shiftVector)
    );
  }

  /**
   *
   * @param eventWorldPoint
   * @param handle
   * @param originalRefHandle the handle that stays "anchored" at its location during the scaling operation
   */
  handleConstraintResize(
    eventWorldPoint: pointArray,
    handle: HandleName,
    originalRefHandle: HandleName = "center"
  ) {
    if (!this.grids[0].points)
      throw new TypeError("handleConstraintResize : points is undefined");
    const handlePoint = this.grids[0].points[handle];
    const originalPoint = this.grids[0].points[originalRefHandle];

    if (!this.grids[0].data.originalDistanceToCenter) {
      this.grids[0].data.originalDistanceToCenter = Toolbox.computeDistance2D(
        originalPoint,
        handlePoint
      );
    }

    const draggingDistanceToCenter = Toolbox.computeDistance2D(
      originalPoint,
      eventWorldPoint
    );

    const ratio =
      draggingDistanceToCenter / this.grids[0].data.originalDistanceToCenter;

    const shiftVector: VisualizeJS.Vector3 = [
      originalPoint[0] * (1 - ratio),
      originalPoint[1] * (1 - ratio),
      0,
    ];

    // Scaling makes the entity move, as a result of all its coordinate being scaled.
    // So we need to compensate with a translation to make it scale while being fixed on its center
    return OdaGeometryUtils.getScaleMatrix([ratio, ratio, 1]).preMultBy(
      OdaGeometryUtils.getTranslationMatrix(shiftVector)
    );
  }

  handleTransformationEnds() {
    if (this.activeHandle) {
      Logger.info(
        `tranformer.handleTransformationEnds : done transforming, ${this.activeHandle}`
      );
      if (this.ghostEntity) {
        // if a corner has been click but there is still no tempEntity,
        // it's probably that the user clicked on the button but didn't drag (simple click)
        const ghostMatrix = EntityBuilder.getModelingMatrix(this.ghostEntity);
        EntityBuilder.setModelingMatrix(
          this.grids[0].targetEntityIds[0],
          ghostMatrix
        );
        ghostMatrix.delete();
        // for undo/redo
        if (this.grids[0].data?.transformMatrix) {
          const vectors = OdaGeometryUtils.getMatrixCoordVectors(
            this.grids[0].data?.transformMatrix
          );
          const params = {
            handle: EntityBuilder.getHandle(this.grids[0].targetEntityIds[0]),
            vectors: vectors,
          };
          this.viewer.commandFactory.transformEntities.execute([params]);
        } else {
          Logger.warn(
            `transformation ended but no transformation matrix found`
          );
        }
        const modelmup = this.mupModel;
        modelmup.removeEntity(this.ghostEntity);

        this.baseMatrix?.delete();
        this.baseMatrix = null;

        this.ghostEntity.delete();
        modelmup.delete();
        this.toolbox.setVisible(this.grids[0].targetEntityIds[0], true);
      }
      this.cleanGrids();
      this.activeHandle = undefined;
      this.ghostEntity = null;
      DraggerState.isTransformActive = false;
      this.drawGridIOnSelection();
    }
  }

  cleanGrids() {
    this.grids.forEach((grid) => {
      grid.clean();
    });
    this.grids = [];
    this.updateFloatingMenu([]);
  }

  // Hide and show all grids and the floating menu
  setGridVisible(visible: boolean) {
    this.grids.forEach((grid) => {
      grid.setVisible(visible);
      if (visible) {
        const handles = grid.targetEntityIds.map((entity) =>
          EntityBuilder.getHandle(entity)
        );
        this.updateFloatingMenu(handles);
      } else {
        this.updateFloatingMenu([]);
      }
    });
  }

  handleAutoPanEnded() {
    this.cleanGrids();
    this.drawGridWithAnimationFrame();
  }

  /**
   * check if a given point matches a handle
   * @param x
   * @param y
   */
  matchHandle(
    x: number,
    y: number
  ): { grid: Grid; handleName: HandleName } | undefined {
    for (const grid of this.grids) {
      const handleName = grid.matchHandle(x, y);
      if (handleName) {
        return {
          ...handleName,
          grid,
        };
      }
    }
  }

  /**
   * Draw grid perimeter and grid handles
   */

  drawGridIOnSelection() {
    this.cleanGrids(); // avoids having many grid on top of each other
    if (this.viewer.selectionSet && this.viewer.selectionSet?.numItems() > 0) {
      const itr = this.viewer.selectionSet.getIterator();
      const entityId = itr.getEntity();
      const grid = new Grid([entityId], this.viewer);
      this.grids.push(grid);
      grid.draw();
      Logger.info(`transformer.drawGridIOnSelection`);
      this.updateFloatingMenu([EntityBuilder.getHandle(entityId)]);
      itr.delete();
    } else {
      Logger.warn(`transformer.ts : no entity in selectionset`);
    }
  }

  debouncedRedrawGrid = debounce(this.drawGridWithAnimationFrame, 100);

  /**
   * Use to re-draw the Grid after a zoom
   */
  drawGridWithAnimationFrame() {
    this.drawGridIOnSelection();
    this.viewer.update();
  }

  /**
   * Send event to update front menu
   * Sending [] hide it
   * @param targets handles of selected entities
   */
  updateFloatingMenu(handles: string[]) {
    document.dispatchEvent(
      new DrawSelectionGridEvent({
        targetHandles: handles,
      })
    );
  }
}
