<script setup lang="ts">
import DraggerMenuState from "@/stores/DraggerMenuState";
import NoteMenuState from "@/stores/NoteMenuState";
import TextInput from "./TextInput.vue";
import { ref } from "vue";
import type { ExposedHtmlInput } from "./text.type";

const NUMBERS = [
  "0.1",
  "0.2",
  "0.3",
  "0.4",
  "0.5",
  "0.6",
  "0.7",
  "0.8",
  "0.9",
  "1",
  "1.1",
  "1.2",
];

const TextInputHTML = ref<ExposedHtmlInput | null>(null);
const modal = ref<HTMLDivElement | null>(null);
function deleteChars(numChar = 1) {
  DraggerMenuState.currentTextInput =
    DraggerMenuState.currentTextInput.substring(
      0,
      DraggerMenuState.currentTextInput.length - numChar
    );
  setActiveText();
}

function blur() {
  TextInputHTML.value?.blur();
}

function addToInput(text: string) {
  DraggerMenuState.currentTextInput += text;
  setActiveText();
}

function setActiveText() {
  DraggerMenuState.setActiveText(DraggerMenuState.currentTextInput);
}
</script>
<template>
  <div
    ref="modal"
    class="modal"
    tabindex="-1"
    id="modalTextPanel"
    aria-labelledby="modalTextPanel"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="grid">
            <div class="g-col-4 m-2 sticky-top">
              <form class="ms-1" @submit.stop.prevent="blur()">
                <div class="input-group">
                  <TextInput
                    ref="TextInputHTML"
                    :prevent-focus-on-clear="true"
                  />
                </div>
              </form>
            </div>
            <div class="g-col-4 m-2">
              <button class="btn btn-secondary me-1" @click="addToInput(' ')">
                esp
              </button>
              <button class="btn btn-secondary me-1" @click="addToInput('+')">
                <i class="bi bi-plus" />
              </button>
              <button class="btn btn-secondary me-1" @click="addToInput('/')">
                /
              </button>
              <button class="btn btn-secondary" @click="deleteChars()">
                <i class="bi bi-backspace" />
              </button>
            </div>
            <div class="g-col-4 m-2">
              <ul class="list-group list-group-horizontal flex-wrap">
                <li
                  v-for="config in NoteMenuState.noteConfigs"
                  :key="config.name"
                  class="list-group-item d-inline-flex justify-content-between align-items-center"
                  @click="addToInput(config.label || config.name)"
                >
                  {{ config.label || config.name }}
                </li>
              </ul>
            </div>
            <div class="g-col-4 m-2">
              <ul class="list-group list-group-horizontal flex-wrap">
                <li
                  v-for="anno in NUMBERS"
                  :key="anno"
                  class="list-group-item d-inline-flex justify-content-between align-items-center"
                  @click="addToInput(anno)"
                >
                  {{ anno }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
