//import type BlockViewer from "@/open-cloud/BlockViewer";
import type { DRViewer } from "@/open-cloud/DRViewer";

export class DeviceBuilder {
  viewer: DRViewer /*| BlockViewer*/;

  constructor(viewer: DRViewer /*| BlockViewer*/) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  get palette(): number[] {
    const device = this.visViewer.getActiveDevice();
    const palette = device.getLogicalPalette();
    device.delete();
    return palette;
  }

  /**
   * Swap color and override color in palette.
   * Note : don't remove a color from palette, just swap 2 colors.
   * inspired from this : https://codepen.io/opendesign/pen/MWeXvme
   * white <=> black
   * @param color
   */

  overrideDevicePalette(
    color: [number, number, number, number],
    overrideColor: [number, number, number, number]
  ) {
    const viewer = this.visViewer;
    const activeDevice = viewer.getActiveDevice();
    const palette = this.palette;
    const indexes = this.findColorInPalette(color, palette);
    const inverts = this.findColorInPalette(overrideColor, palette);
    if (indexes.length) {
      for (const index of indexes) {
        palette[index] = this.toNumColor(overrideColor);
      }
      for (const index of inverts) {
        palette[index] = this.toNumColor(color);
      }
      activeDevice.setLogicalPalette(palette);
    }
    activeDevice.delete();
  }

  findColorInPalette(
    color: [number, number, number, number],
    palette: number[]
  ): number[] {
    const str = color.toString();
    const indexes: number[] = [];
    for (let i = 0; i < palette.length; i++) {
      if (str === this.toRGBColor(palette[i]).toString()) {
        indexes.push(i);
      }
    }
    return indexes;
  }

  /**
   * converts palette decimal color to rgba
   * Be careful the order is bbggrraa in palette.
   * @param num
   * @returns
   */

  toRGBColor(num: number): [number, number, number, number] {
    num >>>= 0;
    const r = num & 0xff,
      g = (num & 0xff00) >>> 8,
      b = (num & 0xff0000) >>> 16,
      a = ((num & 0xff000000) >>> 24) / 255;
    return [r, g, b, a];
  }

  toNumColor(color: [number, number, number, number]): number {
    return (
      (color[0] << 16) + (color[1] << 8) + color[2] + ((color[3] * 255) << 24)
    );
  }

  createView(name: string, saveInFile: boolean): VisualizeJS.View {
    const device = this.visViewer.getActiveDevice();
    const view = device.createView(name, saveInFile);
    device.delete();
    return view;
  }

  addView(view: VisualizeJS.View) {
    const device = this.visViewer.getActiveDevice();
    device.addView(view);
    device.delete();
  }

  eraseView(view: VisualizeJS.View) {
    const device = this.visViewer.getActiveDevice();
    device.eraseView(view);
    view.delete();
    device.delete();
  }

  /**
   * Sets a new color if color param is not the current one
   * Override device palette to change
   * @param color
   * @param overrideColor
   */
  setBackgroundColor(
    color: [number, number, number, number],
    overrideColor?: [number, number, number, number]
  ) {
    const bgColor: [number, number, number, number] =
      this.visViewer.getBackgroundColor();
    if (color.toString() !== bgColor.toString()) {
      this.visViewer.setBackgroundColor(color);
      if (overrideColor) {
        this.overrideDevicePalette(color, overrideColor);
      }
    }
  }
}
