import { defineStore } from "pinia";
import type { File as OdaFile } from "@inweb/client";
import type { DRDrawing, GeometryStatus } from "@/repositories/domain.types";
import { computed, ref } from "vue";
import { liveQuery } from "dexie";
import { v4 as uuidV4 } from "uuid";
import { remove } from "lodash";
import { syncer } from "@/repositories/Syncer";
import { drawingRepository } from "@/repositories/drawing.repository";
import { DrawingDecoder } from "@/open-cloud/drawing.decoder";
import { Monitor } from "@/utils/monitor";
import { useDeviceState } from "@/stores/DeviceState";
import {
  removeAccents,
  removeSpecialCharsForOcsFiles,
} from "@/utils/misc.utils";
import { Logger } from "@/logger";

type TrackerStatus = null | GeometryStatus;

export type ConversionStatusHook = (file: OdaFile) => void;
export type UploadProgressHook = (progress: number) => void;

export type UploadTracker = {
  id: string;
  fileName: string;
  fileSize: number;
  progress: number;
  progressDisplay: number;
  conversionStatus: TrackerStatus;
};

export type FileStoreDrawingFile = Omit<DRDrawing, "lastRevision"> & {
  isLocal: boolean;
  hasDraft: boolean;
  isSynced: boolean;
  isSelected: boolean;
  lastRevisionEditedAt?: Date;
  syncStatus?: string;
};

function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export const useDrawingStore = defineStore("fileStore", () => {
  const drawings = ref<FileStoreDrawingFile[]>([]);
  const syncMonitor = new Monitor(syncDrawingStat, { intervalInMs: 5000 });
  const areAllSynced = computed(() => drawings.value.every((e) => e.isSynced));

  const deviceState = useDeviceState();
  const uploadTrackers = ref<UploadTracker[]>([]);

  const drawingsObservable = liveQuery<DRDrawing[]>(() =>
    drawingRepository.fetchAllByNewest()
  );
  drawingsObservable.subscribe({
    next: (result) => {
      drawings.value = result.map((e) => {
        const drawing = drawings.value.find((drawing) => drawing.id === e.id);
        const options = {
          isSelected: drawing?.isSelected,
        };
        return DrawingDecoder.fromDomainToFileStore(e, options);
      });
    },
  });

  const setTrackerProgress = (trackerId: string, progress: number) => {
    const tracker = uploadTrackers.value.find((f) => f.id === trackerId);
    if (!tracker) return;

    if (tracker.progress >= 1) return; // means we have already been there

    // update the tracker, for display and tracking if we already finished it once
    tracker.progress = progress;
    tracker.progressDisplay = Math.round(progress * 100);

    // it's the first time that we process upload completed
    if (progress >= 1) {
      tracker.conversionStatus = "inprogress";
    }
  };

  const setTrackerStatus = (trackerId: string, file: OdaFile) => {
    const tracker = uploadTrackers.value.find((f) => f.id === trackerId);
    if (!tracker) return;
    tracker.conversionStatus = calcGlobalFileStatus(file);
    // We remove the "tracker" in 3 seconds (from the list)
    if (tracker.conversionStatus === "done") {
      setTimeout(() => {
        removeUploadTracker(trackerId);
      }, 5000);
    }
  };

  const uploadFiles = async (files: FileList) => {
    const renamedFiles = Array.from(files).map((file) => {
      const splitted = file.name.split(".");
      const extension = splitted.pop();
      const normalized = removeSpecialCharsForOcsFiles(
        removeAccents(splitted.join("."))
      );

      return renameFile(file, `${normalized}.${extension}`);
    });

    const newTrackers = Array.from(renamedFiles).map((file) => ({
      id: uuidV4(),
      fileName: file.name,
      fileSize: file.size,
      progress: 0,
      progressDisplay: 0,
      conversionStatus: null,
    }));
    uploadTrackers.value = uploadTrackers.value.concat(newTrackers);

    // when onProgress is called by the oda framework, we can't know which tracker it matches.
    // So we create one callback per tracker
    const progressHooks: {
      onProgress: UploadProgressHook;
      setConversionStatus: ConversionStatusHook;
    }[] = newTrackers.map((tracker) => {
      return {
        onProgress: (progress: number) =>
          setTrackerProgress(tracker.id, progress),
        setConversionStatus: (file: OdaFile) =>
          setTrackerStatus(tracker.id, file),
      };
    });

    await syncer.uploadManyDwgAncCreateDrawings(renamedFiles, progressHooks);
    return;
  };

  const removeUploadTracker = (id: string) => {
    remove(uploadTrackers.value, { id });
  };

  const calcGlobalFileStatus = (
    file: OdaFile
  ): Exclude<TrackerStatus, "none"> => {
    const geometryStatus = file.status.geometry.state;
    const propertiesStatus = file.status.properties.state;
    if (geometryStatus === "done" && propertiesStatus === "done") return "done";
    // if (geometryStatus === "none" && propertiesStatus === "none") return "failed";

    const asArray: GeometryStatus[] = [geometryStatus, propertiesStatus];
    // if at least one is failed => failed
    if (asArray.includes("failed")) return "failed";
    // if one is none (job not wanted), we take the status of the other one
    if (asArray.includes("none")) {
      return asArray.find((e) => e !== "none") || "failed";
    }
    // if one is none (job not wanted), we take the status of the other one
    if (asArray.includes("waiting") || asArray.includes("inprogress")) {
      return "inprogress";
    }

    throw new Error(
      `unexpected file status '${geometryStatus}, ${propertiesStatus}' for file ${file.id}`
    );
  };

  async function syncDrawingStat() {
    if (!deviceState.isOnline) return;
    try {
      const start = performance.now();
      Logger.info(`filestore.syncDrawingStat : autosync`);
      await syncer.autoSync();
      const end = performance.now();
      Logger.info(`filestore.syncDrawingStat : syncing took ${end - start} ms`);
    } catch (e) {
      Logger.error(`fileStore.syncDrawingStat() : error ${JSON.stringify(e)}`);
    }
  }

  function startAutoSync() {
    syncMonitor.start();
  }
  function stopAutoSync() {
    syncMonitor.stop();
  }

  function clearDrawings() {
    drawings.value = [];
    uploadTrackers.value = [];
  }

  async function deleteWaitingJobs() {
    await syncer.deleteWaitingJobs();
  }

  async function archiveDrawing(drawingId: number) {
    const drawing = drawings.value.find((drawing) => drawing.id === drawingId);
    if (drawing) {
      if (!drawing.isSynced) {
        Logger.info(
          `fileStore.ts : can't archive a drawing that is not synced ${drawing.id}`
        );
        throw new Error("DRAWING NOT SYNC");
      } else {
        await syncer.archiveDrawingAndRefreshList(drawing.id);
        drawing.isSelected = false;
      }
    } else {
      Logger.warn(
        `fileStore.ts : can't archive drawing ${drawingId}, drawing is not in the list`
      );
      throw new Error("DRAWING NOT FOUND");
    }
  }

  function toggleDrawing(drawingId: number) {
    const drawing = drawings.value.find((drawing) => drawing.id === drawingId);
    if (drawing) {
      drawing.isSelected = !drawing.isSelected;
    }
  }

  return {
    drawings,
    uploadTrackers,
    uploadFiles,
    stopAutoSync,
    startAutoSync,
    areAllSynced,
    clearDrawings,
    getArchivedDrawings: async () => {
      const drawings = await syncer.fetchArchivedDrawings();
      return drawings.map((d) => DrawingDecoder.fromDomainToFileStore(d));
    },
    deleteWaitingJobs,
    archiveDrawing,
    toggleDrawing,
  };
});
