import { TextBuilder, type TextData } from "@/open-cloud/builders/TextBuilder";
import OneWayEntityDragger from "./OneWayEntityDragger";
import { GeometryBuilder } from "@/open-cloud/builders/GeometryBuilder";

export default class EllipseDragger extends OneWayEntityDragger {
  _updateFrame(): void {
    if (this.startCornerWCS.length === 3 && this.endCornerWCS.length === 3) {
      this.refreshShadowEntity();
      if (this.shadowId) {
        this.drawEllipse(
          this.shadowId,
          this.startCornerWCS,
          this.endCornerWCS,
          this.viewer.activeNoteConfig.label
        );

        //this.drawHatches(this.shadowId, this.startCornerWCS, this.endCornerWCS);

        this.drawHVMeasurements();
        this._setNoteConfigProperties(this.shadowId);
      }
    }
  }

  drawEllipse(
    entityId: VisualizeJS.OdTvEntityId,
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number],
    label?: string
  ) {
    const corner1: [number, number, number] = [
      startCornerWCS[0],
      endCornerWCS[1],
      0,
    ];
    const corner2: [number, number, number] = [
      endCornerWCS[0],
      startCornerWCS[1],
      0,
    ];
    const center = this.viewer.toolbox.computeCenter(
      startCornerWCS,
      endCornerWCS
    );
    const major = this.viewer.toolbox.computeCenter(endCornerWCS, corner1);

    const minor = this.viewer.toolbox.computeCenter(endCornerWCS, corner2);
    const geomId = GeometryBuilder.addEllipse(entityId, {
      center: center,
      major: major,
      minor: minor,
    });
    if (label && this.viewer.label) {
      const data: TextData = {
        refpoint: center,
        message: label,
        alignmentmode: 10,
      };
      TextBuilder.addText(entityId, data);
    }
    geomId.delete();
  }

  drawHatches(
    entityId: VisualizeJS.OdTvEntityId,
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number]
  ) {
    const lib: typeof VisualizeJS = this.viewer.visLib();
    const viewer: VisualizeJS.Viewer = this.viewer.visViewer();
    const entity = entityId.openObject();
    const pointsAs2DArray = this.getPerimeterPoints(
      this.getCenter(startCornerWCS, endCornerWCS),
      this.getMajor(startCornerWCS, endCornerWCS),
      this.getMinor(startCornerWCS, endCornerWCS),
      36
    );

    const points: number[] = [];
    const faces: number[] = [pointsAs2DArray.length];

    for (let i = 0; i < pointsAs2DArray.length; i++) {
      faces.push(i);
      points.push(...pointsAs2DArray[i]);
    }

    const geomId = entity.appendShell(points, faces);
    const shell = geomId.openAsShell();

    // Create pattern lines
    const line0 = new lib.OdTvHatchPatternLineDef();
    line0.setLineAngle(-0.7853); // approximately -45 degrees
    line0.setPatternOffset([0.5, 0]); // each next line will be offset by 0.5 units on X-axis
    const patternLines = new lib.OdTvHatchPatternLines();
    patternLines.push_back(line0);

    // Create hatch pattern
    const hatchPattern = new lib.OdTvHatchPatternDef();
    hatchPattern.setPatternLines(patternLines);
    hatchPattern.setPatternTransparency(0);
    //hatchPattern.setPatternColor(255, 0, 0);
    shell.setHatchPattern(hatchPattern); // Set hatch pattern before setFaceFillOrigins(..) and setFaceFillDirections(..) call
    const hatchPattern1 = shell.getHatchPattern();

    // Create fill origins and set to shell. Do this after hatch pattern has been set to shell.
    const fillOrigins = new lib.OdVectorPoint2d(); // Points number must be equal to face size * pattern lines count.
    fillOrigins.push_back([1.0, 0.0]); // 0 point
    fillOrigins.push_back([1.0, 0.0]); // 1 point

    const fillOriginsRes = shell.setFaceFillOrigins(fillOrigins);
    const fillOrigins1 = shell.getFaceFillOrigins();

    // Create fill directions and set to shell. Do this after hatch pattern has been set to shell.
    const fillDirections = new lib.OdVectorVector2d(); // Vectors number must be equal to face size * pattern lines count.
    fillDirections.push_back([1.0, 0.0]); // 0 vector
    fillDirections.push_back([1.0, 0.0]); // 1 vector

    const fillDirectionsRes = shell.setFaceFillDirections(fillDirections);
    const fillDirections1 = shell.getFaceFillDirections();

    // Set face fill color
    //const colorDef = new lib.OdTvColorDef(125, 125, 125);
    //shell.setFaceColorsViaRange(1, 1, colorDef);
    //shell.setVertexColorsViaRange(0, 4, new lib.OdTvRGBColorDef(0, 255, 255));

    // Set transparency
    const transparencyDef = new lib.OdTvTransparencyDef();
    transparencyDef.setValue(1); //0-1
    shell.setFaceTransparencyViaRange(0, 1, transparencyDef);

    // Update visual style to make sure that you use a visual style that is able to render fill patterns.
    const device = viewer.getActiveDevice();
    const activeView = device.getActiveView();

    const visualStyleId = viewer.findVisualStyle("Gouraud");
    const visualStylePtr = visualStyleId.openObject();

    visualStylePtr.setOptionInt32(
      lib.VisualStyleOptions.kEdgeModel,
      lib.EdgeModel.kFacetEdges.value,
      lib.VisualStyleOperations.kSet
    ); // OdTvVisualStyleOptions::kFacetEdges
    visualStylePtr.setOptionInt32(
      lib.VisualStyleOptions.kDisplayStyles,
      lib.DisplayStyles.kBackgrounds.value,
      lib.VisualStyleOperations.kSet
    ); // only OdTvVisualStyleOptions::kBackgrounds
    //visualStylePtr.setOptionBool(lib.VisualStyleOptions.kUseDrawOrder, false, lib.VisualStyleOperations.kSet); // Disable draw order
    visualStylePtr.setOptionInt32(
      lib.VisualStyleOptions.kFaceLightingModel,
      lib.FaceLightingModel.kConstant.value,
      lib.VisualStyleOperations.kSet
    ); // only OdTvVisualStyleOptions::kBackgrounds

    activeView.visualStyle = visualStyleId; // Set visual style to active view
  }

  getPerimeterPoints(
    center: [number, number, number],
    R0: number,
    Rn: number,
    count: number
  ): [number, number, number][] {
    // number of points has to be greater than 1
    if (count <= 1) return [];

    // set value of thetas (radians) for the ellipse

    const thetas = [];
    for (let i = 0; i < count; i++) {
      thetas[i] = (i * 2 * Math.PI) / count;
    }

    // calculate polar radius of points depending on thetas
    const R: number[] = [];
    for (let i = 0; i < count; i++) {
      R[i] = Math.sqrt(
        (R0 ** 2 * Rn ** 2) /
          (Math.pow(Rn * Math.cos(thetas[i]), 2) +
            Math.pow(R0 * Math.sin(thetas[i]), 2))
      );
    }
    // go from polar to euclidian coords and translate to center
    const XYZ: [number, number, number][] = [];

    for (let i = 0; i < count; i++) {
      XYZ[i] = [
        center[0] + R[i] * Math.cos(thetas[i]),
        center[1] + R[i] * Math.sin(thetas[i]),
        center[2],
      ];
    }
    return XYZ;
  }

  getCenter(
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number]
  ): [number, number, number] {
    return [
      (startCornerWCS[0] + endCornerWCS[0]) / 2,
      (startCornerWCS[1] + endCornerWCS[1]) / 2,
      (startCornerWCS[2] + endCornerWCS[2]) / 2,
    ];
  }

  getMajor(
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number]
  ): number {
    return Math.abs(startCornerWCS[0] - endCornerWCS[0]) / 2;
  }

  getMinor(
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number]
  ): number {
    return Math.abs(startCornerWCS[1] - endCornerWCS[1]) / 2;
  }
}
