<script setup lang="ts">
import { onBeforeUnmount, watch } from "vue";
import { RouterView } from "vue-router";
import Navbar from "./components/Navbar.vue";
import ToastProvider from "./components/providers/ToastProvider.vue";
import UserState from "@/stores/UserState";
import "./utils/side-effect-imports";
import { odaRepository } from "@/repositories/oda.repository";
import Settings from "./stores/Settings";
import { Logger } from "./logger";
// Need to import Settings to get access to the routes
Settings.initialize();
// Init user before mounting the app in order to get access to the routes
UserState.initialize();
Logger.identify();
if (navigator.userAgentData) {
  Logger.info(
    `App.vue : mounting app for user agent : ${JSON.stringify(
      navigator.userAgentData
    )} ${navigator.hardwareConcurrency}`
  );
} else {
  Logger.info(
    `App.vue : mounting app for user agent : ${JSON.stringify(
      navigator.userAgent
    )} ${navigator.hardwareConcurrency}`
  );
}

// the following updates the syncer API token
// based on the value of UserState.open_cloud_api_token
const stopWatchingUserState = watch(
  () => UserState.open_cloud_api_token,
  (token) => {
    Logger.info(`watch user state token :"${token.substring(0, 8)}"`);
    odaRepository.init(token);
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  stopWatchingUserState();
});
</script>

<template>
  <Navbar></Navbar>
  <ToastProvider>
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <component :is="Component"></component>
      </template>
    </RouterView>
  </ToastProvider>
</template>

<style lang="scss">
@import "bootstrap/scss/bootstrap";
$bootstrap-icons-font-dir: "../node_modules/bootstrap-icons/font/fonts";
@import "bootstrap-icons/font/bootstrap-icons";

$font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;

// from https://github.com/playcanvas/editor/issues/160
@mixin disableTouchCallout() {
  // disable callout menu on webkit
  -webkit-touch-callout: none;
  // disable highlight effect on webkit
  -webkit-tap-highlight-color: transparent;
  // disable user selection
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

html,
body {
  font-family: $font-family;
  font-size: 14px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  touch-action: manipulation;

  @include disableTouchCallout;
}

canvas {
  @include disableTouchCallout;
}

// FIXME: 49px is the height of the navbar, and we need the app to take the remaining space on the screen
// I wanted to use flexbox for that, but it causes issues with the Canvas which dynamically computes its height
#app {
  height: calc(100% - 49px);
  height: -webkit-calc(100% - 49px);
  height: -moz-calc(100% - 49px);
}

.dr-button-popover {
  --bs-popover-max-width: 300px;
  --bs-popover-bg: #{$gray-700};
  --bs-popover-header-bg: var(--bs-popover-bg);
  --bs-popover-body-color: #{$gray-300};
  --bs-popover-header-color: #{$white};
  --bs-popover-header-font-size: #{$h5-font-size};

  .popover-header {
    // no css variable expose to customize those
    --bs-popover-border-width: 0;
    padding-top: 0.75rem;
    padding-bottom: 0;
  }
}

/**
  Vue transition https://vuejs.org/guide/built-ins/transition.html
  as per April 2023, used to fade away an uploadItem
 */
.fade-enter-active,
.fade-leave-active {
  transition: padding 0.5s ease, opacity 0.5s ease, max-height 0.5s ease,
    transform 0.5s ease;
}
.fade-enter-to {
  max-height: 60px;
}
.fade-leave-from {
  overflow: hidden;
  max-height: 60px;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0;
  padding: 0;
  transform: scaleX(0.95);
}

:root {
  /**
  Those were supposed to be included in our version of bootstrap (5 at this day) but got delayed to 6.
  Added manually in the meantime
   */
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;

  --bs-primary-border-subtle: #9ec5fe;
  --bs-success-border-subtle: #a3cfbb;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
}

.badge-primary-subtle {
  background-color: var(--bs-primary-bg-subtle);
  border: 2px var(--bs-primary-border-subtle) solid;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.badge-success-subtle {
  background-color: var(--bs-success-bg-subtle);
  border: 2px var(--bs-success-border-subtle) solid;
  color: rgba(var(--bs-success-rgb));
}

.badge-warning-subtle {
  background-color: var(--bs-warning-bg-subtle);
  border: 2px var(--bs-warning-border-subtle) solid;
  color: rgba(var(--bs-warning-rgb));
}

.badge-danger-subtle {
  background-color: var(--bs-danger-bg-subtle);
  border: 2px var(--bs-danger-border-subtle) solid;
  color: rgba(var(--bs-danger-rgb));
}
</style>
@/stores/UserState
