<script setup lang="ts">
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
import { watch, onMounted, ref, toRaw } from "vue";
import { Facade } from "@/open-cloud/Facade";
import {
  DraggerEvents,
  DraggerName,
} from "@/open-cloud/draggers/draggers.type";
import DraggerMenuState from "@/stores/DraggerMenuState";
import { ViewerEvents } from "@/open-cloud/DRViewer";
import { useToast } from "@/components/composable/useToast";
import { Logger } from "@/logger";

const { showSecondary } = useToast();

const TextInput = ref("");
const TextInputHTML = ref<HTMLInputElement | null>(null);
const { refHook } = useLongPressTooltip();

function setActiveDragger() {
  Logger.info(
    `PhotoMarkerInput.vue : activating dragger ${DraggerName.PhotoMarker} number is ${TextInput.value}`
  );
  DraggerMenuState.setActiveDragger({ name: DraggerName.PhotoMarker });
}

function setActivePhotoMarkerNumber() {
  Facade.setActivePhotoMarkerNumber(toRaw(TextInput.value.toString()));
}

function incrementBtnHandler(offset: number) {
  let current = parseInt(TextInput.value);
  if (current === 0 && offset < 0) {
    offset = 0;
  }
  TextInput.value = (current + offset).toString();
  setActivePhotoMarkerNumber();
}

function getLastComputedAndIncrement() {
  const lastComputed = Facade.getLastMarkerCount();
  if (lastComputed) {
    TextInput.value = `${lastComputed + 1}`;
  } else {
    TextInput.value = "1";
  }
  setActivePhotoMarkerNumber();
}

function blur() {
  TextInputHTML.value?.blur();
}

onMounted(() => {
  Facade.on(ViewerEvents.OpenDrawing, () => {
    getLastComputedAndIncrement();
  });

  Facade.on(DraggerEvents.PhotoMarkerAdded, () => {
    setTimeout(() => {
      incrementBtnHandler(1);
      setActivePhotoMarkerNumber();
    }, 200);
  });
});

function plus() {
  Logger.info(`PhotoMarkerInput.vue : plus photo number is ${TextInput.value}`);
  incrementBtnHandler(1);
}

function minus() {
  Logger.info(
    `PhotoMarkerInput.vue : minus photo number is ${TextInput.value}`
  );
  incrementBtnHandler(-1);
}

function focusin() {
  Logger.info(
    `PhotoMarkerInput.vue : focusin photo number is ${TextInput.value}`
  );
  setActiveDragger();
}

function toggleShape() {
  if (DraggerMenuState.activePhotoMarkerShape == "arrow") {
    DraggerMenuState.setPhotoMarkerShape("circle");
    showSecondary("Mode sans flèche");
  } else {
    DraggerMenuState.setPhotoMarkerShape("arrow");
    showSecondary("Mode avec flèche");
  }
  Logger.info(
    `PhotoMarkerInput.vue : done toggling marker shape ${DraggerMenuState.activePhotoMarkerShape}`
  );
}

watch(
  () => DraggerMenuState.activeDragger.name,
  (newName, oldName) => {
    if (newName == DraggerName.PhotoMarker && newName != oldName) {
      setActivePhotoMarkerNumber();
    }
  }
);
</script>
<template>
  <form @submit.prevent="blur()">
    <div
      ref="refHook"
      data-bs-content="Tapez le numéro du marqueur photo de votre choix et ajoutez le en faisant glisser le stylet sur le plan. L'élément dessiné prend les propriétés du désordres sélectionnés."
      data-bs-title="Marqueur Photo"
    >
      <button class="btn btn-secondary mb-1" @click="toggleShape()">
        <i
          v-if="DraggerMenuState.activePhotoMarkerShape == 'circle'"
          class="bi bi-1-circle"
        ></i>
        <i v-else class="bi bi-arrow-up-right"></i>
      </button>
      <div class="btn-group-vertical" style="width: 55px">
        <button type="button" class="btn btn-secondary" @click="minus()">
          <i class="bi bi-dash-lg"></i>
        </button>
        <input
          v-model="TextInput"
          ref="TextInputHTML"
          @input="setActiveText()"
          @focusin="focusin()"
          type="number"
          class="form-control dr-photo-marker-input"
          placeholder="Num"
        />
        <button type="button" class="btn btn-secondary" @click="plus()">
          <i class="bi bi-plus-lg"></i>
        </button>
      </div>
    </div>
  </form>
</template>

<style>
.dr-photo-marker-input {
  font-size: 1rem !important;
  text-align: center;
}
/** Remove default + - on desktop */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
