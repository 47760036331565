import { Logger } from "./logger";
import api from "./APIClient";
export const CURRENT_VERSION = "1.0.3";

/*
 * Checks if the front-end version provided by the back-end is equal to the current version
 * If values are equal, it returns true, if not, it returns false
 * If the back-end version is not available or fetch fails, it returns undefined
 */
export async function checkVersion(): Promise<boolean | undefined> {
  try {
    const data = await api.getHealth();

    if (data.frontend.version && data.frontend.version == CURRENT_VERSION) {
      Logger.info(
        `version.ts : the back-end version is equal to the front-end version, ${CURRENT_VERSION}`
      );
      return true;
    } else if (data.frontend.version === "-1") {
      Logger.warn(
        `version.ts : the back-end version (${data.frontend.version}) is not available`
      );
      return undefined;
    } else {
      Logger.warn(
        `version.ts : the back-end version ${data.frontend.version} is not equal to the front-end version ${CURRENT_VERSION}`
      );
      return false;
    }
  } catch (e) {
    Logger.error("version.ts : there was a problem fetching version");
    return undefined;
  }
}
