<script setup lang="ts">
import { computed } from "vue";
import type { UploadTracker } from "@/stores/fileStore";
import { filesize } from "filesize";

import UploadProgress from "@/components/files/UploadList/UploadProgress.vue";
import FileStatusPill from "@/components/files/UploadList/FileStatusPill.vue";

const props = defineProps<{
  tracker: UploadTracker;
}>();

const fileSize = computed(() => {
  return filesize(props.tracker.fileSize);
});
const isUploadOngoing = computed(() => props.tracker.progressDisplay < 100);
const isConversionDone = computed(
  () => props.tracker.conversionStatus === "done"
);
const isConversionOngoing = computed(
  () =>
    props.tracker.conversionStatus === "inprogress" ||
    props.tracker.conversionStatus === "waiting"
);
</script>

<template>
  <li
    class="list-group-item d-inline-flex justify-content-between align-items-center"
    style="min-height: 45px"
  >
    {{ tracker.fileName }}
    {{ tracker.conversionStatus }}
    <span class="mx-2 mx-md-4 text-secondary">
      {{ fileSize }}
    </span>
    <div
      class="flex-grow-1 px-2 px-lg-4 ms-auto"
      style="max-width: 400px; min-width: 150px"
    >
      <UploadProgress
        :value="tracker.progressDisplay"
        :animated="isConversionOngoing"
        :theme="
          isConversionOngoing
            ? 'warning'
            : isConversionDone
            ? 'success'
            : undefined
        "
      />
    </div>

    <span class="ms-md-4 ms-lg-4 ms-1" style="min-width: 30px">
      <span v-if="tracker.conversionStatus && !isConversionDone" class="me-2">
        <!--    <FileStatusPill status="waiting" />-->
        <FileStatusPill :status="tracker.conversionStatus" />
      </span>
      <i
        v-if="isConversionDone"
        class="bi bi-check-lg h4 text-success d-inline-flex align-items-center m-0"
      ></i>
      <span v-if="isUploadOngoing" class="text-secondary">
        {{ tracker.progressDisplay }}%
      </span>
    </span>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end"></div>
  </li>
</template>
