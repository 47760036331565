/* eslint-disable */
// @ts-nocheck
import type { Coords } from "@/open-cloud/draggers/draggers.type";

export type pointArray = [number, number, number];
export interface BaseIterator<E> {
  done: () => boolean;
  step: () => void;
}
export type OdaFile = {
  id: String;
  created: String;
  customFields: object;
  exports: Array<String>;
  geometryStatus: String;
  metadata: Boolean;
  name: String;
  owner: String;
  previewUrl: String;
  properties: Boolean;
  propertiesStatus: String;
  size: Number;
  type: String;
};

export interface OdaModel {
  id: String;
  database: String;
  default: Boolean;
  fileId: String;
  geometry: String[];
  name: String;
}

export const isVisLibError = (e: any) => {
  return typeof e === "number" && `${e}`.length > 4;
};

export function isOdtvPoint(point: pointArray | Coords): point is pointArray {
  return Array.isArray(point) && point.length >= 2;
}

export function isCoord(point: pointArray | Coords): point is Coords {
  return "x" in point && "y" in point;
}

export function isEntityId(
  entity: VisualizeJS.OdTvEntity | VisualizeJS.OdTvInsert | OdTvEntityId
): entity is OdTvEntityId {
  // @ts-ignore
  return entity && typeof entity.openObject == "function";
}

export function isIdOfEntity(entity: OdTvEntityId): boolean {
  return entity?.getType() === 1;
}

export function isIdOfInsert(entity: OdTvEntityId): boolean {
  return entity?.getType() === 2;
}

export function isInsert(
  entity: VisualizeJS.OdTvEntity | VisualizeJS.OdTvInsert
): entity is VisualizeJS.OdTvInsert {
  return entity.constructor.name === "VisualizeJS.OdTvInsert";
}

export type MatrixAsArray = [
  [number, number, number, number],
  [number, number, number, number],
  [number, number, number, number],
  [number, number, number, number]
];

export interface Extent {
  center: VisualizeJS.Point3;
  min: VisualizeJS.Point3;
  max: VisualizeJS.Point3;
}
