import type { BlockConfig } from "@/open-cloud/builders/BlockBuilder";

export const photoMarkers: BlockConfig[] = [
  {
    name: "PHOTO_LARGE_ARROW",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [0, 2, 0],
          [0.5, 1.5, 0],
          [0.25, 1.5, 0],
          [0.25, 0.5, 0],
          [-0.25, 0.5, 0],
          [-0.25, 1.5, 0],
          [-0.5, 1.5, 0],
          [0, 2, 0],
        ],
      },
    ],
    attributes: [
      {
        name: "photo_number",
        data: {
          refpoint: [0, -1.5, 0],
          alignmentmode: 10,
        },
      },
    ],
  },
  {
    name: "PHOTO_CIRCLE",
    scale: 1,
    geometries: [
      {
        type: "circle",
        center: [0, 0, 0],
        radius: 2,
      },
    ],
    attributes: [
      {
        name: "photo_number",
        data: {
          refpoint: [0, 0, 0],
          alignmentmode: 10,
        },
      },
    ],
  },
  {
    name: "PHOTO_THIN_ARROW",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [0, 2, 0],
          [0, 0.5, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.5, 1.5, 0],
          [0, 2, 0],
          [0.5, 1.5, 0],
        ],
      },
    ],
    attributes: [
      {
        name: "photo_number",
        data: {
          refpoint: [0, -1.5, 0],
          alignmentmode: 10,
        },
      },
    ],
  },
];
