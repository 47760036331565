<script setup lang="ts">
import { Facade } from "@/open-cloud/Facade";
import { Logger } from "@/logger";
import { useToast } from "../composable/useToast";

const { showDanger } = useToast();

async function backToHome() {
  Logger.info(`TopMenu.vue : backToHome()`);
  try {
    await Facade.saveDraft();
  } catch (e) {
    Logger.error(`TopMenu.vue : error while saving drawing`);
    showDanger(
      "Il y a eu un problème pendant la sauvegarde de votre dessin. Fermez-le et actualisez la page avant de réouvrir."
    );
  }
  window.location.href = "/";
}
</script>
<template>
  <div class="me-1">
    <!-- anchor can be used to mitigate memory leak -->
    <!--router-link :to="{ name: 'home' }">
            <span class="btn btn-secondary">
            <i class="bi bi-chevron-left"></i>
            </span>
        </router-link-->
    <a href="/" @click="backToHome()"
      ><span class="btn btn-secondary"><i class="bi bi-chevron-left" /></span
    ></a>
  </div>
</template>
