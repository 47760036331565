<script setup lang="ts">
import type { FileStoreDrawingFile } from "@/stores/fileStore";
import { useDeviceState } from "@/stores/DeviceState";
const props = defineProps<{
  drawing: FileStoreDrawingFile;
  spinning: boolean;
}>();
const deviceState = useDeviceState();
</script>
<template>
  <button
    ref="localRefHook"
    v-if="!props.drawing.isLocal"
    class="btn btn-primary"
    :class="{ disabled: !deviceState.isOnline }"
    @click.prevent.stop
    data-bs-content="Télécharge la dernière version de ce dessin sur l'appareil. Le dessin pourra ensuite être utiliser hors-ligne"
    data-bs-title="Télécharger sur l'appareil"
  >
    <i v-if="!spinning" class="bi bi-cloud-download"></i>
    <span v-else class="spinner-border spinner-border-sm" role="status"></span>
  </button>
  <span v-else @click.prevent.stop style="cursor: default">
    <button class="btn btn-outline-success" disabled>
      <i class="bi bi-database-check"></i>
    </button>
  </span>
</template>
