<script setup lang="ts">
import { useDrawingStore } from "@/stores/fileStore";
import { storeToRefs } from "pinia";
import UploadItem from "@/components/files/UploadList/UploadItem.vue";
import { onUnmounted, watch } from "vue";

const { uploadTrackers } = storeToRefs(useDrawingStore());

function handleUnload(event: BeforeUnloadEvent) {
  const message =
    "Les envois en cours seront annulés, êtes-vous sûr de vouloir fermer ?";
  event.returnValue = message;
  return message;
}

watch(
  () => uploadTrackers.value.length,
  (newlength) => {
    if (newlength) {
      window.addEventListener("beforeunload", handleUnload);
    } else {
      window.removeEventListener("beforeunload", handleUnload);
    }
  }
);

onUnmounted(() => {
  window.removeEventListener("beforeunload", handleUnload);
});
</script>

<template>
  <div v-if="uploadTrackers.length">
    <p class="h4">Uploads en cours</p>
    <TransitionGroup
      tag="ul"
      id="files-list"
      class="list-group w-100 mb-4"
      name="fade"
    >
      <UploadItem
        v-for="tracker in uploadTrackers"
        :tracker="tracker"
        :key="tracker.fileName"
      />
    </TransitionGroup>
  </div>
</template>
