// @ts-ignore
import { OdBaseDragger } from "@inweb/viewer-visualize";
import type { NoteConfig } from "@/stores/UserState";

export default class IdentityDragger extends OdBaseDragger {
  activeText: null | NoteConfig = null;

  // @ts-ignore
  constructor(...args) {
    // @ts-ignore
    super(...args);
  }
  start() {
    // do not call super.start()
  }
  drag() {
    // do not call super.drag()
  }
  end() {
    // do not call super.end()
  }
}
