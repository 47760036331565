import { Logger } from "@/logger";
import { syncer } from "@/repositories/Syncer";
import { drawingRepository } from "@/repositories/drawing.repository";
import type { FileStoreDrawingFile } from "@/stores/fileStore";

export async function downloadFileToUser(drawingId: number, fileName: string) {
  Logger.info(`downloadFileToUser : start converting to dwg`);
  const start = performance.now();
  const dwgBuffer = await syncer.getConvertedToDwg(drawingId);
  const end = performance.now();
  Logger.info(
    `downloadFileToUser : conversion+fetch dwg took ${end - start} ms`
  );
  const blob = new Blob([new Uint8Array(dwgBuffer)], {
    type: "application/" + "dwg",
  });

  triggerDownload(fileName, URL.createObjectURL(blob));
}

export async function downloadVSFXToUser(drawingId: number, fileName: string) {
  const localDrawing = await drawingRepository.getLastSaveOrDraft(drawingId);
  if (!localDrawing) {
    return;
  }

  const extension = localDrawing.format === "VSFX" ? "vsfx" : "vsf";
  const arrayBuffer = localDrawing.binary;

  const blob = new Blob([new Uint8Array(arrayBuffer)], {
    type: "application/" + extension,
  });

  triggerDownload(`${fileName}.${extension}`, URL.createObjectURL(blob));
}

function triggerDownload(fileName: string, href: string) {
  const anchor = document.createElement("a");
  anchor.download = fileName;
  anchor.href = href;

  document.body.append(anchor);

  anchor.click();

  anchor.onclick = function () {
    URL.revokeObjectURL(anchor.href);
    document.body.removeChild(anchor);
  };
}

export async function makeLocal(drawing: FileStoreDrawingFile) {
  try {
    await syncer.makeLastRevisionLocal(drawing.id);
  } catch (e: any) {
    Logger.error(
      `downloadFile.makeLocal : could not make file local ${drawing.id}`
    );
    throw e;
  }

  try {
    await syncer.makeInitialDrawingPropertiesLocal(
      drawing.initialOpenCloudFileId,
      drawing.userId
    );
  } catch (e: any) {
    Logger.error(
      `downloadFile.makeLocal : could not make properties local ${drawing.id} ${e.message}`
    );
    throw e;
  }
}
