import CanvasSettingsState from "@/stores/CanvasSettingsState";
import UserState from "@/stores/UserState";
import { useDrawingStore } from "@/stores/fileStore";

/**
 * mainly to avoid to create a dependency between the userstate and drawing store
 */
export function logout() {
  UserState.reset();
  CanvasSettingsState.reset();
  const drawingStore = useDrawingStore();
  drawingStore.clearDrawings();
}
