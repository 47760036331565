import type { Legend } from "@/stores/UserState";
import UserState, { LOCAL_STORAGE_JWT_KEY } from "@/stores/UserState";
import type { DRDrawing, OdaBinaryFormat } from "@/repositories/domain.types";
import ky, { type Options } from "ky";
import type { ApiDrawingDto, ApiHealth } from "@/repositories/api.types";
import { DrawingDecoder } from "@/open-cloud/drawing.decoder";
import router, { noAuthRoutePrefix } from "@/router";
import CanvasSettingsState from "./stores/CanvasSettingsState";

export type UserProperties = {
  id: number;
  open_cloud_api_token: string;
  email: string;
  full_name: string;
  joined_at: string;
  legends: Legend[];
};

type UserAuthReturnDto = {
  user: UserProperties;
  jwt: string;
};

class APIClient {
  apiURL = import.meta.env.DR_API_URL;

  client = ky.extend({
    prefixUrl: this.apiURL,
    hooks: {
      beforeError: [
        async (error) => {
          const isAuthenticatedRoute = !new RegExp(
            `^${noAuthRoutePrefix}`
          ).test(window.location.pathname);
          if (isAuthenticatedRoute && error.response.status === 401) {
            CanvasSettingsState.reset();
            UserState.reset();
            router.replace({ name: "welcome" });
          }
          // @ts-ignore
          let bodyAsJson: object = error.response.body;
          if (error.response.body instanceof ReadableStream) {
            try {
              bodyAsJson = await error.response.json();
            } catch (e) {
              bodyAsJson = {};
            }
          }
          throw new APIError(error.response.status, bodyAsJson);
        },
      ],
      beforeRequest: [
        (request) => {
          if (request.url.includes("api")) {
            const jwt = localStorage.getItem(LOCAL_STORAGE_JWT_KEY);
            if (jwt) {
              request.headers.set("Authorization", `Bearer ${jwt}`);
            }
          }
        },
      ],
    },
  });

  async get<R>(uri: string, options?: Options) {
    return this.client.get(uri, options).json<R>();
  }

  async post<R>(uri: string, body?: object, params?: Options) {
    return this.client.post(uri, { json: body, ...params }).json<R>();
  }

  static saveJwt(jwt: string) {
    localStorage.setItem(LOCAL_STORAGE_JWT_KEY, jwt);
  }

  static updateLocalUser(user: UserProperties, jwt: string) {
    APIClient.saveJwt(jwt);
    UserState.updateAndPersist(user);
  }

  static parseAuthPayload(
    user: UserProperties | UserAuthReturnDto
  ): UserAuthReturnDto {
    function isAuthPayload(
      payload: UserAuthReturnDto | unknown
    ): payload is UserAuthReturnDto {
      // @ts-ignore
      return Boolean(payload.user);
    }

    if (isAuthPayload(user)) return user;
    return { user, jwt: "" };
  }

  async forgot(email: string) {
    await this.post("auth/forgot", {
      email,
    });
  }

  async reset(token: string, password: string) {
    await this.post("auth/reset", {
      password,
      token,
    });
  }

  async signup(full_name: string, email: string, password: string) {
    const payload = await this.post<UserAuthReturnDto | UserProperties>(
      "auth/signup",
      {
        full_name,
        email,
        password,
      }
    );
    const { user, jwt } = APIClient.parseAuthPayload(payload);
    APIClient.updateLocalUser(user, jwt);
    return user;
  }

  async login(email: string, password: string): Promise<void> {
    const payload = await this.post<UserAuthReturnDto | UserProperties>(
      "auth/login",
      {
        email,
        password,
      }
    );
    const { user, jwt } = APIClient.parseAuthPayload(payload);
    APIClient.updateLocalUser(user, jwt);
  }

  async fetchDrawings(archived = false): Promise<DRDrawing[]> {
    const res = await this.client
      .get("api/drawings", { searchParams: { onlyArchived: archived } })
      .json<ApiDrawingDto[]>();

    // @todo do proper validation with a typescript lib (requires strict: true)
    return res.map((d) => DrawingDecoder.fromApiDtoToDomain(d));
  }

  async createDrawing(
    initialOdaFileId: string,
    firstRevisionOdaFileId: string,
    fileName: string
  ): Promise<DRDrawing> {
    const drawing = await this.post<ApiDrawingDto>("api/drawings", {
      initialOpenCloudFileId: initialOdaFileId,
      fileName: fileName,
      firstRevisionOpenCloudFileId: firstRevisionOdaFileId,
    });

    return DrawingDecoder.fromApiDtoToDomain(drawing);
  }

  async createRevision(
    drawingId: number,
    revisionOdaFileId: string,
    format: OdaBinaryFormat,
    currentRevisionId: number
  ) {
    return await this.post<DRDrawing>(`api/drawings/${drawingId}/revisions`, {
      openCloudFileId: revisionOdaFileId,
      format,
      ancestorRevisionId: currentRevisionId,
    });
  }

  async forkDrawing(
    drawingId: number,
    revisionOdaFileId: string
  ): Promise<DRDrawing> {
    const drawing = await this.post<ApiDrawingDto>(
      `api/drawings/${drawingId}/fork`,
      {
        openCloudFileId: revisionOdaFileId,
      }
    );
    return DrawingDecoder.fromApiDtoToDomain(drawing);
  }

  async archiveDrawing(drawingId: number): Promise<void> {
    await this.post(`api/drawings/${drawingId}/archive`);
    return;
  }

  async unArchiveDrawing(drawingId: number): Promise<void> {
    await this.post(`api/drawings/${drawingId}/unarchive`);
    return;
  }

  /**
   * The BE will do the polling, when this call ends, it means the file is ready
   * @param drawingId
   */
  async startConversionToDwg(
    drawingId: number
  ): Promise<{ fileId: string; resourceId: string }> {
    //conversion can take time and we need to increase timeout
    const params: Options = {
      timeout: 30000,
    };
    return this.post<{ fileId: string; resourceId: string }>(
      `api/drawings/${drawingId}/dwg`,
      undefined,
      params
    );
  }

  async getHealth(): Promise<ApiHealth> {
    return await this.get<ApiHealth>("health");
  }
}

export class APIError extends Error {
  response: object & { message?: string };
  status: number;
  _error?: Error;

  constructor(status: number, body: object, error?: Error) {
    super(`API responded with status ${status}.`);
    this.status = status;
    this.response = body;
    if (error) {
      this._error = error;
    }
  }

  isAuthError() {
    return this.status === 401;
  }

  static isOffline(error: Error) {
    return error instanceof TypeError && error?.message === "Failed to fetch";
  }

  userMessage() {
    return this.response?.message;
  }
}

/**
 * Replaces the old version of user that is still provided by the back-end
 * @param user
 */

export default new APIClient();
