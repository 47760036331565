import type { DRViewer } from "../DRViewer";
import type { Attribute } from "./BlockBuilder";
import { EntityBuilder } from "./EntityBuilder";
import { TextBuilder } from "./TextBuilder";

export class InsertBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  static setBlockMatrix(
    entId: VisualizeJS.OdTvEntityId,
    matrix: VisualizeJS.Matrix3d
  ) {
    const insert = entId.openObjectAsInsert();
    const old = insert.getBlockTransform();
    old.invert();
    const delta = old.preMultBy(matrix);
    insert.setBlockTransform(matrix);
    const itr = insert.getSubEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      const baseMatrix = EntityBuilder.getModelingMatrix(entId);
      baseMatrix.preMultBy(delta);
      EntityBuilder.setModelingMatrix(entId, baseMatrix);
      baseMatrix.delete();
      entId.delete();
    }
    itr.delete();
    delta.delete();
    old.delete();
  }

  static addAttribute(
    insertId: VisualizeJS.OdTvEntityId,
    attribute: Attribute,
    message: string
  ) {
    const insert = insertId.openObjectAsInsert();
    const entId = insert.appendSubEntity(attribute.name);
    TextBuilder.addText(entId, { ...attribute.data, message });
    insert.delete();
    entId.delete();
  }
}
