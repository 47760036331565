<script setup lang="ts">
import { computed } from "vue";
import CanvasButton from "@/components/canvas/CanvasButton.vue";
import type { DraggerConfig } from "@/open-cloud/draggers/draggers.type";
import DraggerMenuState from "@/stores/DraggerMenuState";

const props = defineProps<{
  config: DraggerConfig;
  icon?: string;
  tooltipText?: string;
  tooltipTitle?: string;
}>();

const isActive = computed(
  () => DraggerMenuState.activeDragger.name === props.config.name
);
</script>

<template>
  <CanvasButton
    :icon="icon"
    :tooltip-text="tooltipText"
    :tooltip-title="tooltipTitle"
    :active="isActive"
  >
    <slot />
  </CanvasButton>
</template>
