import { TEMP_LAYER } from "../DRViewer";
import type { Layer } from "../builders/LayerBuilder";
import Command from "./Command";
export type DeleteParams = {
  handle: string;
  layername: Layer["name"];
}[];

export default class DeleteEntities extends Command<DeleteParams> {
  // reset entities in their original layer
  execute(params: DeleteParams, shouldStore = true): void {
    const handles = params.map((param) => param.handle);
    this._viewer.entityBuilder.setLayerByHandles(handles, TEMP_LAYER.name);
    super.execute(params, shouldStore);
    this._viewer.update();
  }
  _reexecute(params: DeleteParams) {
    const handles = params.map((param) => param.handle);
    this._viewer.entityBuilder.setLayerByHandles(handles, TEMP_LAYER.name);
    this._viewer.update();
  }
  _unexecute(params: DeleteParams) {
    for (const param of params) {
      this._viewer.entityBuilder.setLayerByHandle(
        param.handle,
        param.layername
      );
    }
    this._viewer.update();
  }
  _delete(params: DeleteParams): void {
    const handles = params.map((param) => param.handle);
    this._viewer.entityBuilder.removeByHandlesIfTemp(handles);
  }
}
