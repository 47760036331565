import OneWayEntityDragger from "./OneWayEntityDragger";
import {
  checkIfCloseAndAddCursorStart,
  isExtremitiesClose,
} from "../composables/close.utils";
import { clearCursor } from "../composables/close.utils";
import { smooth3DPolyline } from "../composables/smooth.utils";

export default class FreeLineDragger extends OneWayEntityDragger {
  autoSelect = false;
  cursorId: VisualizeJS.OdTvEntityId | null = null;

  end(x: number, y: number) {
    let isClosed = false;
    if (this.viewer.closeContour) {
      isClosed = isExtremitiesClose(this.drawPoints, this.viewer);
    }
    if (this.viewer.smoothen) {
      this.drawPoints = smooth3DPolyline(this.drawPoints);
    }
    if (isClosed) this.closeContour();
    this.refreshShadowEntity();
    const ent = this.shadowId?.openObject();
    const geomId = ent?.appendPolyline(this.drawPoints);
    this._setNoteConfigProperties(this.shadowId);
    geomId?.delete();
    ent?.delete();
    super.end(x, y);
  }

  _updateFrame(): void {
    this.refreshShadowEntity();
    if (this.shadowId) {
      const ent = this.shadowId.openObject();
      const geomId = ent.appendPolyline(this.drawPoints);
      this._setNoteConfigProperties(this.shadowId);
      if (this.viewer.closeContour) {
        this.cursorId = checkIfCloseAndAddCursorStart(
          this.cursorId,
          this.drawPoints,
          this.viewer
        );
      }

      geomId.delete();
      ent.delete();
    }
  }

  /**
   * Closes the contour if the extremities are close
   */
  closeContour() {
    this.drawPoints.push(
      this.drawPoints[0],
      this.drawPoints[1],
      this.drawPoints[2]
    );
  }

  clear() {
    this.cursorId = clearCursor(this.cursorId, this.viewer);
    super.clear();
  }
}
