import type { DraggerConfig } from "@/open-cloud/draggers/draggers.type";
import DRBaseDragger from "../DRBaseDragger";
import type { pointArray } from "@/open-cloud/types/oda.types";

export default class BaseEntityDragger extends DRBaseDragger {
  start(x: number, y: number) {
    super.start(x, y);
    this._updateFrame();
  }

  drag(x: number, y: number) {
    super.drag(x, y);
    if (this.isDragging) this._updateFrame();
  }

  onescape() {
    this.clear();
  }

  clear() {
    super.clear();
    this.viewer.measureDragger.clearMeasureLines();
  }

  _setNoteConfigProperties(entId: VisualizeJS.OdTvEntityId | null) {
    if (entId) {
      this._setProperties(entId, this.viewer.activeNoteConfig.props);
    }
  }

  setConfig(config: DraggerConfig) {
    this.config = config;
  }

  /**
   * Draws Horizontal and Vertical measurements
   */
  drawHVMeasurements() {
    this.viewer.measureDragger.clearMeasureLines();
    if (this.startCornerWCS.length && this.endCornerWCS.length) {
      const corner1: pointArray = [
        this.startCornerWCS[0],
        this.endCornerWCS[1],
        0,
      ];
      this.viewer.measureDragger.drawMeasureLine(this.startCornerWCS, corner1);
      this.viewer.measureDragger.drawMeasureLine(corner1, this.endCornerWCS);
    }
  }
  /**
   * Draws Diagonal measurements
   */
  drawDMeasurements() {
    this.viewer.measureDragger.clearMeasureLines();
    if (this.startCornerWCS.length && this.endCornerWCS.length) {
      this.viewer.measureDragger.drawMeasureLine(
        this.startCornerWCS,
        this.endCornerWCS
      );
    }
  }
}
