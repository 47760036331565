import { TextBuilder, type TextData } from "@/open-cloud/builders/TextBuilder";
import OneWayEntityDragger from "./OneWayEntityDragger";

export default class TextDragger extends OneWayEntityDragger {
  autoSelect = false;

  _updateFrame(): void {
    this.refreshShadowEntity();
    if (this.shadowId && this.endCornerWCS.length === 3) {
      const data: TextData = {
        refpoint: this.endCornerWCS,
        message: this.viewer.activeText,
      };
      TextBuilder.addText(this.shadowId, data);
      this._setNoteConfigProperties(this.shadowId);
    }
  }
}
