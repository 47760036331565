<script setup lang="ts">
import AddFile from "@/components/files/AddFile.vue";
import OfflineIndicator from "@/components/files/OfflineIndicator.vue";
import { useDeviceState } from "@/stores/DeviceState";
import ShowArchived from "@/components/files/ShowArchived.vue";
import ArchiveSelected from "./ArchiveSelected.vue";

const deviceState = useDeviceState();
</script>

<template>
  <div class="d-flex gap-2 justify-content-end text-end my-2 pb-2">
    <OfflineIndicator class="ms-3" v-if="!deviceState.isOnline" />
    <div class="flex-grow-1"></div>
    <AddFile />
    <ArchiveSelected />
    <ShowArchived />
  </div>
</template>
