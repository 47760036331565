import Dropdown from "bootstrap/js/dist/dropdown";

export type ColorTheme =
  | "primary"
  | "success"
  | "info"
  | "danger"
  | "warning"
  | "secondary"
  | "tertiary"
  | "light";
export type FontSize = 1 | 2 | 3 | 4 | 5 | 6;

function disposeAllDropdowns() {
  const dropdownElementList = document.querySelectorAll(".dropdown-toggle");
  [...dropdownElementList].map((dropdownElement) => {
    const dropdown = new Dropdown(dropdownElement);
    dropdown.dispose();
  });
}

export function disposeBootstrap() {
  disposeAllDropdowns();
}
