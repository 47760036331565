import { TextBuilder, type TextData } from "@/open-cloud/builders/TextBuilder";
import OneWayEntityDragger from "./OneWayEntityDragger";

export default class RectangleDragger extends OneWayEntityDragger {
  _updateFrame(): void {
    if (this.startCornerWCS.length === 3 && this.endCornerWCS.length === 3) {
      this.refreshShadowEntity();
      if (this.shadowId) {
        this.drawRectangle(
          this.shadowId,
          this.startCornerWCS,
          this.endCornerWCS,
          this.viewer.activeNoteConfig.label
        );
        this.drawHVMeasurements();
        this._setNoteConfigProperties(this.shadowId);
      }
    }
  }

  drawRectangle(
    entityId: VisualizeJS.OdTvEntityId,
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number],
    label?: string
  ) {
    const entity = entityId.openObject();
    const corner1 = [startCornerWCS[0], endCornerWCS[1], 0];
    const corner2 = [endCornerWCS[0], startCornerWCS[1], 0];
    const contour = entity.appendPolyline([
      ...startCornerWCS,
      ...corner1,
      ...endCornerWCS,
      ...corner2,
      ...startCornerWCS,
    ]);
    const center = this.viewer.toolbox.computeCenter(
      startCornerWCS,
      endCornerWCS
    );
    if (label && this.viewer.label) {
      const data: TextData = {
        refpoint: center,
        message: label,
        alignmentmode: 10,
      };
      TextBuilder.addText(entityId, data);
    }
    contour.delete();
    entity.delete();
  }
}
