<script setup lang="ts">
import Dragger from "../Tools/Dragger.vue";
import {
  DraggerName,
  type DraggerConfig,
  DraggerEvents,
} from "@/open-cloud/draggers/draggers.type";
import { Logger } from "@/logger";
import DraggerMenuState from "@/stores/DraggerMenuState";
import { Facade } from "@/open-cloud/Facade";

Facade.on(DraggerEvents.OriginSet, (e: CustomEvent) => {
  DraggerMenuState.persistOrigin(e.detail.coordinates);
});

function activateDragger(config: DraggerConfig) {
  Logger.info(`OriginMenu.vue : activating dragger ${config.name}`);
  DraggerMenuState.setActiveDragger(config);
}
</script>
<template>
  <Dragger
    :config="{ name: DraggerName.DrOrigin }"
    icon="rulers"
    @click="activateDragger({ name: DraggerName.DrOrigin })"
    :activeDragger="DraggerMenuState.activeDragger"
    tooltipText="Permet de déplacer l'origine des règles graduées."
    tooltipTitle="Origine"
  />
</template>
