<script setup lang="ts">
import { Logger } from "@/logger";
import Settings from "@/stores/Settings";

function setStylusMode(mode: boolean) {
  Logger.info(`StylusModeToggler.vue : toggle stylus mode to ${mode}`);
  Settings.setStylusMode(mode);
}
</script>

<template>
  <div class="btn-group">
    <button
      class="btn"
      :class="Settings.onlyStylusOn ? 'btn-primary' : 'btn-outline-primary'"
      @click="() => setStylusMode(true)"
    >
      Oui
    </button>
    <button
      class="btn"
      :class="!Settings.onlyStylusOn ? 'btn-primary' : 'btn-outline-primary'"
      @click="() => setStylusMode(false)"
    >
      Non
    </button>
  </div>
</template>
