import { OdBaseDragger } from "@inweb/viewer-visualize";
import { PanAction } from "@inweb/viewer-visualize/src/Viewer/Draggers/Actions/PanAction";
import type { DRViewer } from "@/open-cloud/DRViewer";
import { delayedPanZoomEnded } from "./navigation.utils";
import { changeCursor } from "./navigation.utils";
import DraggerState from "../DraggersState";
import { DraggerEvents } from "../draggers.type";
export default class WheelSpaceKeyPanDragger extends OdBaseDragger {
  previousCursor = "default";
  private _panAction: PanAction;
  isClicked = false;
  spaceKeyPressed = false;
  constructor(viewer: DRViewer) {
    super(viewer);
    this._panAction = new PanAction(
      this.m_module,
      this.subject,
      this.beginInteractivity,
      this.endInteractivity,
      this.getViewParams,
      this.setViewParams
    );
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
  }

  isMouseEvent(ev: PointerEvent): boolean {
    return ev.pointerType === "mouse";
  }

  isWheelClick(ev: PointerEvent): boolean {
    return this.isMouseEvent(ev) && ev.button === 1;
  }

  public initialize(): void {
    super.initialize();
    this.subject.addEventListener("pointerdown", this.onPointerDown);
    this.subject.addEventListener("pointermove", this.onPointerMove);
    this.subject.addEventListener("pointerup", this.onPointerUp);
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  }

  private onPointerDown(event: PointerEvent) {
    if (this.isWheelClick(event) || this.spaceKeyPressed) {
      this.isClicked = true;
      DraggerState.isGestureActive = true;
      changeCursor("pointer");
      const point = this.relativeCoords(event);
      this.onmessage({ type: DraggerEvents.AutoPanZoomStart });
      this._panAction.beginAction(point.x, point.y);
    }
  }

  private onPointerMove(event: PointerEvent) {
    if (this.isClicked) {
      const point = this.relativeCoords(event);
      this._panAction.action(point.x, point.y);
      this.subject.update();
    }
  }

  private onPointerUp(event: PointerEvent) {
    if (this.isWheelClick(event) || this.spaceKeyPressed) {
      this.end();
    }
  }

  end() {
    this.isClicked = false;
    changeCursor("default");
    this._panAction.endAction();
    this.subject.update();
    delayedPanZoomEnded(this);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === " ") {
      changeCursor("pointer");
      this.spaceKeyPressed = true;
    }
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.key === " ") {
      this.end();
      this.spaceKeyPressed = false;
    }
  }

  public dispose(): void {
    super.dispose();
    this.subject.removeEventListener("pointerdown", this.onPointerDown);
    this.subject.removeEventListener("pointermove", this.onPointerMove);
    this.subject.removeEventListener("pointerup", this.onPointerUp);
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  }
}
