<script setup lang="ts">
import CanvasButton from "@/components/canvas/CanvasButton.vue";
import { Logger } from "@/logger";
import { Facade } from "@/open-cloud/Facade";

function undo() {
  Logger.info(`ActionMenu.vue : undo`);
  Facade.undo();
}

function redo() {
  Logger.info(`ActionMenu.vue : redo`);
  Facade.redo();
}

function deleteSelection() {
  Logger.info(`ActionMenu.vue : deleteSelection`);
  Facade.deleteSelection();
}
function copySelection() {
  Logger.info(`ActionMenu.vue : copySelection`);
  Facade.copySelection();
}
function escape() {
  Logger.info(`ActionMenu.vue : escape`);
  Facade.escape();
}
</script>

<template>
  <div class="btn-group me-2 align-self-start">
    <CanvasButton
      icon="arrow-counterclockwise"
      @click="undo()"
      tooltipText="Permet d'annuler la dernière action"
      tooltipTitle="Annuler"
    />
    <CanvasButton
      icon="arrow-clockwise"
      @click="redo()"
      tooltipText="Permet de refaire la dernière action annulée"
      tooltipTitle="Refaire"
    />
    <CanvasButton
      icon="trash"
      @click="deleteSelection()"
      tooltipText="Permet d'effacer la sélection"
      tooltipTitle="Effacer"
    />
    <!--      Prefer `copy` to `clipboard` in version starting 1.11-->
    <CanvasButton
      icon="copy"
      @click="copySelection()"
      tooltipText="Permet de dupliquer la sélection"
      tooltipTitle="Dupliquer"
    />
    <CanvasButton
      @click="escape()"
      tooltipText="Arrête l'action en cours"
      tooltipTitle="Echap"
      >Echap</CanvasButton
    >
  </div>
</template>
