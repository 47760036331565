<script setup lang="ts">
import type { Legend } from "@/stores/UserState";
import UserState from "@/stores/UserState";
import { ref } from "vue";
import { LEGEND_LIST_ID } from "@/stores/Settings";

const list = ref<HTMLDataListElement>();

function open(legend: Legend) {
  list.value?.dispatchEvent(
    new CustomEvent("showModal", {
      detail: {
        legend: legend,
      },
    })
  );
}
</script>
<template>
  <ul class="list-group" ref="list" :id="LEGEND_LIST_ID">
    <li
      v-for="legend in UserState.data?.legends"
      :key="legend.name"
      class="list-group-item list-group-item-action"
      @click="open(legend)"
    >
      {{ legend.name }}
    </li>
  </ul>
</template>
@/stores/UserState@/stores/UserState
