<script setup lang="ts">
import { ref } from "vue";
import { delay } from "lodash";
import ScaleMenuState from "@/stores/ScaleMenuState";

const SizeMenuInputHTML = ref<HTMLInputElement | null>(null);

function blur() {
  SizeMenuInputHTML.value?.blur();
}

function handleInput() {
  if (ScaleMenuState.forcedSize == "") {
    ScaleMenuState.isTextsizeForced = false;
  } else {
    ScaleMenuState.isTextsizeForced = true;
  }
  delay(() => {
    ScaleMenuState.forceTextsize();
  }, 200);
}

function handleCheckbox() {
  delay(() => {
    ScaleMenuState.forceTextsize();
  }, 200);
}
</script>
<template>
  <li>
    <hr class="dropdown-divider" />
  </li>
  <li>
    <div class="d-inline-flex align-items-center">
      <h6 class="dropdown-header">Forcer une hauteur</h6>
      <input
        class="form-check-input me-1"
        type="checkbox"
        value=""
        v-model="ScaleMenuState.isTextsizeForced"
        @click="handleCheckbox"
      />
    </div>
  </li>
  <li class="ps-3 pe-2">
    <form @submit.prevent="blur()">
      <input
        type="number"
        ref="SizeMenuInputHTML"
        class="form-control"
        step="any"
        placeholder="Hauteur"
        v-model="ScaleMenuState.forcedSize"
        @focus="handleInput"
        @input="handleInput"
      />
    </form>
  </li>
</template>
