import type { DRViewer } from "@/open-cloud/DRViewer";
import type { DRLineWeightType } from "@/open-cloud/types/lineweight.type";
import { Logger } from "@/logger";

export class LineweightBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): any {
    return this.viewer.visViewer();
  }

  logLineweight(entId: VisualizeJS.OdTvEntityId) {
    const lwDef = this.getLineweightDef(entId);
    Logger.debug("Line weight type: ", lwDef.getType());
    Logger.debug("Line weight Inherited: ", lwDef.getInheritedLineWeight());
    Logger.debug("Line weight value: ", lwDef.getValue());
    lwDef.delete();
  }

  getLineweightDef(
    objId: VisualizeJS.OdTvEntityId | VisualizeJS.OdTvLayerId,
    type = this.visLib.GeometryTypes.kAll
  ): VisualizeJS.OdTvLineWeightDef {
    const obj = objId.openObject();
    const lw = obj.getLineWeight(type);
    obj.delete();
    return lw;
  }

  setLineweight(lwDef: VisualizeJS.OdTvLineWeightDef, lw: DRLineWeightType) {
    if (lw == "kDefault") {
      lwDef.setDefault();
    } else if (lw == "kInherited") {
      lwDef.setInherited(this.visLib.InheritedAttribute.kByLayer); // FIXME : should be parametered with default value
    } else {
      lwDef.setValue(lw);
    }
  }
}
