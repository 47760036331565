/** * This implementation leads to memory leaks. * HTMLInputElement is
referenced by WebNode and is not collected. * Workaround is to add and remove
input manually from the DOM * */
<script setup lang="ts">
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
import { computed, ref, onMounted } from "vue";
import { DraggerName } from "@/open-cloud/draggers/draggers.type";
import DraggerMenuState from "@/stores/DraggerMenuState";
import { Logger } from "@/logger";
import KeyboardModal from "./TextInput/KeyboardModal.vue";
import TextInput from "./TextInput/TextInput.vue";
import type { ExposedHtmlInput } from "./TextInput/text.type";
import { Facade } from "@/open-cloud/Facade";

const TextInputHTML = ref<ExposedHtmlInput | null>(null);

function blur() {
  Logger.info(
    `TextInput.vue : blur() text is ${DraggerMenuState.currentTextInput}`
  );
  TextInputHTML.value?.blur();
}

const { refHook } = useLongPressTooltip();

function onModalOpen() {
  Logger.info(
    `TextInput.vue : OpenModal text is ${DraggerMenuState.currentTextInput}`
  );
}

onMounted(() => {
  Facade.on("changeactivedragger", () => {
    setTimeout(() => {
      if (isTextDraggerActive.value)
        DraggerMenuState.setActiveText(DraggerMenuState.currentTextInput);
    }, 300);
  });
});
const isTextDraggerActive = computed(() => {
  return (
    DraggerMenuState.activeDragger.name == DraggerName.DrText ||
    DraggerMenuState.activeDragger.name == DraggerName.DrLeader
  );
});
</script>
<template>
  <div v-if="isTextDraggerActive">
    <form class="ms-1" @submit.stop.prevent="blur()">
      <div
        class="ms-1 input-group d-inline-flex flex-nowrap"
        style="width: auto; min-width: 80px"
        ref="refHook"
        data-bs-content="Tapez le texte de votre choix et ajoutez le en faisant glisser le stylet sur le plan. L'élément dessiné prend les propriétés du désordres sélectionnés. La croix permet de remettre à zéro le texte."
        data-bs-title="Texte personalisé"
      >
        <TextInput ref="TextInputHTML" />
        <button
          class="btn btn-secondary"
          type="button"
          @click="onModalOpen()"
          data-bs-toggle="modal"
          data-bs-target="#modalTextPanel"
        >
          <i class="bi bi-pencil-square"></i>
        </button>
      </div>
    </form>
  </div>
  <KeyboardModal />
</template>
