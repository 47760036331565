<script setup lang="ts">
import UserState from "@/stores/UserState";
import router from "@/router";
import OffcanvasHelp from "./navbar/OffcanvasHelp.vue";
import OffcanvasAccountInfo from "./navbar/OffcanvasAccountInfo.vue";
import SettingsToggler from "./navbar/SettingsToggler.vue";
//import LegendConfigurator from "./navbar/LegendConfigurator.vue";
import { computed } from "vue";

const routeIsNotCanvas = computed(() => {
  if (router.currentRoute.value.name !== "canvas") {
    return true;
  } else {
    const app = document.getElementById("app");
    app ? (app.style.height = "100%") : null;
    return false;
  }
});
</script>

<template>
  <div class="container-fluid bg-primary">
    <nav v-if="routeIsNotCanvas" class="navbar navbar-dark ms-2">
      <router-link :to="{ name: 'home' }" class="navbar-brand">
        <span class="fs-4 fw-bolder">D R</span>
      </router-link>
      <ul
        class="navbar-nav ms-auto list-group-horizontal"
        v-if="UserState.isLoggedIn"
      >
        <li class="me-2">
          <SettingsToggler />
        </li>
        <li class="me-2">
          <OffcanvasHelp />
        </li>
        <li class="nav-item">
          <OffcanvasAccountInfo />
        </li>
      </ul>
      <!--LegendConfigurator /-->
    </nav>
  </div>
</template>
@/stores/UserState
