<script setup lang="ts">
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
import type { FileStoreDrawingFile } from "@/stores/fileStore";
import { Monitor } from "@/utils/monitor";
import { formatDistanceToNow } from "@/utils/misc.utils";
import { ref, onMounted, onUnmounted } from "vue";
const props = defineProps<{
  drawing: FileStoreDrawingFile;
}>();
const { refHook: refHookSyncWarning } = useLongPressTooltip();
const { refHook: refHookSynced } = useLongPressTooltip();
// Last edit label
const lastEditMonitor = new Monitor(
  () => {
    if (props.drawing.lastRevisionEditedAt) {
      editedAt.value = formatEditAt();
    }
  },
  { intervalInMs: 2000 }
);
const formatEditAt = () =>
  props.drawing.lastRevisionEditedAt &&
  formatDistanceToNow(props.drawing.lastRevisionEditedAt);

const editedAt = ref(formatEditAt());

onMounted(() => {
  lastEditMonitor.start();
});

onUnmounted(() => {
  lastEditMonitor.stop();
});
</script>
<template>
  <div>
    <small class="text-muted align-self-center" v-if="editedAt">
      edité {{ editedAt }}
    </small>
    <span v-if="drawing.isSynced" class="text-success align-self-center">
      <i
        ref="refHookSynced"
        data-bs-content="Ce fichier est synchronisé avec le serveur"
        class="bi bi-check2"
      ></i>
    </span>
    <span v-else class="text-warning align-self-center">
      <i
        ref="refHookSyncWarning"
        data-bs-content="Ce fichier contient un brouillon non synchronisé avec le serveur"
        class="bi bi-exclamation-circle-fill"
      ></i>
    </span>
  </div>
</template>
