import { ref, type Ref } from "vue";

export function useTogglePassword(): {
  toggle: () => void;
  refPwdHook: Ref<HTMLInputElement | null>;
  isPwdToggled: Ref<boolean>;
} {
  const inputRefHook = ref<HTMLInputElement | null>(null);
  const isPwdToggled = ref(false);

  const toggle = () => {
    if (inputRefHook.value?.type === "password") {
      inputRefHook.value.type = "text";
      isPwdToggled.value = true;
    } else if (inputRefHook.value?.type === "text") {
      inputRefHook.value.type = "password";
      isPwdToggled.value = false;
    }
  };

  return {
    toggle,
    refPwdHook: inputRefHook,
    isPwdToggled,
  };
}
