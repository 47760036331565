<script setup lang="ts">
import type { NoteConfig } from "@/stores/UserState";
import { computed } from "vue";
const props = defineProps<{
  scale: NoteConfig;
  activeScale?: NoteConfig | null;
}>();

const isActive = computed<boolean>(
  () => props.activeScale?.name === props.scale.name
);

const size = computed<number | undefined>(() => {
  if (props.scale.props.textsize) {
    return props.scale.props.textsize;
  } else if (props.scale.props.textstyle?.size) {
    return props.scale.props.textstyle?.size;
  } else {
    return undefined;
  }
});

const message = computed<string>(() => {
  let name = props.scale.props.textstyle
    ? props.scale.props.textstyle.name
    : props.scale.name;
  size.value ? (name += ` (${size.value})`) : null;
  return name;
});
</script>
<template>
  <div
    class="dropdown-item"
    :class="{
      active: isActive,
    }"
  >
    {{ message }}
  </div>
</template>
