import { TEMP_LAYER } from "../DRViewer";
import type { Layer } from "../builders/LayerBuilder";
import Command from "./Command";

export type ReplaceParams = {
  old: {
    handle: string;
    layername: Layer["name"];
  };
  replacer: {
    handle: string;
    layername: Layer["name"];
  };
}[];

export default class ReplaceEntities extends Command<ReplaceParams> {
  execute(params: ReplaceParams): void {
    super.execute(params);
    const handles = params.map((param) => param.old.handle);
    this._viewer.entityBuilder.setLayerByHandles(handles, TEMP_LAYER.name);
    this._viewer.update();
  }
  _unexecute(params: ReplaceParams) {
    for (const param of params) {
      this._viewer.entityBuilder.setLayerByHandle(
        param.old.handle,
        param.old.layername
      );
      this._viewer.entityBuilder.setLayerByHandle(
        param.replacer.handle,
        TEMP_LAYER.name
      );
    }
    this._viewer.update();
  }
  _reexecute(params: ReplaceParams) {
    for (const param of params) {
      this._viewer.entityBuilder.setLayerByHandle(
        param.old.handle,
        TEMP_LAYER.name
      );
      this._viewer.entityBuilder.setLayerByHandle(
        param.replacer.handle,
        param.replacer.layername
      );
    }

    this._viewer.update();
  }
  _delete(params: ReplaceParams): void {
    const handles: string[] = [];
    params.forEach((param) => {
      handles.push(param.old.handle);
      handles.push(param.replacer.handle);
    });
    this._viewer.entityBuilder.removeByHandlesIfTemp(handles);
  }
}
