import type { DRViewer } from "@/open-cloud/DRViewer";

export interface TextData {
  refpoint: VisualizeJS.Point3;
  alignmentmode?: number;
  message: string;
}

export class TextBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): any {
    return this.viewer.visLib();
  }

  static isTextGeometry(geometry: VisualizeJS.OdTvGeometryDataId) {
    return geometry.getType() === 8;
  }

  get visViewer(): any {
    return this.viewer.visViewer();
  }

  /**
   * Add a text to an entity
   * For alignment, refer to https://docs.opendesign.com/tv/OdTvTextStyle__AlignmentType.html
   * alignment does not work well, if textsize is not set,
   * the vertical algnment does not work
   * @param entityId
   * @param point
   * @param text
   * @param alignment
   */
  static addText(entityId: VisualizeJS.OdTvEntityId, data: TextData) {
    const entity = entityId.openObject();
    const geomId = entity.appendText(data.refpoint, data.message);
    const text = geomId.openAsText();
    text.setPosition(data.refpoint);
    if (data.alignmentmode) {
      text.setAlignmentMode(data.alignmentmode);
    }
    text.delete();
    geomId.delete();
    entity.delete();
  }
}
