export type EntityResizeGrid = {
  handles: Handles;
  perimeterEntityId: VisualizeJS.OdTvEntityId;
  targetEntityId: VisualizeJS.OdTvEntityId;
  data: Record<string, any> & {
    transformMatrix?: VisualizeJS.Matrix3d;
  };
};

export const HANDLE_NAMES = [
  "center",
  "bottomLeft",
  "topLeft",
  "topRight",
  "bottomRight",
  "rotation",
  "topMiddle",
  "rightMiddle",
  "bottomMiddle",
  "leftMiddle",
  "scale",
] as const;

export type HandleName = (typeof HANDLE_NAMES)[number];
export type Handle = {
  point: VisualizeJS.Point3;
  entity: VisualizeJS.OdTvEntityId;
};

export type Handles = {
  [Key in HandleName]?: Handle;
};

// theme used to draw perimeter and handles
export const THEME = {
  gridPerimeterRGB: [0, 123, 244] as [number, number, number],
  handleFillRGB: [0, 123, 244] as [number, number, number],
  handleFillRGBAlt: [0, 244, 123] as [number, number, number],
  handlePerimeter: [255, 255, 255] as [number, number, number],
};

interface DrawSelectionGridEventDetail {
  targetHandles: string[];
}

export const SELECTION_GRID_EVENT_NAME = "DrawSelectionGrid";

export class DrawSelectionGridEvent extends CustomEvent<DrawSelectionGridEventDetail> {
  constructor(detail: DrawSelectionGridEventDetail) {
    super(SELECTION_GRID_EVENT_NAME, {
      detail,
    });
  }
}
