import { db } from "@/repositories/database";
import type { Table } from "dexie";
import Dexie from "dexie";
import uniqBy from "lodash/uniqBy";
import { Logger } from "@/logger";

type IdType = string | number;

export class BaseRepository<T extends { id: I }, I extends IdType = string> {
  readonly primaryKey = "id";

  constructor(private table: Table<T, I>, private resourceName: string) {}

  static isContraintError(e: Error) {
    return e.name === Dexie.errnames.Constraint;
  }
  fetchAll() {
    return this.table.toArray();
  }

  getById(id: I) {
    return this.table.get(id);
  }

  async getByIdOrThrow(id: I) {
    const item = await this.getById(id);
    if (!item) throw new Error(`Unknown ${this.resourceName} ${id}`);
    return item;
  }

  async pruneManyFromIds(ids: I[], indexName: string): Promise<number> {
    const deletedCount = await db.transaction("rw", this.table, async () => {
      return this.table.where(indexName).noneOf(ids).delete();
    });
    if (deletedCount) {
      Logger.warn(
        `Pruned ${deletedCount} ${this.resourceName}. Were in the db but not anymore on server`
      );
    } else {
      Logger.warn(`No ${this.resourceName} pruned`);
    }
    return deletedCount;
  }

  async exists(id: string | number) {
    const count = await this.table.where(this.primaryKey).equals(id).count();
    return count > 0;
  }

  /**
   * Create a new item if it doesn't already exist. Existing condition is done on the primary key.
   * Checking existence and insertion in the same transaction
   * @param item
   * @return The inserted item, or undefined if it was already existing
   */
  createSafeById(item: T) {
    return db.transaction("rw", this.table, async () => {
      if (await this.exists(item[this.primaryKey])) return;
      return this.table.add(item);
    });
  }

  /**
   * Create many items. For each of them it will check first if they exist by primary key.
   * One transaction per item
   * @param items Items to insert
   * @return Array of Ids of inserted items. Doesn't include the items that were already existing.
   */
  async createManySafe(items: T[]) {
    const rowsWithoutDuplicates: T[] = uniqBy(items, this.primaryKey);
    const addedIds = await Promise.all(
      rowsWithoutDuplicates.map((f) => this.createSafeById(f))
    );
    return addedIds.filter((e) => Boolean(e)) as string[];
  }

  async createMany2(items: T[]): Promise<{ created: I[]; failed: I[] }> {
    return this.table
      .bulkAdd(items, { allKeys: true })
      .then((created) => {
        const failed: I[] = [];
        return { created, failed };
      })
      .catch(Dexie.BulkError, function (e) {
        // Explicitly catching the bulkAdd() operation makes those successful
        // additions commit despite that there were errors.
        if (e.failures?.length) {
          if (e.failures.every(BaseRepository.isContraintError)) {
            const failedIndexes = e.failuresByPos
              .map((e: unknown, i: number) => i)
              .filter((e: number | undefined) => typeof e === "number");

            const failed: I[] = [],
              created: I[] = [];
            items.forEach((e, i) => {
              if (failedIndexes.includes(i)) failed.push(e.id);
              else created.push(e.id);
            });

            return { created, failed };
          }
        }
        throw e;
      });
  }
}
