import { OdBaseDragger } from "@inweb/viewer-visualize";
import type { DraggerConfig } from "./draggers.type";
import type { DRViewer } from "../DRViewer";
import { DraggerEvents, DraggerName } from "./draggers.type";
import type { EntityProps } from "../builders/EntityBuilder";
import DraggerState from "./DraggersState";

export default class DRBaseDragger extends OdBaseDragger {
  config: DraggerConfig = {
    name: DraggerName.NoDragger,
  };
  viewer: DRViewer;
  startCornerCanvas: [number, number, number] | [] = [];
  endCornerCanvas: [number, number, number] | [] = [];
  startCornerWCS: [number, number, number] | [] = [];
  endCornerWCS: [number, number, number] | [] = [];
  drawPoints: number[] = [];
  shadowId: VisualizeJS.OdTvEntityId | null = null;

  constructor(viewer: DRViewer) {
    super(viewer);
    this.viewer = viewer;
    this.press = false;
    this.viewer.addEventListener(DraggerEvents.Escape, () => this.onescape());
    DraggerState.isBaseActive = true;
  }

  protected pointerdown(ev: PointerEvent): void {
    if (DraggerState.isBaseActive) {
      super.pointerdown(ev);
    }
  }

  protected pointermove(ev: PointerEvent): void {
    if (DraggerState.isBaseActive) {
      super.pointermove(ev);
    } else {
      // start can have happened and Dragger should be reset.
      this.clear();
    }
  }

  protected pointerup(ev: PointerEvent): void {
    if (DraggerState.isBaseActive) {
      super.pointerup(ev);
    } else {
      // start can have happened and Dragger should be reset.
      this.clear();
    }
  }

  start(x: number, y: number) {
    this.startCornerCanvas = [x, y, 0];
    const point = this.getViewer().screenToWorld(x, y);
    this.drawPoints = [point[0], point[1], point[2]];
    this.startCornerWCS = [point[0], point[1], point[2]];
  }

  drag(x: number, y: number) {
    if (!this.isDragging) return;
    const point = this.getViewer().screenToWorld(x, y);
    this.drawPoints.push(point[0], point[1], point[2]);
    this.endCornerWCS = [point[0], point[1], point[2]];
    this.endCornerCanvas = [x, y, 0];
  }

  end(x: number, y: number) {
    this.clearCoord();
    this.clearShadow();
  }

  protected click(ev: MouseEvent): void {}

  protected dblclick(ev: MouseEvent): void {}

  _updateFrame() {}

  onescape() {}

  clear() {
    this.clearShadow();
    this.clearCoord();
    this.isDragging = false;
  }

  clearCoord() {
    this.drawPoints = [];
    this.startCornerCanvas = [];
    this.endCornerCanvas = [];
    this.startCornerWCS = [];
    this.endCornerWCS = [];
  }

  clearShadow() {
    if (this.shadowId) {
      const modelMUP = this.viewer.visViewer().getMarkupModel();
      modelMUP.removeEntity(this.shadowId);
      this.shadowId.delete();
      modelMUP.delete();
      this.shadowId = null;
    }
  }

  refreshShadowEntity() {
    const model: VisualizeJS.TvModel = this.subject
      .visViewer()
      .getMarkupModel();
    this.clearShadow();
    this.shadowId = model.appendEntity("DR_Entity");
    model.delete();
  }

  _setProperties(entId: VisualizeJS.OdTvEntityId, props: EntityProps) {
    this.viewer.entityBuilder.setProperties(entId, props);
  }

  dispose() {
    super.dispose();
    this.clear();
  }
}
