<script setup lang="ts">
import ScaleMenuState from "@/stores/ScaleMenuState";
import ScaleItem from "../Settings/ScaleItem.vue";
import { ref, toRaw } from "vue";
import type { ExposedScaleMenu } from "./floating.type";
import { Facade } from "@/open-cloud/Facade";
import type { NoteConfig } from "@/stores/UserState";
import { Logger } from "@/logger";
import type { EntityProps } from "@/open-cloud/builders/EntityBuilder";
import { cloneDeep } from "lodash";
const size = ref<number>();
const input = ref<number>();

function update(targets: string[]) {
  if (targets.length === 0) size.value = 0;
  else {
    const targetProps = Facade.getSelectedEntityTextProps(targets[0]);
    if (targetProps.length) {
      if (targetProps[0].textsize != 0) size.value = targetProps[0].textsize;
      else size.value = targetProps[0].textstyle.size;
    }
  }
}

function insertAndSetProps(config: NoteConfig) {
  Logger.info(`ScaleDropdown : insert and set props`);
  if (config.props.textstyle) {
    Facade.updateOrCreateTextStyle(toRaw(config.props.textstyle));
    setProps(config);
  }
}

function setProps(config: NoteConfig): void {
  Logger.info(`ScaleDropdown : set props to ${JSON.stringify(config)}`);
  const props: EntityProps = cloneDeep(toRaw(config.props));
  if (!props.textsize) props.textsize = 0;
  Facade.setSelectionProperty(props);
}

function setHeight() {
  Logger.info(`ScaleDropdown : set height to ${input.value}`);
  setProps({
    name: "",
    props: {
      textsize: input.value,
    },
  });
}

defineExpose<ExposedScaleMenu>({
  update,
});
</script>
<template>
  <div
    class="input-group"
    ref="refHook"
    data-bs-content="Appliquez des styles de textes (Arial, Times, ...) et des échelles à vos textes."
    data-bs-title="Echelle et style"
  >
    <span class="input-group-text">h({{ size }})</span>
    <button
      type="button"
      class="btn btn-outline-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="ScaleMenuState.updateScales()"
    />
    <ul
      class="dropdown-menu dropdown-menu-dark overflow-y-auto"
      style="max-height: 45vh"
    >
      <li>
        <div class="input-group p-1">
          <input
            type="number"
            class="form-control"
            v-model="input"
            style="width: 50px"
          />
          <span
            type="button"
            class="input-group-text"
            aria-expanded="false"
            @click="setHeight()"
          >
            <i class="bi bi-check" />
          </span>
        </div>
      </li>
      <li>
        <h6 class="dropdown-header">Vos styles à insérer</h6>
      </li>
      <li
        v-for="scale in ScaleMenuState.scales[1]"
        :key="scale.name"
        @click="() => insertAndSetProps(scale)"
      >
        <ScaleItem :scale="scale" />
      </li>
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li>
        <h6 class="dropdown-header">Dans le dessin</h6>
      </li>
      <li
        v-for="scale in ScaleMenuState.scales[0]"
        :key="scale.name"
        @click="() => setProps(scale)"
      >
        <ScaleItem :scale="scale" />
      </li>
    </ul>
  </div>
</template>
