<script setup lang="ts">
import ScaleMenu from "@/components/canvas/Settings/ScaleMenu.vue";
import LayerMenu from "@/components/canvas/Settings/LayerMenu.vue";
import OriginMenu from "@/components/canvas/Settings/OriginMenu.vue";
import LegendMenu from "@/components/canvas/Settings/LegendMenu.vue";
import ZoomMenu from "./ZoomMenu.vue";
import { useDeviceState } from "@/stores/DeviceState";

const device = useDeviceState();

const props = defineProps<{
  drawingId?: number;
}>();
</script>

<template>
  <div v-if="!device.isSmall()" class="btn-group">
    <LegendMenu />
    <ScaleMenu :drawingId="props.drawingId" />
    <LayerMenu />
    <OriginMenu />
    <ZoomMenu />
  </div>
  <div v-else class="btn-group">
    <div class="btn-group dropup">
      <button
        class="btn btn-secondary dropdown-toggle"
        data-bs-auto-close="outside"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @click.prevent.stop
      />
      <ul class="dropdown-menu dropdown-menu-dark">
        <li class="dropdown-item">
          <LegendMenu />
        </li>
        <li class="dropdown-item">
          <OriginMenu />
        </li>
        <li class="dropdown-item">
          <ZoomMenu />
        </li>
      </ul>
    </div>
    <ScaleMenu :drawingId="props.drawingId" />
    <LayerMenu />
  </div>
</template>
