<script setup lang="ts">
import { useDrawingStore } from "@/stores/fileStore";
const props = defineProps<{
  drawingid: number;
}>();
const { toggleDrawing } = useDrawingStore();

function toggleSelection() {
  toggleDrawing(props.drawingid);
}
</script>

<template>
  <div class="form-check">
    <input @click="toggleSelection" class="form-check-input" type="checkbox" />
  </div>
</template>
